import { createSlice } from "@reduxjs/toolkit";
export const leaguesSeasonInitialState = {
  isLoading: false,
  isError: false,
  errorMsg: "",
  leaguesSeason: [],
};
export const sessionExpireSlice = createSlice({
  name: "leagueSeasonSlice",
  initialState: leaguesSeasonInitialState,
  reducers: {
    setLeagueSeasonMetadata: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setLeagueSeasonMetadata } = sessionExpireSlice.actions;
export default sessionExpireSlice.reducer;
