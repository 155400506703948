import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  variant: "",
  show: false,
  title: "",
};
export const sessionExpireSlice = createSlice({
  name: "sessionExpireSlice",
  initialState,
  reducers: {
    setSessionExpireData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetSessionExpireData: (state) => {
      return { ...state, ...initialState };
    },
  },
});

export const { setSessionExpireData, resetSessionExpireData } =
  sessionExpireSlice.actions;
export default sessionExpireSlice.reducer;
