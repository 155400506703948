/* eslint-disable @typescript-eslint/ban-types */
import DataTable from "./DataTable";
import PaginationComponent from "./Pagination";
// import SearchAndFilter from "./SearchAndFilter";
// import { ISearch } from "./SearchAndFilter/Search";
// import { IFilter } from "./SearchAndFilter/Filter";
import { Info } from "./Svg";

interface IReactTable {
  isLoading?: boolean;
  data?: Array<any>;
  columns?: Array<any>;
  configuration?: object;
  totalRows?: number;
  rowsPerPage?: number;
  noDataFoundText?: string;
  errorMessage?: string;
  hasError?: boolean;
  pageChangeHandler?: Function;
  pageSizeHandler?: Function;
  showSearch?: boolean;
  onSearchChange?: Function;
  //   search?: ISearch;
  //   filter?: IFilter;
  currentPageNumber?: number;
  currentPageSize?: number;
  iFilter?: {
    isClientSideFilter: boolean;
    searchPlaceholder: string;
    filterContainerClass: string;
    serachBoxClass: string;
  };
  AdditionFilterComp?: any;
}

const ReactTable = ({
  isLoading,
  data,
  columns,
  configuration,
  pageChangeHandler,
  pageSizeHandler,
  totalRows,
  rowsPerPage,
  noDataFoundText,
  hasError,
  errorMessage = "Something went wrong",
  showSearch = true,
  onSearchChange,
  //   search,
  //   filter,
  currentPageNumber = 1,
  currentPageSize = 10,
  iFilter = {
    isClientSideFilter: false,
    searchPlaceholder: "Search...",
    filterContainerClass: "",
    serachBoxClass: "",
  },
  AdditionFilterComp = () => <></>,
}: IReactTable) => {
  return (
    <div className="react-table-wrapper">
      {/* <SearchAndFilter search={search} filter={filter} currentPage={currentPage}
        setCurrentPage={setCurrentPage} currentPageNumber={currentPageNumber} /> */}

      {!hasError ? (
        <>
          <DataTable
            isLoading={isLoading}
            data={data}
            columns={columns}
            configuration={configuration}
            noDataFoundText={noDataFoundText}
            iFilter={iFilter}
            AdditionFilterComp={AdditionFilterComp}
          />
          <PaginationComponent
            pageChangeHandler={pageChangeHandler}
            pageSizeHandler={pageSizeHandler}
            totalRows={totalRows}
            rowsPerPage={rowsPerPage}
            currentPage={currentPageNumber}
          />
        </>
      ) : (
        <div className="fs-2 w-100 d-flex align-items-center justify-content-center py-3">
          <Info />
          <span className="ml-2">{errorMessage}</span>
        </div>
      )}
    </div>
  );
};

export default ReactTable;
