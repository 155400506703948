import API, { API_METHOD } from "../api/api";
import { getBase } from "../apiconfig";
import { URLS } from "../common/url";
import { handleApiRequest } from "../utils/apiUtil";
import { HttpStatus } from "../utils/constants";
import { ERROR_MESSAGE_TYPE } from "../utils/error/errorMsg";

export const fetchPlayerList = async (payload = {}, config = {}) => {
  const url: string = getBase() + "/api" + URLS.PLAYER_LIST_URL;
  return await handleApiRequest(API_METHOD.POST, url, payload, config);
};

export const fetchPlayer = async (playerId: string) => {
  try {
    const url: string = getBase() + "/api" + URLS.PLAYER_URL + `/${playerId}`;
    const data: any = await API.get(url);
    if (data.status === 200 || data.status === 201) {
      if (data?.data?.data?.error)
        // eslint-disable-next-line no-throw-literal
        throw {
          status: HttpStatus.BAD_REQUEST,
          message:
            data?.data?.data?.error ||
            ERROR_MESSAGE_TYPE[HttpStatus.BAD_REQUEST],
        };
      else return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.status],
      };
    }
  } catch (error) {
    return error;
  }
};

export const updatePlayer = async (playerId: string, updatedBody = {}) => {
  try {
    const url: string = getBase() + "/api" + URLS.PLAYER_URL + `/${playerId}`;
    const data: any = await API.put(url, updatedBody);
    if (data.status === 200 || data.status === 201) {
      if (data?.data?.data?.error)
        // eslint-disable-next-line no-throw-literal
        throw {
          status: HttpStatus.BAD_REQUEST,
          message:
            data?.data?.data?.error ||
            ERROR_MESSAGE_TYPE[HttpStatus.BAD_REQUEST],
        };
      else return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.status],
      };
    }
  } catch (error) {
    return error;
  }
};

export const fetchTeamList = async (config: any = {}) => {
  try {
    const { leagueSeasonId, ...restConfig } = config;
    let url: string = getBase() + "/api" + URLS.TEAM_LIST_URL;
    if (leagueSeasonId) url += `?leagueSeasonId=${leagueSeasonId}`;
    const data: any = await API.get(url, restConfig);
    if (data.status === 200 || data.status === 201) {
      if (data?.data?.data?.error)
        // eslint-disable-next-line no-throw-literal
        throw {
          status: HttpStatus.BAD_REQUEST,
          message:
            data?.data?.data?.error ||
            ERROR_MESSAGE_TYPE[HttpStatus.BAD_REQUEST],
        };
      else return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.status],
      };
    }
  } catch (error) {
    return error;
  }
};
