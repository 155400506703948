const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const specialCharRegex = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/;
export const loginValidation = (mobileNumber, password) => {
  if (!mobileNumber && !password)
    return {
      mobileNumberError: {
        error: true,
        errorMsg: "Please enter mobile number",
      },
      passwordError: { error: true, errorMsg: "Please enter password" },
    };
  else if (!mobileNumber) {
    return {
      mobileNumberError: {
        error: true,
        errorMsg: "Please enter mobile number",
      },
      passwordError: { error: false, errorMsg: "" },
    };
  } else if (!password) {
    return {
      passwordError: {
        error: true,
        errorMsg: "Please enter password",
      },
      mobileNumberError: { error: false, errorMsg: "" },
    };
  } else
    return {
      passwordError: { error: false, errorMsg: "" },
      mobileNumberError: { error: false, errorMsg: "" },
    };
};

export const getEmailErrorMessage = (email) => {
  const isValidEmail = email.match(emailRegex);

  if (isValidEmail) return "";
  else return "Please enter valid email";
};

export const getPasswordErrorMessage = (pwd) => {
  if (
    !pwd ||
    pwd?.length < 8 ||
    !hasBothCases(pwd) ||
    !hasSpecialChar(pwd) ||
    !hasNumber(pwd)
  )
    return "Password must contain atleast 8 character with combination of 1 uppercase, 1 lowercase, 1 numeric and 1 special characters";
  else return "";
};
function hasNumber(str) {
  return /\d/.test(str);
}

function hasBothCases(str: string) {
  const upper = /[A-Z]/.test(str),
    lower = /[a-z]/.test(str);

  return upper && lower;
}

function hasSpecialChar(str: string) {
  return specialCharRegex.test(str);
}
