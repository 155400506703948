import { ColumnDef } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import {
  EMPTY_ERROR,
  UserIdentity,
  UserIdentityStatusOption,
  defaultPageSize,
  searchTimeDelay,
} from "../../common/constants";
import { Button, Col, Row } from "react-bootstrap";
import Typography from "../../common/components/Typography";
import ReactToastr from "../../common/components/ReactToaster";
import TableWrapper from "../../common/components/ReactTable/TableWrapper";
import ReactTable from "../../common/components/ReactTable/ReactTable";
import { fetchUserIdentityList } from "../service";
import { HttpStatus } from "../../utils/constants";
import Select from "react-select";
import {
  formatSearchMobileNumber,
  getPaginationQuery,
  restrictAlpha,
} from "../../utils/userUtil";
import { SearchWithClearButton } from "../../common/components/SearchWithClearButton";
import { useNavigate } from "react-router-dom";

export const UserIdentityView = () => {
  const navigate = useNavigate();
  const [viewedUser, setViewedUser] = useState(null);
  const [searchedUsername, setSearchedUsername] = useState(null);
  const [searchedMobileNumber, setSearchedMobileNumber] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [userIdentityList, setUserIdentityList] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
    if (showToaster?.callBack) {
      showToaster.callBack();
    }
  };

  const getUserIdentityList = async () => {
    setUserIdentityList((pre) => ({
      ...pre,
      isLoading: true,
      error: EMPTY_ERROR,
    }));
    const queryPayload = {};
    if (searchedUsername) queryPayload["username"] = searchedUsername;
    if (searchedMobileNumber)
      queryPayload["mobile"] = formatSearchMobileNumber(searchedMobileNumber);
    const resData: any = await fetchUserIdentityList({
      query: {
        ...queryPayload,
        status: statusFilter ? statusFilter.map((item) => item.value) : [],
      },
      page: getPaginationQuery(currentPage, pageSize),
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setUserIdentityList((pre) => ({
        ...pre,
        isLoading: false,
        data: resData?.data?.data,
      }));
    } else {
      setUserIdentityList((pre) => ({
        ...pre,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      }));
    }
  };

  useEffect(() => {
    if (viewedUser) navigate(`/user-identity/${viewedUser?.id}`);
  }, [viewedUser, navigate]);

  useEffect(() => {
    getUserIdentityList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (
      searchedUsername !== null ||
      searchedMobileNumber !== null ||
      statusFilter !== null
    ) {
      const timerId = setTimeout(() => {
        if (currentPage === 1) getUserIdentityList();
        else setCurrentPage(1);
      }, searchTimeDelay);

      return () => clearTimeout(timerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedUsername, searchedMobileNumber, statusFilter]);

  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: "username",
        id: "username",
        header: () => <span>{UserIdentity.username}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "status",
        id: "status",
        header: () => <span>{UserIdentity.status}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "view",
        id: "view",
        header: () => <span>{UserIdentity.document}</span>,
        cell: (info) => (
          <Button
            size="sm"
            variant="link"
            onClick={() => {
              setViewedUser(info?.row?.original);
            }}
          >
            {UserIdentity.view}
          </Button>
        ),
        canSort: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <>
      <>
        <Typography variant="pageTitle">{UserIdentity.users}</Typography>
        <Row className="mb-2">
          <Col lg="4">
            <SearchWithClearButton
              value={searchedUsername || ""}
              searchPlaceholder={UserIdentity.usernameSearchPlaceholder}
              inputChangeHandler={(event) =>
                setSearchedUsername(event.target.value)
              }
              clearButtonHandler={() => setSearchedUsername("")}
              isButtonDisabled={!searchedUsername}
              buttonVariant="outline-secondary"
            />
          </Col>
          <Col lg="4">
            <SearchWithClearButton
              value={searchedMobileNumber || ""}
              searchPlaceholder={UserIdentity.mobileSeachPlaceholder}
              inputChangeHandler={(e: any) => {
                if (
                  restrictAlpha(e.nativeEvent.data) ||
                  !e.nativeEvent.data ||
                  e.nativeEvent.data === "+"
                ) {
                  setSearchedMobileNumber(e.target.value);
                }
              }}
              clearButtonHandler={() => setSearchedMobileNumber("")}
              isButtonDisabled={!searchedMobileNumber}
              buttonVariant="outline-secondary"
            />
          </Col>
          <Col>
            <Select
              className="select-filter"
              closeMenuOnSelect={false}
              isMulti
              placeholder={UserIdentity.statusFilterPlaceholder}
              value={statusFilter}
              options={UserIdentityStatusOption}
              onChange={(e) => {
                setStatusFilter(e);
              }}
              isClearable={true}
            />
          </Col>
        </Row>
        <TableWrapper>
          <ReactTable
            isLoading={userIdentityList?.isLoading}
            data={userIdentityList?.data?.usersIdentity || []}
            hasError={userIdentityList?.error?.isError}
            errorMessage={userIdentityList?.error?.errorMsg}
            columns={columns}
            pageChangeHandler={setCurrentPage}
            pageSizeHandler={setPageSize}
            currentPageNumber={currentPage}
            totalRows={userIdentityList?.data?.totalCount}
            rowsPerPage={pageSize}
          />
        </TableWrapper>
      </>

      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleClose}
        position="top-center"
        autohide
      />
    </>
  );
};
