import React from "react";
import TableWrapper from "../../../../common/components/ReactTable/TableWrapper";
import ReactTable from "../../../../common/components/ReactTable/ReactTable";
import { ColumnDef } from "@tanstack/react-table";
import { TeamsConfig } from "../../../../common/constants";
import { Button } from "react-bootstrap";

export const TeamsInfoList = ({
  teamsInfoList,
  setCurrentPage,
  setPageSize,
  pageSize,
  currentPage,
  setSelectedTeam,
}) => {
  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: "logoUrl",
        id: "logoUrl",
        header: () => <span>{TeamsConfig.teams.logo}</span>,
        cell: (info) => {
          return (
            <img src={info.getValue()} width={50} height={50} alt="team logo" />
          );
        },
        canSort: false,
      },
      {
        accessorKey: "name",
        id: "credits",
        header: () => <span>{TeamsConfig.teams.name}</span>,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "shortCode",
        id: "shortCode",
        header: () => <span>{TeamsConfig.teams.code}</span>,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "teamColors",
        id: "teamColors",
        header: () => <span>{TeamsConfig.teams.teamColor}</span>,
        cell: (info) => (
          <div className="d-flex" style={{ justifyContent: "space-around" }}>
            <span>{info.getValue()?.[0]}</span>
            <span
              style={{
                background: info.getValue()?.[0],
                width: "40px",
                height: "30px",
              }}
            ></span>
          </div>
        ),
      },
      {
        accessorKey: "Action",
        id: "action",
        header: () => <span>{TeamsConfig.teams.action}</span>,
        cell: (info) => (
          <Button
            size="sm"
            variant="link"
            onClick={() => {
              setSelectedTeam(info?.row?.original);
            }}
          >
            {TeamsConfig.teams.edit}
          </Button>
        ),
        canSort: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <>
      <TableWrapper>
        <ReactTable
          isLoading={teamsInfoList?.isLoading}
          data={teamsInfoList?.data || []}
          hasError={teamsInfoList?.error?.isError}
          errorMessage={teamsInfoList?.error?.errorMsg}
          columns={columns}
          configuration={{
            sorting: [],
          }}
          pageChangeHandler={setCurrentPage}
          pageSizeHandler={setPageSize}
          totalRows={teamsInfoList?.data}
          rowsPerPage={pageSize}
          currentPageNumber={currentPage}
        />
      </TableWrapper>
    </>
  );
};
