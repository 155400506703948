import Select from "react-select";
import { CommonMessage } from "../../message";
import { Spinner } from "react-bootstrap";
import { getActiveSeason } from "../../../LeaguesConfigure/components/leaguesUtils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./style.scss";
import { FILTER_CONSTANTS } from "../../constants";

export const LeagueSeasonFilter = ({
  setFilteredLeagueSeason,
  leagueSeasonWrapperClassName = "league-season-filter-wrapper",
  leagueClassName = "leagues-select-filter-wrapper",
  seasonClassName = "seasons-select-filter-wrapper",
  leaguePlaceHolder = FILTER_CONSTANTS.filterByLeaguePlaceholder,
  seasonPlaceholder = FILTER_CONSTANTS.filterBySeasonPlaceholder,
}) => {
  const leaguesMetadata = useSelector((state: any) => state.leagues);
  const [selectedLeagueOption, setSelectedLeagueOption] = useState(null);
  const [seasonsOption, setSeasonsOption] = useState([]);
  const [selectedSeasonOption, setSelectedSeasonOption] = useState(null);
  const [leaguesOption, setLeaguesOption] = useState(
    leaguesMetadata.leaguesSeason,
  );
  useEffect(() => {
    setLeaguesOption(leaguesMetadata.leaguesSeason);
  }, [leaguesMetadata]);

  const handleLeaguesChange = (e) => {
    const seasonOption = e?.leagueSeasons?.map((item) => ({
      ...item,
      label: item?.name,
      value: item?.id,
    }));
    const activeSeason = getActiveSeason(seasonOption);
    setSelectedLeagueOption(e);
    setSelectedSeasonOption(activeSeason);
    setFilteredLeagueSeason(activeSeason);
    setSeasonsOption(seasonOption || []);
  };

  return (
    <div className={leagueSeasonWrapperClassName}>
      <Select
        placeholder={leaguePlaceHolder}
        className={leagueClassName}
        options={leaguesOption}
        value={selectedLeagueOption}
        onChange={(e) => {
          handleLeaguesChange(e);
        }}
        isLoading={leaguesMetadata.isLoading}
        loadingMessage={() => <Spinner variant="primary" size="sm" />}
        noOptionsMessage={() =>
          leaguesMetadata?.isError
            ? leaguesMetadata?.errorMsg
            : CommonMessage.noData
        }
      />
      <Select
        placeholder={seasonPlaceholder}
        className={seasonClassName}
        options={seasonsOption}
        value={selectedSeasonOption}
        onChange={(e) => {
          setSelectedSeasonOption(e);
          setFilteredLeagueSeason(e);
        }}
      />
    </div>
  );
};
