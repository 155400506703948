import { ChangeEvent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import { Button, Card, CardBody, Col, Row } from "react-bootstrap";
import FormControl from "../common/components/FormControl/FormControl";
import { Login } from "../common/constants";
import { doUserLogin } from "./service";
import { HttpStatus } from "../utils/constants";
import { isUserLoggedIn, setLoggedInUser } from "../utils/userUtil";
import { loginValidation } from "./loginUtils";
import ReactToastr from "../common/components/ReactToaster";
const initialErrorState = {
  mobileNumberError: { error: false, errorMsg: "" },
  passwordError: { error: false, errorMsg: "" },
};
export const LoginView = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(initialErrorState);
  const [loginLoader, setLoginLoader] = useState(false);
  const navigate = useNavigate();
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  useEffect(() => {
    if (isUserLoggedIn()) navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = async () => {
    setLoginLoader(true);
    const resData = await doUserLogin({
      mobile: mobileNumber,
      password: password,
    });
    if (
      resData.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData.status === HttpStatus.RESOURCE_CREATED
    ) {
      setLoginLoader(false);
      if (resData?.data?.data?.accessToken) {
        setLoggedInUser(resData?.data?.data, mobileNumber);
        navigate("/");
      } else {
        if (resData?.data?.data.error?.includes("User does not exists"))
          setError({
            ...initialErrorState,
            mobileNumberError: {
              error: true,
              errorMsg: resData?.data?.data.error,
            },
          });
        else {
          setError({
            mobileNumberError: {
              error: true,
              errorMsg: Login.incorrectUserAndPasswordMsg,
            },
            passwordError: {
              error: true,
              errorMsg: Login.incorrectUserAndPasswordMsg,
            },
          });
        }
      }
    } else {
      setLoginLoader(false);
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `${
          resData?.message || "Something went wrong, please try again"
        }`,
        variant: "danger",
      }));
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!mobileNumber || !password) {
      setError(loginValidation(mobileNumber, password));
    } else {
      setError(initialErrorState);
      handleLogin();
    }
  };

  return (
    <div className="login-container">
      <Card className="bg-white wrapper-style">
        <CardBody>
          <h2 className="login-header">{Login.loginHeader}</h2>
          <Row>
            <Col>
              <FormControl
                label={Login.mobileNubmerLabel}
                placeholder={Login.mobileNubmerPlaceholder}
                className="margin-bottom-3"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setMobileNumber(e.target.value);
                  setError({
                    ...error,
                    mobileNumberError: { error: false, errorMsg: "" },
                  });
                }}
                value={mobileNumber}
                isRequired={true}
                isError={error?.mobileNumberError?.error}
                error={error?.mobileNumberError?.errorMsg}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormControl
                type="password"
                label={Login.passwordLabel}
                placeholder={Login.passwordPlaceholder}
                className="margin-bottom-3"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setPassword(e.target.value);
                  setError({
                    ...error,
                    passwordError: { error: false, errorMsg: "" },
                  });
                }}
                value={password}
                isRequired={true}
                isError={error?.passwordError?.error}
                error={error?.passwordError?.errorMsg}
              />
            </Col>
          </Row>
          <div className="login-wrapper margin-top-3">
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={loginLoader}
            >
              {loginLoader ? Login.loggingButtonText : Login.loginButtonText}
            </Button>
          </div>
        </CardBody>
      </Card>
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleClose}
        position="top-center"
        autohide
      />
    </div>
  );
};
