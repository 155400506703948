import { API_METHOD } from "./api/api";
import { getBase } from "./apiconfig";
import { handleApiRequest } from "./utils/apiUtil";

export const uploadImageToPresignUrl = async (
  presignUrl: string,
  file: any,
  config = {},
) => {
  return await handleApiRequest(API_METHOD.PUT, presignUrl, file, config);
};

export const fetchImagePresignedUrl = async (fileUrl: string) => {
  const url: string = getBase() + "/api" + fileUrl;
  return await handleApiRequest(API_METHOD.GET, url);
};
