import { Button } from "react-bootstrap";
import {
  Action,
  MiniLeagueModel,
  MiniLeagues,
} from "../../../../../../common/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  CustomStatusCode,
  HttpStatus,
} from "../../../../../../utils/constants";
import {
  showFailureToaster,
  showSuccessToaster,
} from "../../../../../../utils/apiUtil";
import { getCommonError } from "../../../../../../common/message";
import {
  disburseMiniLeaguePrize,
  publishMiniLeague,
  saveKickOffMatch,
  unPublishMiniLeague,
  updateKickOffMatch,
} from "../../../service";
import {
  getAddMiniLeagueErrorMessages,
  getKickOffMatchPayload,
  getMiniLeagueConfirmationModelMsg,
} from "../../../../miniLeagueUtil";
import ConfirmationModal from "../../../../../../common/components/Modal/ConfirmationModel";

export const AddMiniLeagueFooter = ({
  kickOffMatch,
  setShowToaster,
  isEditingLeague,
  leagueName,
  uniqueCode,
  managerPrizeList,
  leaguePrizeList,
  addMiniLeagueInfoError,
  setAddMiniLeagueInfoError,
  miniLeagueId,
  imageUrl,
  isImageUpload,
  isMiniLeaguePublished,
  setIsMiniLeaguePublished,
  isLoading,
  reloadMiniLeagueById,
  setReloadMiniLeagueById,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { page, pageSize } = location.state || {};
  const [saveLoader, setSaveLoader] = useState(false);
  const [disbursePrizeLoader, setDisbursePrizeLoader] = useState(false);
  const [publishLoader, setPublishLoader] = useState(false);
  const [unPublishLoader, setUnPublishLoader] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modelType, setModelType] = useState(null);
  const handleDisbursePrize = async () => {
    setShowConfirmationModal(false);
    setDisbursePrizeLoader(true);
    const resData: any = await disburseMiniLeaguePrize({
      miniLeagueId: miniLeagueId,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setShowToaster(
        showSuccessToaster(
          MiniLeagues.messages.successfullyDisbursedPrize,
          () => {
            navigate("/mini-leagues", { state: { page, pageSize } });
          },
        ),
      );
      setDisbursePrizeLoader(false);
      setModelType(null);
    } else {
      setDisbursePrizeLoader(false);
      setModelType(null);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };

  const handleSave = async () => {
    setSaveLoader(true);
    const payload = getKickOffMatchPayload(
      isEditingLeague,
      imageUrl,
      leagueName,
      uniqueCode,
      kickOffMatch,
      managerPrizeList,
      leaguePrizeList,
    );
    let resData: any;
    // if isEditingLeague is true then call update the league APE else  call save the league API
    // And isEditingLeague is true only when we have already saved the league details 1st time
    if (isEditingLeague) {
      resData = await updateKickOffMatch(payload, {
        miniLeagueId: miniLeagueId,
      });
    } else {
      resData = await saveKickOffMatch(payload);
    }
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setSaveLoader(false);
      navigate(`/mini-leagues/${resData?.data?.data?.id}`);
      setReloadMiniLeagueById(!reloadMiniLeagueById);
      setShowToaster(
        showSuccessToaster(
          resData?.message || MiniLeagues.messages.successfullySavedData,
        ),
      );
    } else {
      setSaveLoader(false);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };

  const handlePublish = async () => {
    setShowConfirmationModal(false);
    setPublishLoader(true);
    const resData: any = await publishMiniLeague({
      miniLeagueId: miniLeagueId,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setPublishLoader(false);
      setIsMiniLeaguePublished(true);
      setModelType(null);
      setShowToaster(
        showSuccessToaster(
          MiniLeagues.messages.successfullyPublishedData,
          () => {
            navigate("/mini-leagues", { state: { page, pageSize } });
          },
        ),
      );
    } else {
      setPublishLoader(false);
      setModelType(null);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };

  const handleUnPublish = async () => {
    setShowConfirmationModal(false);
    setUnPublishLoader(true);
    const resData: any = await unPublishMiniLeague({
      miniLeagueId: miniLeagueId,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setUnPublishLoader(false);
      setIsMiniLeaguePublished(false);
      setModelType(null);
      setShowToaster(
        showSuccessToaster(MiniLeagues.messages.successfullyUnpublishedData),
      );
    } else {
      setUnPublishLoader(false);
      setModelType(null);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };

  const handleErrorValidation = (actionCall) => {
    const { isValidationError, ...restErrorMessages } =
      getAddMiniLeagueErrorMessages(
        isEditingLeague,
        leagueName,
        uniqueCode,
        kickOffMatch,
        managerPrizeList,
        leaguePrizeList,
      );
    if (!isValidationError) {
      actionCall();
    } else {
      const {
        leagueNameError,
        kickOffMatchError,
        uniqueCodeError,
        managerPrizeError,
        leaguePrizeError,
      } = restErrorMessages;
      if (leagueNameError || kickOffMatchError || uniqueCodeError) {
        setAddMiniLeagueInfoError({
          ...addMiniLeagueInfoError,
          leagueName: { error: !!leagueNameError, errorMsg: leagueNameError },
          kickOffMatch: {
            error: !!kickOffMatchError,
            errorMsg: kickOffMatchError,
          },
          uniqueCode: { error: !!uniqueCodeError, errorMsg: uniqueCodeError },
        });
      } else if (leaguePrizeError) {
        setShowToaster(showFailureToaster(leaguePrizeError));
      } else if (managerPrizeError) {
        setShowToaster(showFailureToaster(managerPrizeError));
      }
    }
  };
  const handleSaveButtonAction = () => {
    handleErrorValidation(handleSave);
  };

  const handleDisbursePrizeButtonAction = () => {
    setModelType(MiniLeagueModel.DISBURSE_PRIZE);
    setShowConfirmationModal(true);
  };
  const handlePublishButtonAction = () => {
    handleErrorValidation(() => {
      // if miniLeagueId is not present then show error to save the details of kick off match
      //   As miniLeagueId is not present then we can't publish the league
      if (miniLeagueId) {
        setModelType(MiniLeagueModel.PUBLISH);
        setShowConfirmationModal(true);
      } else {
        setShowToaster(
          showFailureToaster(MiniLeagues.messages.kickOffMatchDetailsNotSaved),
        );
      }
    });
  };
  const handleUnPublishButtonAction = () => {
    setModelType(MiniLeagueModel.UNPUBLISH);
    setShowConfirmationModal(true);
  };
  const handleConfirmationClick = () => {
    switch (modelType) {
      case MiniLeagueModel.PUBLISH:
        handlePublish();
        break;
      case MiniLeagueModel.UNPUBLISH:
        handleUnPublish();
        break;
      case MiniLeagueModel.DISBURSE_PRIZE:
        handleDisbursePrize();
        break;
      default:
        setShowConfirmationModal(false); // Just close model without performing any action
    }
  };

  return (
    <>
      <div className="sticky-action-button-wrapper">
        <Button
          variant="primary"
          className="mr-1"
          onClick={() =>
            navigate("/mini-leagues", { state: { page, pageSize } })
          }
        >
          {Action.goBack}
        </Button>
        {!isLoading && (
          <>
            <Button
              variant="primary"
              className="mr-1"
              disabled={disbursePrizeLoader || !isMiniLeaguePublished}
              onClick={() =>
                !disbursePrizeLoader &&
                isMiniLeaguePublished &&
                handleDisbursePrizeButtonAction()
              }
            >
              {disbursePrizeLoader
                ? Action.disbursingPrize
                : Action.disbursePrize}
            </Button>
            <Button
              variant="primary"
              className="mr-1"
              disabled={isMiniLeaguePublished || saveLoader || isImageUpload}
              onClick={() =>
                !isMiniLeaguePublished &&
                !saveLoader &&
                !isImageUpload &&
                handleSaveButtonAction()
              }
            >
              {saveLoader ? Action.saving : Action.save}
            </Button>
            {isMiniLeaguePublished ? (
              <Button
                variant="primary"
                disabled={unPublishLoader}
                onClick={() =>
                  !unPublishLoader && handleUnPublishButtonAction()
                }
              >
                {unPublishLoader ? Action.unPublishing : Action.unPublish}
              </Button>
            ) : (
              <Button
                variant="primary"
                disabled={saveLoader || publishLoader}
                onClick={() =>
                  !saveLoader && !publishLoader && handlePublishButtonAction()
                }
              >
                {publishLoader ? Action.publishing : Action.publish}
              </Button>
            )}
          </>
        )}
      </div>
      <ConfirmationModal
        variant="alert"
        show={showConfirmationModal}
        title={() => getMiniLeagueConfirmationModelMsg(modelType)}
        handleClick={() => handleConfirmationClick()}
        btnText={Action.ok}
        handleClose={() => setShowConfirmationModal(false)}
        secondaryBtnText={Action.cancel}
        handleSecondaryBtnClick={() => setShowConfirmationModal(false)}
      />
    </>
  );
};
