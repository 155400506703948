import { API_METHOD } from "../api/api";
import { getBase } from "../apiconfig";
import { URLS } from "../common/url";
import { handleApiRequest } from "../utils/apiUtil";

export const fetchUserIdentityList = async (payload = {}, config = {}) => {
  const url: string = getBase() + "/api" + URLS.USER_IDENTITY_URL + "/list";
  return await handleApiRequest(API_METHOD.POST, url, payload, config);
};

export const updateUserIdentityStatusApi = async (
  payload = {},
  config: any = {},
) => {
  const { id, ...restConfig } = config;
  const url: string =
    getBase() + "/api" + URLS.USER_IDENTITY_URL + `/userIdentityStatus/${id}`;
  return await handleApiRequest(API_METHOD.PUT, url, payload, restConfig);
};

export const fetchUserIdentityDetail = async (
  payload: any = {},
  config = {},
) => {
  const { id, ...restPayload } = payload;
  const url: string = getBase() + "/api" + URLS.USER_IDENTITY_URL + `/${id}`;
  return await handleApiRequest(API_METHOD.GET, url, restPayload, config);
};

export const getDocumentByDocsId = async (payload: any = {}, config = {}) => {
  const url: string =
    getBase() + "/api" + URLS.USER_IDENTITY_URL + `/downloadDocument`;
  return await handleApiRequest(API_METHOD.POST, url, payload, config);
};
