import API from "../api/api";
import { getBase } from "../apiconfig";
import { URLS } from "../common/url";
import { HttpStatus } from "../utils/constants";
import { ERROR_MESSAGE_TYPE } from "../utils/error/errorMsg";

export const fetchFutyPoints = async (config = {}) => {
  try {
    const url: string = getBase() + "/api" + URLS.FUTY_POINTS;
    const data: any = await API.get(url, config);
    if (data.status === 200 || data.status === 201) {
      if (data?.data?.data?.error)
        // eslint-disable-next-line no-throw-literal
        throw {
          status: HttpStatus.BAD_REQUEST,
          message:
            data?.data?.data?.error ||
            ERROR_MESSAGE_TYPE[HttpStatus.BAD_REQUEST],
        };
      else return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.status],
      };
    }
  } catch (error) {
    return error;
  }
};

export interface IUpdatedPoints {
  defender: number;
  attacker: number;
}

export const updateFutyPoints = async (
  categoryId: string,
  updatedPoints: IUpdatedPoints,
  config = {},
) => {
  try {
    const url = `${getBase()}/api${URLS.FUTY_POINTS}/${categoryId}`;

    const data: any = await API.put(url, updatedPoints, config);
    if (data.status === 200 || data.status === 201) {
      if (data?.data?.data?.error)
        // eslint-disable-next-line no-throw-literal
        throw {
          status: HttpStatus.BAD_REQUEST,
          message:
            data?.data?.data?.error ||
            ERROR_MESSAGE_TYPE[HttpStatus.BAD_REQUEST],
        };
      else return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.status],
      };
    }
  } catch (error) {
    return error;
  }
};
