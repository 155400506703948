import { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { fetchTeamList } from "../../../Players/service";
import { CustomStatusCode, HttpStatus } from "../../../utils/constants";
import { showFailureToaster } from "../../../utils/apiUtil";
import { getCommonError } from "../../message";
import { useDispatch, useSelector } from "react-redux";
import { setTeamsData } from "../../../teamslist/component/TeamsColorConfig/teamColorConfigSlice";
import { EMPTY_ERROR, FILTER_CONSTANTS } from "../../constants";
import "./style.scss";

export const TeamsFilter = ({
  closeMenuOnSelect = false,
  isMulti = true,
  setSelectedTeams,
  setShowToaster,
  placeholder = FILTER_CONSTANTS.filterByTeams,
  classNames = "small-select-filter",
}) => {
  const dispatch = useDispatch();
  const teamsInfoList = useSelector((state: any) => state.teams);
  const [selectedTeamOptions, setSelectedTeamOptions] = useState<any[]>(null);
  const getTeamsInfoList = useCallback(async () => {
    dispatch(
      setTeamsData({
        ...teamsInfoList,
        isLoading: true,
        error: EMPTY_ERROR,
      }),
    );
    const resData: any = await fetchTeamList();
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      dispatch(
        setTeamsData({
          ...teamsInfoList,
          isLoading: false,
          data: resData?.data?.data,
          error: EMPTY_ERROR,
        }),
      );
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!teamsInfoList?.data?.length) getTeamsInfoList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTeamsInfoList]);

  const handleTeamFilter = (selectedOptions = []) => {
    setSelectedTeamOptions(selectedOptions);
    setSelectedTeams(selectedOptions?.map((option) => option.value));
  };
  return (
    <Select
      className={classNames}
      placeholder={placeholder}
      closeMenuOnSelect={closeMenuOnSelect}
      isMulti={isMulti}
      value={selectedTeamOptions}
      options={teamsInfoList?.data?.map((team) => ({
        label: team.name,
        value: team.id,
      }))}
      onChange={handleTeamFilter}
    />
  );
};
