import * as Yup from "yup";

export const TASK = {
  validationMessage: {
    taskGroup: "Please select a task group",
    heading: "Please enter a heading",
    maximumTier: "Please enter a maximum tier",
    duration: "duration is required",
    canExpire: "canExpire is required",
    taskTitle: {
      default: "Title is required",
      length: "Title cannot exceed 160 characters",
    },
    inAppUrl: "InAppUrl is required",
    iconUrl: "Icon is required",
    tierNumber: "Tier number is required",
    rewards: {
      default: "At least one reward is required",
      amount: "Amount is required",
      amountMin: "Amount should be greater than 0",
      currency: "Currency is required",
    },
    redirectionUrl: "Redirection URL is required",
    isTrackable: "isTrackable is required",
    gameModes: "Please select a game mode",
    trackingType: "Please select a tracking type",
    isAnyMatch: "isAnyMatch is required",
  },
};

export enum TaskGroupsEnum {
  DAILY_TASK = "DailyTask",
  WELCOME_TASK = "WelcomeTask",
  MILESTONE = "Milestone",
  TASK = "Task",
}

export enum RewardCurrencyEnum {
  FUTY_CREDITS = "FUTY_CREDITS",
}

export enum GameModesEnum {
  TG_FANTASY = "Tg_Fantasy",
  TG_TRIPLE_THREAT = "Tg_TripleThreat",
  TG_GOALFEST = "Tg_GoalFest",
}

export enum MatchStatus {
  SCHEDULED = "Scheduled",
  LIVE = "Live",
  INREVIEW = "In Review",
  COMPLETED_UNPUBLISHED = "Completed Unpublished",
  COMPLETED = "Completed",
  POSTPONED = "Postponed",
  ABANDONED = "Abandoned",
}

export enum TrackingTypesEnum {
  CLICK = "CLICK",
  GAME_PARTICIPATION = "GAME_PARTICIPATION",
}

export enum taskIconsEnum {
  X = "X",
  YOUTUBE = "YOUTUBE",
  INSTAGRAM = "INSTAGRAM",
  TELEGRAM = "TELEGRAM",
  TRIPLE_THREAT = "QUIZ",
  GOALFEST = "GOAL-FEST",
  FANTASY = "FANTASY",
  FUTY = "FUTY",
}

export const taskGroups = [
  {
    label: "Daily Task",
    value: TaskGroupsEnum.DAILY_TASK,
  },
  {
    label: "Welcome Task",
    value: TaskGroupsEnum.WELCOME_TASK,
  },
  {
    label: "Milestone",
    value: TaskGroupsEnum.MILESTONE,
  },
  {
    label: "Task",
    value: TaskGroupsEnum.TASK,
  },
];

export const taskLogoMap = {
  [taskIconsEnum.X]: "/icons/X.png",
  [taskIconsEnum.INSTAGRAM]: "/icons/Instagram.png",
  [taskIconsEnum.YOUTUBE]: "/icons/Youtube.png",
  [taskIconsEnum.TELEGRAM]: "/icons/Telegram.png",
  [taskIconsEnum.FUTY]: "https://www.futy.uk/FUTYLogoMain.png",
  [taskIconsEnum.FANTASY]: "/icons/FantasyLogo.svg",
  [taskIconsEnum.GOALFEST]: "/icons/GoalFestLogo.svg",
  [taskIconsEnum.TRIPLE_THREAT]: "/icons/QuizLogo.svg",
};

export const taskIcons = [
  {
    label: taskIconsEnum.FUTY,
    value: taskIconsEnum.FUTY,
  },
  {
    label: taskIconsEnum.FANTASY,
    value: taskIconsEnum.FANTASY,
  },
  {
    label: taskIconsEnum.TRIPLE_THREAT,
    value: taskIconsEnum.TRIPLE_THREAT,
  },
  {
    label: taskIconsEnum.GOALFEST,
    value: taskIconsEnum.GOALFEST,
  },
  {
    label: taskIconsEnum.X,
    value: taskIconsEnum.X,
  },
  {
    label: taskIconsEnum.INSTAGRAM,
    value: taskIconsEnum.INSTAGRAM,
  },
  {
    label: taskIconsEnum.TELEGRAM,
    value: taskIconsEnum.TELEGRAM,
  },
  {
    label: taskIconsEnum.YOUTUBE,
    value: taskIconsEnum.YOUTUBE,
  },
];

export interface Option {
  label: string;
  value: string;
  optional?: string;
}

export const currencyOptions = [
  {
    label: RewardCurrencyEnum.FUTY_CREDITS,
    value: RewardCurrencyEnum.FUTY_CREDITS,
  },
];

export const gameModesOptions = [
  {
    label: GameModesEnum.TG_TRIPLE_THREAT,
    value: GameModesEnum.TG_TRIPLE_THREAT,
  },
  {
    label: GameModesEnum.TG_FANTASY,
    value: GameModesEnum.TG_FANTASY,
  },
  {
    label: GameModesEnum.TG_GOALFEST,
    value: GameModesEnum.TG_GOALFEST,
  },
];

export const trackingTypeOptions = [
  {
    label: TrackingTypesEnum.CLICK,
    value: TrackingTypesEnum.CLICK,
  },
  {
    label: TrackingTypesEnum.GAME_PARTICIPATION,
    value: TrackingTypesEnum.GAME_PARTICIPATION,
  },
];

/*interfaces*/
export interface Reward {
  amount: number;
  currency: string;
  rewardAfterMins: number;
}

export interface TrackingDetails {
  trackingType: string;
  matchIds: string[] | null;
  gameIds: string[] | null;
  gameModesArr: GameModesEnum[] | null;
  totalMatchesCount: number;
  isAnyMatch: boolean;
}

export interface TaskValue {
  title: string;
  iconUrl: string;
  tierNumber: number;
  rewards: Reward[];
  redirectionUrl: string;
  isInAppUrl: boolean;
  isTrackable: boolean;
  gameModes: GameModesEnum[] | null;
  trackingDetails: TrackingDetails;
}

export interface CreateTaskValues {
  taskGroup: string;
  canExpire: boolean;
  duration: { startTime: string; endTime: string } | null;
  heading: string;
  maximumTier: number;
  tasks: TaskValue[];
}

export const initialDateRange = [null, null];

export const createTaskValidationSchema = Yup.object({
  taskGroup: Yup.string().required(TASK.validationMessage.taskGroup),
  duration: Yup.object()
    .shape({
      startTime: Yup.date(),
      endTime: Yup.date(),
    })
    .nullable()
    .default(null),
  canExpire: Yup.boolean().required(TASK.validationMessage.canExpire),
  heading: Yup.string().optional(),
  maximumTier: Yup.number()
    .required(TASK.validationMessage.maximumTier)
    .default(1),
  tasks: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .required(TASK.validationMessage.taskTitle.default)
        .max(160, TASK.validationMessage.taskTitle.length),
      iconUrl: Yup.string().required(TASK.validationMessage.iconUrl),
      tierNumber: Yup.number().required(TASK.validationMessage.tierNumber),
      rewards: Yup.array()
        .of(
          Yup.object().shape({
            amount: Yup.number()
              .required(TASK.validationMessage.rewards.amount)
              .min(1, TASK.validationMessage.rewards.amountMin),
            currency: Yup.string().required(
              TASK.validationMessage.rewards.currency,
            ),
            rewardAfterMins: Yup.number().optional(),
          }),
        )
        .required(TASK.validationMessage.rewards.default),
      redirectionUrl: Yup.string().required(
        TASK.validationMessage.redirectionUrl,
      ),
      isInAppUrl: Yup.boolean().required(TASK.validationMessage.inAppUrl),
      isTrackable: Yup.boolean().required(TASK.validationMessage.isTrackable),
      gameModes: Yup.array().of(Yup.string()).nullable().default(null),
      trackingDetails: Yup.object().shape({
        trackingType: Yup.string().required(
          TASK.validationMessage.trackingType,
        ),
        matchIds: Yup.array().of(Yup.string()).nullable().default(null),
        totalMatchesCount: Yup.number().nullable().default(null),
        isAnyMatch: Yup.boolean()
          .required(TASK.validationMessage.isAnyMatch)
          .default(false),
      }),
    }),
  ),
});

export const mapDailyTaskData = (task: any): CreateTaskValues => ({
  taskGroup: task?.taskGroup,
  canExpire: Boolean(task?.canExpire),
  duration: task?.duration ? getFormattedDate(task?.duration) : null,
  heading: task?.heading,
  maximumTier: task?.maximumTier,
  tasks: [
    {
      title: task?.tasks?.at(0)?.title || "",
      iconUrl: task?.tasks?.at(0)?.iconUrl || "",
      tierNumber: task?.tasks?.at(0)?.tierNumber || 1,
      rewards: task?.tasks?.at(0)?.rewards || [],
      redirectionUrl: task?.tasks?.at(0)?.redirectionUrl || "",
      isInAppUrl: task?.tasks?.at(0)?.isInAppUrl || false,
      isTrackable: task?.tasks?.at(0)?.isTrackable || false,
      gameModes: task?.tasks?.at(0)?.gameModes
        ? Array.from(new Set(task?.tasks?.at(0)?.gameModes))
        : null,
      trackingDetails: {
        trackingType: task?.tasks?.at(0)?.trackingDetails?.trackingType || "",
        matchIds: task?.tasks?.at(0)?.trackingDetails?.matchIds || null,
        gameIds: task?.tasks?.at(0)?.trackingDetails?.gameIds || null,
        totalMatchesCount:
          task?.tasks?.at(0)?.trackingDetails?.totalMatchesCount || 0,
        isAnyMatch: task?.tasks?.at(0)?.trackingDetails?.isAnyMatch || false,
        gameModesArr: task?.tasks?.at(0)?.gameModeArr || null,
      },
    },
  ],
});

export const getTodayTomorrowRange = () => {
  const start = new Date();
  start.setUTCHours(0, 0, 0, 0);
  const end = new Date(start);
  end.setUTCDate(end.getUTCDate() + 1);
  end.setUTCHours(0, 0, 0, 0);
  const dateRanges = [start.toString(), end.toString()];
  return dateRanges;
};

export const formatDateToLocale = (date) => {
  const startDate = new Date(date?.startTime);
  const endDate = new Date(date?.endTime);
  return [startDate.toString(), endDate.toString()];
};

export const iconRedirectionUrlMap = {
  [taskIconsEnum.X]: "https://x.com/",
  [taskIconsEnum.INSTAGRAM]: "https://www.instagram.com/",
  [taskIconsEnum.TELEGRAM]: "https://t.me/",
  [taskIconsEnum.YOUTUBE]: "https://youtube.com/",
};

export const initialTasksValue: TaskValue = {
  title: "",
  iconUrl: taskIconsEnum.FUTY,
  tierNumber: 1,
  rewards: [{ amount: 0, currency: "", rewardAfterMins: 0 }],
  redirectionUrl: "",
  isInAppUrl: false,
  isTrackable: false,
  gameModes: null,
  trackingDetails: {
    trackingType: "",
    matchIds: null,
    gameIds: null,
    totalMatchesCount: 0,
    isAnyMatch: false,
    gameModesArr: null,
  },
};

export const initialCreateTaskValues: CreateTaskValues = {
  taskGroup: "",
  canExpire: false,
  duration: {
    startTime: getTodayTomorrowRange()[0],
    endTime: getTodayTomorrowRange()[1],
  },
  heading: "",
  maximumTier: 1,
  tasks: [initialTasksValue],
};

const getFormattedDate = (duration) => {
  const dateRange = formatDateToLocale(duration);
  return {
    startTime: dateRange[0],
    endTime: dateRange[1],
  };
};

export const mapMilestoneTaskData = (task: any): CreateTaskValues => ({
  taskGroup: task?.taskGroup,
  canExpire: Boolean(task?.canExpire),
  duration: task?.duration ? getFormattedDate(task?.duration) : null,
  heading: task?.heading,
  maximumTier: task?.maximumTier,
  tasks: task?.tasks.map((task) => ({
    title: task?.title || "",
    iconUrl: task?.iconUrl || "",
    tierNumber: task?.tierNumber || 1,
    rewards: task?.rewards || [],
    redirectionUrl: task?.redirectionUrl || "",
    isInAppUrl: task?.isInAppUrl || false,
    isTrackable: task?.isTrackable || false,
    gameModes: task?.gameModes ? Array.from(new Set(task?.gameModes)) : null,
    trackingDetails: {
      trackingType: task?.trackingDetails?.trackingType || "",
      matchIds: task?.trackingDetails?.matchIds || null,
      gameIds: task?.trackingDetails?.gameIds || null,
      gameModesArr: task?.gameModes || null,
      totalMatchesCount: task?.trackingDetails?.totalMatchesCount || 0,
      isAnyMatch: task?.trackingDetails?.isAnyMatch || false,
    },
  })),
});

export const formatFormData = (formData: any): CreateTaskValues => ({
  taskGroup: formData?.taskGroup,
  canExpire: formData?.canExpire,
  duration: Boolean(formData?.canExpire) ? formData?.duration : null,
  heading: formData?.heading,
  maximumTier: formData?.maximumTier ?? 1,
  tasks: formData?.tasks.map((task: any) => ({
    title: task?.title,
    iconUrl: task?.iconUrl,
    tierNumber: task?.tierNumber,
    rewards: task?.rewards,
    redirectionUrl: task?.redirectionUrl,
    isInAppUrl: task?.isInAppUrl,
    isTrackable: task?.isTrackable,
    gameModes: task?.trackingDetails?.gameModesArr || task?.gameModes,
    trackingDetails: {
      trackingType: task?.trackingDetails?.trackingType,
      matchIds: task?.trackingDetails?.matchIds,
      totalMatchesCount: task?.trackingDetails?.totalMatchesCount,
      isAnyMatch: task?.trackingDetails?.isAnyMatch,
    },
  })),
});

export const navigateTaskPageUrlMap = {
  [TaskGroupsEnum.DAILY_TASK]: "/tasks/daily-tasks",
  [TaskGroupsEnum.WELCOME_TASK]: "/tasks/welcome-tasks",
  [TaskGroupsEnum.MILESTONE]: "/tasks/milestone-tasks",
  [TaskGroupsEnum.TASK]: "/tasks/milestone-tasks",
};
