import React, { useEffect, useState } from "react";
import Typography from "../../common/components/Typography";
import { Card, CardBody, Row, Col, Button, Image } from "react-bootstrap";
import {
  Action,
  EMPTY_ERROR,
  ManageUsers,
  UserFieldUpdateModel,
} from "../../common/constants";
import { formatSearchMobileNumber, restrictAlpha } from "../../utils/userUtil";
import FormControl from "../../common/components/FormControl/FormControl";
import NoImageAvailable from "../../../src/common/assets/NoImageAvailable.png";
import "./style.scss";
import ConfirmationModal from "../../common/components/Modal/ConfirmationModel";
import {
  confirmUserApi,
  fetchUserDetails,
  updateUserDetails,
} from "../service";
import { CustomStatusCode, HttpStatus } from "../../utils/constants";
import ReactToastr from "../../common/components/ReactToaster";
import {
  getEmailErrorMessage,
  getPasswordErrorMessage,
} from "../../Login/loginUtils";
import Itooltip from "../../common/Itooltip/Itooltip";

const initialErrorState = {
  mobileError: { error: false, errorMsg: "" },
  passwordError: { error: false, errorMsg: "" },
  emailError: { error: false, errorMsg: "" },
};
const initialUserDetails = {
  data: null,
  isLoading: false,
  error: EMPTY_ERROR,
};
export const ManageUser = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState(initialErrorState);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [userDetail, setUserDetail] = useState(initialUserDetails);
  const [modelType, setModelType] = useState(UserFieldUpdateModel.PASSWORD);
  const [isUpdatingUserDetails, setIsUpdatingUserDetails] = useState(false);
  const [isConfirmingUser, setIsConfirmingUser] = useState(false);
  const [isDisplayUpdateView, setIsDisplayUpdateView] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  useEffect(() => {
    if (userDetail.data) {
      const { isEmailVerified, isMobileVerified } = userDetail.data;
      const isUserVerified = isEmailVerified || isMobileVerified;
      setIsUserVerified(isUserVerified);
    } else setIsUserVerified(false);
  }, [userDetail]);

  const handleSave = async () => {
    const payload: any = {};
    if (modelType === UserFieldUpdateModel.PASSWORD)
      payload["password"] = password;
    else payload["email"] = email;
    setIsUpdatingUserDetails(true);
    const resData: any = await updateUserDetails(payload, {
      id: userDetail?.data?.id,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setIsUpdatingUserDetails(false);
      setShowConfirmationModal(false);
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `${
          resData?.message ||
          `Successfully update the ${
            modelType === UserFieldUpdateModel.PASSWORD ? "password" : "Email"
          }`
        }`,
        variant: "success",
      }));
      setPassword("");
      setEmail("");
      setUserDetail({ ...initialUserDetails, data: resData?.data?.data });
    } else {
      setIsUpdatingUserDetails(false);
      setShowConfirmationModal(false);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message || "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
    }
  };

  const confirmUser = async () => {
    const { mobile, email } = userDetail?.data;
    const payload = {};
    if (mobile) payload["mobile"] = mobile;
    else if (email) payload["email"] = email;
    setIsConfirmingUser(true);
    const resData: any = await confirmUserApi(payload);
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setIsConfirmingUser(false);
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `${resData?.message || ManageUsers.successfullyConfirmUser}`,
        variant: "success",
      }));
      setUserDetail({ ...initialUserDetails, data: resData?.data?.data });
    } else {
      setIsConfirmingUser(false);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message || "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
    }
  };

  const handlePasswordUpdate = () => {
    const passwordErrorMsg = getPasswordErrorMessage(password);
    if (passwordErrorMsg) {
      setError({
        ...error,
        passwordError: { error: true, errorMsg: passwordErrorMsg },
      });
    } else {
      setModelType(UserFieldUpdateModel.PASSWORD);
      setShowConfirmationModal(true);
    }
  };

  const handleEmailUpdate = () => {
    const emailErrorMsg = getEmailErrorMessage(email);
    if (emailErrorMsg) {
      setError({
        ...error,
        emailError: { error: true, errorMsg: emailErrorMsg },
      });
    } else {
      setModelType(UserFieldUpdateModel.EMAIL);
      setShowConfirmationModal(true);
    }
  };

  const handleConfirmUser = () => {
    confirmUser();
  };

  const getUserDetails = async (payload) => {
    setUserDetail((pre) => ({
      ...pre,
      isLoading: true,
    }));
    const resData: any = await fetchUserDetails(payload);
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setUserDetail((pre) => ({
        ...pre,
        isLoading: false,
        data: resData?.data?.data,
      }));
      setIsDisplayUpdateView(true);
    } else {
      setIsDisplayUpdateView(false);
      setUserDetail((pre) => ({
        ...pre,
        isLoading: false,
        data: null,
        error: {
          isError: true,
          errorMsg: `${
            resData?.message || "Something went wrong, please try again"
          }`,
        },
      }));
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message || "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
    }
    setPassword("");
    setEmail("");
    setError(initialErrorState);
  };

  return (
    <div className="user-manage-wrapper">
      <Typography variant="pageTitle">{ManageUsers.userManagement}</Typography>
      <Card className="card-wrapper">
        <CardBody className="manage-user-body">
          <Row className="mb-3 mt-3">
            <Col sm={3}>
              <label className="d-block fw-light fs-13">
                {ManageUsers.mobile}
              </label>
            </Col>
            <Col className="mb-3">
              <FormControl
                onChange={(e: any) => {
                  if (
                    restrictAlpha(e.nativeEvent.data) ||
                    !e.nativeEvent.data ||
                    e.nativeEvent.data === "+"
                  ) {
                    setMobileNumber(e.target.value);
                    setError({
                      ...error,
                      mobileError: { error: false, errorMsg: "" },
                    });
                  }
                }}
                value={mobileNumber}
                isRequired={true}
                isError={error?.mobileError?.error}
                error={error?.mobileError?.errorMsg}
                placeholder={ManageUsers.searchByMobilePlaceholder}
                helperText={ManageUsers.mobileNumberHelperText}
              />
            </Col>
            <Col>
              <Button
                variant="primary"
                disabled={userDetail?.isLoading}
                onClick={() => {
                  if (!userDetail?.isLoading) {
                    getUserDetails({
                      mobile: formatSearchMobileNumber(mobileNumber),
                    });
                    setUsername("");
                    setSearchEmail("");
                  }
                }}
              >
                {userDetail?.isLoading ? Action.searching : Action.search}
              </Button>
            </Col>
          </Row>
          <Row className="mb-3 mt-3">
            <Col sm={3}>
              <label className="d-block fw-light fs-13">
                {ManageUsers.userName}
              </label>
            </Col>
            <Col className="mb-3">
              <FormControl
                onChange={(e: any) => {
                  setUsername(e.target.value);
                }}
                value={username}
                placeholder={ManageUsers.searchByUsernamePlaceholder}
              />
            </Col>
            <Col>
              <Button
                variant="primary"
                disabled={userDetail?.isLoading}
                onClick={() => {
                  if (!userDetail?.isLoading) {
                    getUserDetails({ username: username });
                    setMobileNumber("");
                    setSearchEmail("");
                  }
                }}
              >
                {userDetail?.isLoading ? Action.searching : Action.search}
              </Button>
            </Col>
          </Row>
          <Row className="mb-3 mt-3">
            <Col sm={3}>
              <label className="d-block fw-light fs-13">
                {ManageUsers.email}
              </label>
            </Col>
            <Col className="mb-3">
              <FormControl
                onChange={(e: any) => {
                  setSearchEmail(e.target.value);
                }}
                value={searchEmail}
                placeholder={ManageUsers.searchByEmailPlaceholder}
              />
            </Col>
            <Col>
              <Button
                variant="primary"
                disabled={userDetail?.isLoading}
                onClick={() => {
                  if (!userDetail?.isLoading) {
                    getUserDetails({ email: searchEmail });
                    setUsername("");
                    setMobileNumber("");
                  }
                }}
              >
                {userDetail?.isLoading ? Action.searching : Action.search}
              </Button>
            </Col>
          </Row>
          {isDisplayUpdateView ? (
            isUserVerified ? (
              <>
                <hr></hr>
                <Row className="mb-3 align-items-center">
                  <Col sm={3}>
                    <Image
                      src={
                        userDetail?.data
                          ? userDetail?.data?.profileUrl
                          : NoImageAvailable
                      }
                      className="player-profile-image  mt-3"
                      alt="user-profile"
                    />
                  </Col>
                  <Col>
                    <div>
                      <label className="user-label fw-light fs-13">
                        {ManageUsers.name}:
                      </label>
                      <span className="fs-13 fw-bold">
                        {userDetail?.data?.name}
                      </span>
                    </div>
                    <div>
                      <label className="user-label fw-light fs-13">
                        {ManageUsers.email}:
                      </label>
                      <span className="fs-13 fw-bold">
                        {userDetail?.data?.email}
                      </span>
                    </div>
                    <div>
                      <label className="user-label fw-light fs-13">
                        {ManageUsers.userName}:
                      </label>
                      <span className="fs-13 fw-bold">
                        {userDetail?.data?.username}
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm={3}>
                    <label className="d-block fw-light fs-13">
                      {ManageUsers.password}
                    </label>
                  </Col>
                  <Col>
                    <FormControl
                      onChange={(e: any) => {
                        setPassword(e.target.value);
                        setError({
                          ...error,
                          passwordError: { error: false, errorMsg: "" },
                        });
                      }}
                      value={password}
                      isRequired={true}
                      isError={error?.passwordError?.error}
                      error={error?.passwordError?.errorMsg}
                      disabled={!userDetail?.data}
                      helperText={ManageUsers.passwordHelperText}
                    />
                  </Col>
                  <Col>
                    <Button
                      variant="primary"
                      onClick={() =>
                        !isUpdatingUserDetails &&
                        userDetail?.data &&
                        handlePasswordUpdate()
                      }
                      disabled={isUpdatingUserDetails || !userDetail?.data}
                    >
                      {isUpdatingUserDetails &&
                      modelType === UserFieldUpdateModel.PASSWORD
                        ? Action.updating
                        : Action.update}
                    </Button>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm={3}>
                    <label className="d-block fw-light fs-13">
                      {ManageUsers.email}
                    </label>
                  </Col>
                  <Col>
                    <FormControl
                      onChange={(e: any) => {
                        setEmail(e.target.value);
                        setError({
                          ...error,
                          emailError: { error: false, errorMsg: "" },
                        });
                      }}
                      value={email}
                      isRequired={true}
                      isError={error?.emailError?.error}
                      error={error?.emailError?.errorMsg}
                      disabled={!userDetail?.data}
                    />
                  </Col>
                  <Col>
                    <Button
                      variant="primary"
                      onClick={() =>
                        !isUpdatingUserDetails &&
                        userDetail?.data &&
                        handleEmailUpdate()
                      }
                      disabled={isUpdatingUserDetails || !userDetail?.data}
                    >
                      {isUpdatingUserDetails &&
                      modelType === UserFieldUpdateModel.EMAIL
                        ? Action.updating
                        : Action.update}
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              <Row className="mb-3">
                <Col sm={3}></Col>
                <Col>
                  <div
                    className={`button-with-tooltip ${
                      isConfirmingUser || !userDetail?.data ? "disabled" : ""
                    }`}
                  >
                    <Button
                      className="confirm-user-button"
                      variant="primary"
                      onClick={() =>
                        !isConfirmingUser &&
                        userDetail?.data &&
                        handleConfirmUser()
                      }
                      disabled={isConfirmingUser || !userDetail?.data}
                    >
                      {isConfirmingUser
                        ? Action.confirmingUser
                        : Action.confirmUser}
                    </Button>
                    <Itooltip
                      placement="right"
                      tooltipId={"confirm-user-button"}
                      tooltipContent={ManageUsers.confirmUserContent}
                    />
                  </div>
                </Col>
              </Row>
            )
          ) : (
            <></>
          )}
        </CardBody>
      </Card>
      <ConfirmationModal
        variant="alert"
        show={showConfirmationModal}
        title={
          modelType === UserFieldUpdateModel.PASSWORD
            ? `Are you sure, you want to update password?`
            : "Are you sure you want to update email?"
        }
        handleClick={() => {
          userDetail?.data?.id && handleSave();
        }}
        handleClose={() => setShowConfirmationModal(false)}
        btnText="Ok"
      />
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleClose}
        position="top-center"
        autohide
      />
    </div>
  );
};
