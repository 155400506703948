import { Seprator, TrueOrFalse } from "../common/constants";
export function getSearchedMatchIds(inputString) {
  if (
    inputString === null ||
    inputString === undefined ||
    inputString?.trim() === ""
  ) {
    return [];
  }
  let array = inputString.split(",");
  array = array.map((item) => item.trim());
  return array;
}

export const getAppendedDateAndTime = (
  date,
  time,
  seprator = Seprator.comma,
) => {
  return `${date ? `${date}${seprator}` : ""} ${time ?? ""}`;
};

export const getTrueOrFalseValue = (value) => {
  return value === true
    ? TrueOrFalse.TRUE
    : value === false
    ? TrueOrFalse.FALSE
    : "";
};
