import { getBase } from "../apiconfig";
import { URLS } from "../common/url";
import { handleApiRequest } from "../utils/apiUtil";
import { API_METHOD } from "../api/api";

export const fetchGoalfestList = async (payload = {}, config = {}) => {
  const url: string = getBase() + "/api" + URLS.GOALFEST_LIST_URL;
  return await handleApiRequest(API_METHOD.POST, url, payload, config);
};

export const fetchGoalFest = async (id) => {
  const url: string = getBase() + "/api" + URLS.GOALFEST_URL + "/" + id;
  return await handleApiRequest(API_METHOD.GET, url);
};

export const saveGoalFest = async ({ id, values }) => {
  const url: string = getBase() + "/api" + URLS.GOALFEST_URL + "/" + id;
  return await handleApiRequest(API_METHOD.PUT, url, values);
};
export const publishGoalFest = async ({ id, payload }) => {
  const url: string = getBase() + "/api" + URLS.GOALFEST_URL + "/" + id;
  return await handleApiRequest(API_METHOD.PUT, url, payload);
};
export const disburseGoalFestPrize = async (id) => {
  const url: string =
    getBase() + "/api" + URLS.GOALFEST_URL + "/distributePrize/" + id;
  return await handleApiRequest(API_METHOD.PUT, url);
};
