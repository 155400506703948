export const setLoggedInUser = (data, mobileNumber) => {
  localStorage.setItem("accessToken", data?.accessToken);
  localStorage.setItem("idToken", data?.idToken);
  localStorage.setItem("refreshToken", data?.refreshToken);
  localStorage.setItem("mobileNumber", mobileNumber);
};

export const setUserDetails = (data) => {
  localStorage.setItem("user", data ? JSON.stringify(data) : null);
};
export const isUserLoggedIn = () => {
  const haveAccessToken = localStorage.getItem("accessToken");
  return haveAccessToken ? true : false;
};

export const restrictAlpha = (key) => {
  const pattern = /^[0-9]$/;
  return pattern.test(key);
};

export const getUserDetailsObject = (userJson) => {
  try {
    return JSON.parse(userJson);
  } catch (error) {
    return null;
  }
};

export const formatSearchMobileNumber = (mobileNumber) => {
  if (mobileNumber) {
    const trimMobileNumber = mobileNumber.trim();
    return trimMobileNumber.startsWith("+")
      ? trimMobileNumber
      : `+${trimMobileNumber}`;
  } else return "";
};

export const getPaginationQuery = (page, pageSize) => {
  return {
    skip: Math.max((page - 1) * pageSize, 0),
    limit: pageSize,
  };
};
