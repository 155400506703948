export interface ApiConfigInterface {
  protocol: string;
  host: string;
  port?: string | number;
}
export const apiConfig1: ApiConfigInterface = {
  protocol: process.env.REACT_APP_BASE_PROTOCOL || "",
  host: process.env.REACT_APP_BASE_HOST || "",
  port: process.env.REACT_APP_BASE_PORT || "",
};

export const getBase: (config?: ApiConfigInterface) => string = (
  config: ApiConfigInterface = apiConfig1,
) => {
  if (!config?.port) return apiConfig1.protocol + "://" + config.host;
  else return apiConfig1.protocol + "://" + config.host;
  // else return apiConfig1.protocol + "://" + config.host + `:${config.port}`;
};
