import React, { HTMLProps, useEffect, useState } from "react";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import { ColumnDef } from "@tanstack/react-table";
import {
  Match,
  playerPositionOptions,
  SVG_COLOR,
} from "../../../common/constants";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import { getTeamName } from "./playerHelper";
import Typography from "../../../common/components/Typography";
import "./style.scss";
import FormCheckbox from "../../../common/components/FormCheckbox/FormCheckbox";
import ReactToastr from "../../../common/components/ReactToaster";
import FormSelect from "../../../common/components/FormSelect/FormSelect";
import { HoverTooltip } from "../../../common/components/HoverTooltip";
import { Checkmark, ClearInputCross, EditPencil } from "../../../common/SVG";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const PlayersConfig = ({
  matchMetaData,
  playerList,
  matchData,
  futyStar,
  setFutyStar,
  isReadOnly,
}) => {
  const navigate = useNavigate();
  const [filterPlayerList, setFilterPlayerList] = useState(playerList);
  const [team1Checkbox, setTeam1Checkbox] = useState({
    val: matchData?.matchData?.teamId1,
    checkbox: true,
  });
  const [team2Checkbox, setTeam2Checkbox] = useState({
    val: matchData?.matchData?.teamId2,
    checkbox: true,
  });
  const [positionFilter, setPositionFilter] = useState(null);
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };
  useEffect(() => {
    let filterPlayerList = [...(playerList || [])];
    // Filter by position
    if (positionFilter) {
      filterPlayerList = filterPlayerList.filter(
        (player) =>
          player?.futyPosition?.toLowerCase() === positionFilter.value,
      );
    }
    // Filter by team checkboxes
    if (team1Checkbox.checkbox && team2Checkbox.checkbox) {
      filterPlayerList = filterPlayerList.filter(
        (player) =>
          player.teamId === matchData?.matchData?.teamId1?.id ||
          player.teamId === matchData?.matchData?.teamId2?.id,
      );
    } else if (team1Checkbox.checkbox) {
      filterPlayerList = filterPlayerList.filter(
        (player) => player.teamId === matchData?.matchData?.teamId1?.id,
      );
    } else if (team2Checkbox.checkbox) {
      filterPlayerList = filterPlayerList.filter(
        (player) => player.teamId === matchData?.matchData?.teamId2?.id,
      );
    } else {
      filterPlayerList = filterPlayerList.filter(
        (player) =>
          player.teamId !== matchData?.matchData?.teamId1?.id &&
          player.teamId !== matchData?.matchData?.teamId2?.id,
      );
    }
    setFilterPlayerList(filterPlayerList);
  }, [
    playerList,
    positionFilter,
    matchData?.matchData?.teamId1?.id,
    matchData?.matchData?.teamId2?.id,
    team1Checkbox.checkbox,
    team2Checkbox.checkbox,
  ]);
  const handleSelectedFutyStar = (value) => {
    if (futyStar.some((player) => player.playerId === value.playerId)) {
      const filteredPlayers = futyStar.filter(
        (e) => e.playerId !== value.playerId,
      );
      setFutyStar(filteredPlayers);
    } else {
      if (futyStar.length === 4)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: "Can add only 4 Futy star",
          variant: "danger",
        }));
      else {
        setFutyStar([...futyStar, value]);
      }
    }
  };
  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => {
      const baseColumns = [
        {
          accessorKey: "id",
          id: "id",
          header: () => <span>{Match.player.teamName}</span>,
          cell: ({ row }) => (
            <span>
              {getTeamName(
                matchData?.matchData?.teamId1,
                matchData?.matchData?.teamId2,
                row?.original,
              )}
            </span>
          ),
          canSort: false,
        },
        {
          accessorKey: "profileImageUrl",
          id: "profileImage",
          header: () => <span>{Match.player.profile}</span>,
          cell: (info) => (
            <img src={info.getValue()} width={50} height={50} alt="profile" />
          ),
          canSort: false,
        },
        {
          accessorKey: "name",
          id: "name",
          header: () => <span>{Match.player.name}</span>,
          cell: (info) => <span>{info.getValue()}</span>,
          canSort: false,
        },
        {
          accessorKey: "futyPosition",
          id: "futyPosition",
          header: () => <span>{Match.player.position}</span>,
          cell: (info) => info.getValue(),
          canSort: false,
        },
        {
          accessorKey: "totalScore",
          id: "totalScore",
          header: () => (
            <HoverTooltip
              tooltipId="totalScore"
              tooltipContent={Match.player.totalFutyScoreTooltip}
            >
              <span>{Match.player.totalFutyScore}</span>
            </HoverTooltip>
          ),
          cell: (info) => info.getValue(),
          canSort: false,
        },
        {
          accessorKey: "last5score",
          id: "last5score",
          header: () => (
            <HoverTooltip
              tooltipId="last5score"
              tooltipContent={Match.player.last5ScoreTooltip}
            >
              <span>{Match.player.last5Score}</span>
            </HoverTooltip>
          ),
          cell: (info) => info.getValue(),
          canSort: false,
        },
        {
          accessorKey: "lastMatchScore",
          id: "lastMatchScore",
          header: () => (
            <HoverTooltip
              tooltipId="lastMatchScore"
              tooltipContent={Match.player.lastMatchScoreTooltip}
            >
              <span>{Match.player.lastMatchScore}</span>
            </HoverTooltip>
          ),
          cell: (info) => info.getValue(),
          canSort: false,
        },
        {
          accessorKey: "score",
          id: "score",
          header: () => (
            <HoverTooltip
              tooltipId="totalMatchScore"
              tooltipContent={Match.player.totalMatchScoreTooltip}
            >
              <span>{Match.player.totalMatchScore}</span>
            </HoverTooltip>
          ),
          cell: (info) => info.getValue(),
          canSort: false,
        },
        {
          accessorKey: "form",
          id: "form",
          header: () => <span>{Match.player.form}</span>,
          cell: (info) => info.getValue(),
        },
        {
          accessorKey: "injuryStatus",
          id: "injuryStatus",
          header: () => <span>{Match.player.injuryStatus}</span>,
          cell: ({ row }) => (
            <>
              {`${row?.original?.injuryStatus || ""} ${
                row?.original?.injuryType
                  ? " (" + row?.original?.injuryType + ")"
                  : ""
              }`}
            </>
          ),
          canSort: false,
        },
        {
          accessorKey: "select",
          id: "select",
          enableSorting: false,
          header: () => <span>{Match.player.futyStar}</span>,
          cell: ({ row }) => (
            <div className="px-1">
              <IndeterminateCheckbox
                {...{
                  checked: futyStar.some(
                    (item) => item.playerId === row?.original?.playerId,
                  ),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: () => {
                    !isReadOnly &&
                      !row?.original?.isSuspended &&
                      handleSelectedFutyStar(row?.original);
                  },
                  disabled: isReadOnly || row?.original?.isSuspended,
                }}
              />
            </div>
          ),
        },
      ];
      if (!isReadOnly) {
        baseColumns.push(
          {
            accessorKey: "published",
            id: "published",
            header: () => <span>{Match.player.published}</span>,
            cell: (info) => (
              <div className="align-center">
                {info.getValue() === true ? (
                  <Checkmark stroke={SVG_COLOR.green} />
                ) : info.getValue() === false ? (
                  <ClearInputCross stroke={SVG_COLOR.red} />
                ) : (
                  <></>
                )}
              </div>
            ),
            canSort: false,
          },
          {
            accessorKey: "action-key",
            id: "action",
            header: () => <span>{Match.player.action}</span>,
            cell: ({ row }) => (
              <div className="align-center">
                <Button
                  size="sm"
                  variant="link"
                  onClick={() => {
                    navigate(`/players/${row?.original?.playerId}`);
                  }}
                >
                  <EditPencil />
                </Button>
              </div>
            ),
            canSort: false,
          },
        );
      }
      return baseColumns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [futyStar, matchData, playerList, navigate], // Include all relevant dependencies
  );

  function IndeterminateCheckbox({
    indeterminate,
    className = "",
    ...rest
  }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const ref = React.useRef<HTMLInputElement>(null!);

    React.useEffect(() => {
      if (typeof indeterminate === "boolean") {
        ref.current.indeterminate = !rest.checked && indeterminate;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, indeterminate]);

    return (
      <>
        <input
          type="checkbox"
          ref={ref}
          className={className + " form-check-input cursor-pointer"}
          disabled={isReadOnly}
          {...rest}
        />
      </>
    );
  }

  const teamFilterComp = () => {
    return (
      <div className="team-filter-wrapper">
        <FormSelect
          className="position-filter"
          options={playerPositionOptions}
          placeholder={Match.player.positionFilterPlaceholder}
          value={positionFilter}
          onChange={(e) => {
            setPositionFilter(e);
          }}
          isClearable={true}
        />
        <FormCheckbox
          className="team-checkbox1-style"
          name="Team 1"
          label={matchData?.matchData?.teamId1?.name ?? ""}
          checked={team1Checkbox.checkbox}
          disabled={isReadOnly}
          onChange={(e: any) => {
            !isReadOnly &&
              setTeam1Checkbox({
                ...team1Checkbox,
                checkbox: e.target.checked,
              });
          }}
        />
        <FormCheckbox
          name="Team 2"
          className="team-checkbox2-style"
          label={matchData?.matchData?.teamId2?.name ?? ""}
          checked={team2Checkbox.checkbox}
          disabled={isReadOnly}
          onChange={(e: any) => {
            !isReadOnly &&
              setTeam2Checkbox({
                ...team2Checkbox,
                checkbox: e.target.checked,
              });
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div className="d-flex align-items-center selcted-player-name-wrapper">
        <div>
          <Typography variant="heading4" className="selected-player-header">
            Selected Futy Players :{" "}
          </Typography>
        </div>
        <div>
          <div className="d-flex align-items-center">
            {futyStar.map((item, index) => (
              <span className="p-2 pt-1 player-name-span" key={item?.id}>
                {index +
                  1 +
                  ". " +
                  (item?.firstName ?? "") +
                  " " +
                  (item?.lastName ?? "")}
              </span>
            ))}
          </div>
        </div>
      </div>

      <div className="player-config-wrapper">
        <TableWrapper tableClassName="mt-3">
          <ReactTable
            isLoading={matchMetaData?.isLoading}
            data={filterPlayerList || []}
            hasError={matchMetaData?.error?.isError}
            errorMessage={matchMetaData?.error?.errorMsg}
            columns={columns}
            configuration={{
              sorting: [{ id: "form", desc: true }],
            }}
            iFilter={{
              isClientSideFilter: true,
              searchPlaceholder: "Search by player name",
              filterContainerClass: "filter-container-style",
              serachBoxClass: "search-box-style",
            }}
            AdditionFilterComp={() =>
              matchMetaData?.isLoading ? <></> : teamFilterComp()
            }
          />
        </TableWrapper>
      </div>
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleClose}
        position="top-center"
        autohide
      />
    </>
  );
};

export default PlayersConfig;
