import API from "../api/api";
import { getBase } from "../apiconfig";
import { URLS } from "../common/url";
import { ERROR_MESSAGE_TYPE } from "../utils/error/errorMsg";

export const getTodoList = async (payload = {}, config = {}) => {
  try {
    const url: string =
      getBase({ protocol: "https", host: "jsonplaceholder.typicode.com" }) +
      URLS.TODOLIST_URL +
      "/1";
    const data: any = await API.post(url, payload, config);
    if (data.statusCode === 200) {
      return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        statusCode: data.statusCode,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.statusCode],
      };
    }
  } catch (error) {
    return error;
  }
};
