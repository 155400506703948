/* eslint-disable @typescript-eslint/ban-types */
import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./style.scss";
import { ConfirmationAlert, ConfirmationSuccess } from "../../../SVG";
import Typography from "../../Typography";
import { Spinner } from "react-bootstrap";

const ConfirmationModal = ({
  show,
  handleClose,
  variant,
  title,
  value,
  infoText,
  handleClick,
  btnText,
  secondaryBtnText,
  handleSecondaryBtnClick,
  isLoading,
}: {
  show?: boolean;
  variant?: string;
  btnText?: string;
  secondaryBtnText?: string;
  title?: any;
  value?: string;
  infoText?: string;
  handleClose?: Function;
  handleClick?: Function;
  handleSecondaryBtnClick?: Function;
  isLoading?: boolean;
}) => {
  return (
    <Modal
      className="modal-confirmation"
      show={show}
      onHide={() => handleClose()}
      centered
    >
      {isLoading ? (
        <div className="react-table-loader">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <Modal.Header closeButton onClick={() => handleClose()} />
          <Modal.Body>
            {variant && (
              <div className="mb-5">
                {variant === "alert" && <ConfirmationAlert />}
                {variant === "success" && <ConfirmationSuccess />}
              </div>
            )}

            {title && (
              <Typography variant="heading3" className="title text-center">
                {typeof title === "function" ? title() : title}
              </Typography>
            )}
            {value && (
              <Typography variant="heading3" className="value text-center">
                {value}
              </Typography>
            )}
            {infoText && (
              <Typography variant="body" className="info-text text-center">
                {infoText}
              </Typography>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex ">
              {secondaryBtnText && (
                <Button
                  variant=""
                  onClick={() => handleSecondaryBtnClick()}
                  className="mt-3 py-2 margin-right-1"
                >
                  {secondaryBtnText}
                </Button>
              )}
              {btnText && (
                <Button onClick={() => handleClick()} className="mt-3 py-2">
                  {btnText}
                </Button>
              )}
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default ConfirmationModal;
