import { useState, useEffect } from "react";
import Typography from "../../../common/components/Typography";
import { fetchGoalfestList } from "../../sevice";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import { EMPTY_ERROR } from "../../../common/constants";
import { HttpStatus } from "../../../utils/constants";
import ReactToastr from "../../../common/components/ReactToaster";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TrueFalseIconViewer } from "../../../common/components/TrueFalseIconViewer";
import moment from "moment";

export const GoalFestList = () => {
  const [goalfestList, setGoalfestList] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });
  const handleToasterClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };
  useEffect(() => {
    getGoalfestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGoalfestList = async () => {
    setGoalfestList({
      ...goalfestList,
      isLoading: true,
      error: EMPTY_ERROR,
    });
    const resData = await fetchGoalfestList({
      query: {
        platform: "Admin",
        isPublished: false,
        isUpcoming: false,
        isJoined: false,
        isAllMyGames: true,
      },
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      console.log(resData?.data?.data);
      setGoalfestList((prev) => ({
        ...prev,
        isLoading: false,
        data: resData?.data?.data,
      }));
    } else {
      setGoalfestList({
        ...goalfestList,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      });
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `${
          resData?.message ?? "Something went wrong, please try again"
        }`,
        variant: "danger",
      }));
    }
  };
  const columns = [
    {
      accessorKey: "weekLeague",
      header: "Week League",
      cell: (info) => info.getValue(),
      canSort: false,
    },
    {
      accessorKey: "leagueSeasonName",
      header: "Season Name",
      cell: (info) => info.getValue(),
      canSort: false,
    },
    {
      accessorKey: "isPublished",
      header: "Published",
      cell: (info) => (
        <TrueFalseIconViewer value={info?.getValue() ? true : false} />
      ),
    },
    {
      accessorKey: "deadLine",
      header: "Deadline",
      cell: (info) => new Date(info.getValue()).toLocaleDateString(),
    },

    {
      accessorKey: "prizeDisbursedAt",
      header: "Prize Disbursed",
      cell: (info) => {
        const row = info.row.original;
        const date = info.getValue() ? moment(info.getValue()) : null;
        const disbursedDate = date?.isValid()
          ? date?.format("DD/MM/YYYY (HH:mm)")
          : "";
        return (
          <div className="d-flex align-items-center gap-2">
            <TrueFalseIconViewer value={row.prizeDisbursed ? true : false} />
            {row.prizeDisbursed ? `\u{1F4C5} ${disbursedDate}` : null}
          </div>
        );
      },
    },
    {
      id: "navigate",
      header: "Actions",
      cell: (info) => (
        <Link to={`/goalfest/${info.row.original.goalFestId}`}>
          <Button size="sm" variant="link">
            Configure
          </Button>
        </Link>
      ),
      canSort: false,
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between">
        <Typography variant="pageTitle" className="pb-2">
          Goalfest List
        </Typography>
      </div>
      <TableWrapper>
        <ReactTable
          isLoading={goalfestList?.isLoading}
          data={goalfestList?.data || []}
          hasError={goalfestList?.error?.isError}
          errorMessage={goalfestList?.error?.errorMsg}
          columns={columns}
          totalRows={goalfestList?.data?.totalCount}
        />
      </TableWrapper>
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleToasterClose}
        position="top-center"
        autohide
      />
    </>
  );
};
