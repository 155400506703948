import React, { useState, useEffect } from "react";
import "./style.scss";
import { fetchUserTeamStats } from "../../service";
import { HttpStatus } from "../../../utils/constants";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import { ColumnDef } from "@tanstack/react-table";
import { EMPTY_ERROR, Match } from "../../../common/constants";
import "./style.scss";
import { Col, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import { getActiveSeason } from "../../../LeaguesConfigure/components/leaguesUtils";
import Typography from "../../../common/components/Typography";
import { useSelector } from "react-redux";
import { CommonMessage } from "../../../common/message";

export const MatchStatComponent = ({ startDate, endDate }) => {
  const leaguesMetadata = useSelector((state: any) => state.leagues);
  const [leaguesOption, setLeaguesOption] = useState(
    leaguesMetadata.leaguesSeason,
  );
  const [seasonsOption, setSeasonsOption] = useState([]);
  const [selectedLeagueOption, setSelectedLeagueOption] = useState(null);
  const [selectedSeasonOption, setSelectedSeasonOption] = useState(null);
  const [userTeamStats, setUserTeamStats] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });

  useEffect(() => {
    setLeaguesOption(leaguesMetadata.leaguesSeason);
  }, [leaguesMetadata]);

  useEffect(() => {
    if (startDate && endDate) {
      getUserTeamStats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, selectedSeasonOption]);

  const getUserTeamStats = async () => {
    setUserTeamStats({
      ...userTeamStats,
      isLoading: true,
      error: EMPTY_ERROR,
    });

    const resData: any = await fetchUserTeamStats(
      startDate,
      endDate,
      selectedSeasonOption?.id,
    );

    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setUserTeamStats((pre) => ({
        ...pre,
        isLoading: false,
        data: {
          stats: resData?.data?.data?.matchesWithUsersJoinedCount,
          usersCount: resData?.data?.data?.usersJoinedCount,
        },
      }));
    } else {
      setUserTeamStats({
        ...userTeamStats,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      });
    }
  };

  const handleLeaguesChange = (e) => {
    const seasonOption = e?.leagueSeasons?.map((item) => ({
      ...item,
      label: item?.name,
      value: item?.id,
    }));
    const activeSeason = getActiveSeason(seasonOption);
    setSelectedLeagueOption(e);
    setSelectedSeasonOption(activeSeason);
    setSeasonsOption(seasonOption || []);
  };

  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: "team1",
        id: "team1",
        header: () => <span>{Match.published.team1name}</span>,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "team2",
        id: "team2",
        header: () => <span>{Match.published.team2name}</span>,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "startDate",
        id: "startDate",
        header: () => <span>{Match.published.startDate}</span>,
        cell: (info) => info.getValue()?.replace("Z", ""),
      },
      {
        accessorKey: "startTime",
        id: "startTime",
        header: () => <span>{Match.published.startTime}</span>,
        cell: (info) => info.getValue()?.replace("Z", ""),
      },
      {
        accessorKey: "weekLeague",
        id: "weekLeague",
        header: () => <span>{Match.published.weekLeague}</span>,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "usersJoinedCount",
        id: "usersJoinedCount",
        header: () => <span>{Match.published.usersJoinedCount}</span>,
        cell: (info) => info.getValue(),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Row className="mb-2">
        <Col md="6">
          <Typography className="text-bodymedium">
            Number of users that joined matches:{" "}
            <u style={{ fontWeight: "bold" }}>
              {userTeamStats?.data?.usersCount || 0}
            </u>
          </Typography>{" "}
        </Col>
        <Col>
          <Select
            placeholder="Filter by leagues"
            className="leagues-select-wrapper"
            options={leaguesOption}
            value={selectedLeagueOption}
            onChange={(e) => {
              handleLeaguesChange(e);
            }}
            isLoading={leaguesMetadata.isLoading}
            loadingMessage={() => <Spinner variant="primary" size="sm" />}
            noOptionsMessage={() =>
              leaguesMetadata?.isError
                ? leaguesMetadata?.errorMsg
                : CommonMessage.noData
            }
          />
        </Col>
        <Col>
          <Select
            placeholder="Filter by season"
            className="seasons-select-wrapper"
            options={seasonsOption}
            value={selectedSeasonOption}
            onChange={(e) => {
              setSelectedSeasonOption(e);
            }}
          />
        </Col>
      </Row>
      <TableWrapper>
        <ReactTable
          isLoading={userTeamStats?.isLoading}
          data={userTeamStats?.data?.stats || []}
          columns={columns}
          hasError={userTeamStats?.error.isError}
          errorMessage={userTeamStats?.error.errorMsg}
        />
      </TableWrapper>
    </>
  );
};
