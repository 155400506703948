import { Match, MatchConfigModel } from "../../../common/constants";
export const getTeamName = (team1, team2, player) => {
  const teamId = player?.teamId || player?.team.id;
  if (teamId === team1?.id) return team1.name || "";
  if (teamId === team2?.id) return team2.name || "";
  return "";
};

export const getModelTypeConfirmationMessage = (
  modelType,
  isPublishedMatch,
) => {
  switch (modelType) {
    case MatchConfigModel.PUBLISH_UNPUBLISH:
      return Match.configure.publishDetailsConfirmMessage(isPublishedMatch);
    case MatchConfigModel.SCORE_CALCULATION:
      return Match.configure.calculateScoreConfirmMessage;
    case MatchConfigModel.DISBURSE_PRIZE:
      return Match.configure.disbursePrizeConfirmMessage;
    default:
      return "";
  }
};
