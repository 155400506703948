import { useRef, useState } from "react";
import { Overlay, Tooltip } from "react-bootstrap";

const Itooltip = ({ placement = "right", tooltipId, tooltipContent }) => {
  const [show, setShow] = useState(false);
  const target = useRef<HTMLSpanElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tooltipPlacement, setTooltipPlacement] = useState<any>(placement);
  return (
    <>
      <span
        ref={target}
        onClick={() => setShow(!show)}
        className="tooltip-icon"
      >
        i
      </span>
      <Overlay target={target.current} show={show} placement={tooltipPlacement}>
        {(props) => (
          <Tooltip id={tooltipId} {...props}>
            {tooltipContent}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default Itooltip;
