/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from "react";
import { Button, Form, FormControl } from "react-bootstrap";

interface IPaginationComponent {
  currentPage: number;
  totalRows?: number;
  rowsPerPage?: number;
  pageChangeHandler?: Function;
  pageSizeHandler?: Function;
}

const PaginationComponent = ({
  pageChangeHandler,
  pageSizeHandler,
  totalRows,
  rowsPerPage,
  currentPage,
}: IPaginationComponent) => {
  let noOfPages = 1;
  if (totalRows && rowsPerPage) {
    noOfPages = Math.ceil(totalRows / rowsPerPage);
  }
  const [pageSize, setPageSize] = useState(rowsPerPage);
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);
  const onNextPage = () => pageChangeHandler(currentPage + 1);
  const onPrevPage = () => pageChangeHandler(currentPage - 1);

  const handlePageSize = (e: any) => {
    setPageSize(e.target.value);
  };

  const handleInputChange = (e: any) => {
    pageChangeHandler(Number(e.target.value));
  };

  useEffect(() => {
    if (currentPage >= noOfPages || !currentPage) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 1 || !currentPage) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
    if (pageChangeHandler) {
      pageChangeHandler(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noOfPages, currentPage]);

  useEffect(() => {
    if (pageSizeHandler) pageSizeHandler(pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize]);

  return (
    <>
      {noOfPages > 1 && (
        <>
          <div className="px-2 d-flex justify-content-between align-items-center pagination">
            <div className="d-flex  align-items-center rows-per-page">
              <span>Show</span>
              <Form.Select
                className="border py-0 mx-2 pagination-dropdown"
                aria-label="Default select example"
                onChange={handlePageSize}
                value={rowsPerPage}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </Form.Select>
              <span className="text-nowrap">rows per page</span>
            </div>
            <div className="d-flex justify-content-end align-items-end pagination-groupBtn">
              <div className="mx-2 d-flex align-items-center goTo-pagenumber">
                Go to page number&nbsp;
                <FormControl
                  className="me-2"
                  type="number"
                  onChange={handleInputChange}
                  value={currentPage}
                />
              </div>
              <Button
                className="mx-1 btn btn-primary"
                disabled={!canGoBack}
                onClick={() => pageChangeHandler(1)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                >
                  <path
                    d="M0 5L5.35714 0.669872L5.35714 9.33013L0 5Z"
                    fill="#D5E1E9"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                >
                  <path
                    d="M0 5L5.35714 0.669872L5.35714 9.33013L0 5Z"
                    fill="#D5E1E9"
                  />
                </svg>
              </Button>
              <Button
                className="mx-1 btn btn-primary"
                disabled={!canGoBack}
                onClick={onPrevPage}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                >
                  <path
                    d="M0 5L5.35714 0.669872L5.35714 9.33013L0 5Z"
                    fill="#D5E1E9"
                  />
                </svg>
              </Button>
              <Button
                className="mx-1 btn btn-primary"
                disabled={!canGoNext}
                onClick={onNextPage}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="10"
                  viewBox="0 0 7 10"
                  fill="none"
                >
                  <path
                    d="M6.28571 5L0.928563 0.669872L0.928563 9.33013L6.28571 5Z"
                    fill="#D5E1E9"
                  />
                </svg>
              </Button>
              <Button
                className="mx-1 btn btn-primary"
                disabled={!canGoNext}
                onClick={() => pageChangeHandler(noOfPages)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="10"
                  viewBox="0 0 7 10"
                  fill="none"
                >
                  <path
                    d="M6.28571 5L0.928563 0.669872L0.928563 9.33013L6.28571 5Z"
                    fill="#D5E1E9"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="10"
                  viewBox="0 0 7 10"
                  fill="none"
                >
                  <path
                    d="M6.28571 5L0.928563 0.669872L0.928563 9.33013L6.28571 5Z"
                    fill="#D5E1E9"
                  />
                </svg>
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PaginationComponent;
