import React, { useState, useEffect } from "react";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import { ColumnDef } from "@tanstack/react-table";
import {
  EMPTY_ERROR,
  Player,
  defaultPageSize,
} from "../../../common/constants";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import { Button, InputGroup, Form, Row, Col, Spinner } from "react-bootstrap";
import Select from "react-select";
import { fetchPlayerList, fetchTeamList } from "../../service";
import { CustomStatusCode, HttpStatus } from "../../../utils/constants";
import "./style.scss";
import { ClearInputCross } from "../../../common/SVG";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactToastr from "../../../common/components/ReactToaster";
import Typography from "../../../common/components/Typography";
import { useSelector } from "react-redux";
import { CommonMessage } from "../../../common/message";
import { getPaginationQuery } from "../../../utils/userUtil";

export const Players = () => {
  const leaguesMetadata = useSelector((state: any) => state.leagues);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedPage = searchParams.get("page");
  const [currentPage, setCurrentPage] = useState(
    selectedPage ? +selectedPage : 1,
  );
  const [searchTerm, setSearchTerm] = useState(null);
  const [selectedTeamOptions, setSelectedTeamOptions] = useState<any[]>(null);
  const [selectedTeams, setSelectedTeams] = useState<string[]>(null);
  const [leaguesOption, setLeaguesOption] = useState(
    leaguesMetadata.leaguesSeason,
  );
  const [selectedLeagueOption, setSelectedLeagueOption] = useState(null);
  const [filterLeagueOption, setFilterLeagueOption] = useState({
    isSearch: false,
    value: null,
  });
  const [playerList, setPlayerList] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [teamList, setTeamList] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });

  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  useEffect(() => {
    setLeaguesOption(leaguesMetadata.leaguesSeason);
  }, [leaguesMetadata]);

  useEffect(() => {
    getTeamList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedPlayer) {
      navigate(`/players/${selectedPlayer.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlayer]);

  useEffect(() => {
    getPlayerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, currentPage]);

  useEffect(() => {
    if (searchTerm !== null) {
      const timerId = setTimeout(() => {
        if (currentPage === 1) getPlayerList();
        else setCurrentPage(1);
      }, 1000);

      return () => clearTimeout(timerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    if (selectedTeams !== null) {
      if (currentPage === 1) getPlayerList();
      else setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeams]);

  useEffect(() => {
    if (filterLeagueOption.isSearch) {
      if (currentPage === 1) getPlayerList();
      else setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterLeagueOption]);

  useEffect(() => {
    setSearchParams({ page: String(currentPage) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (!+selectedPage) {
      setSearchParams({ page: "1" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPage]);

  const getPlayerList = async () => {
    setPlayerList({
      ...playerList,
      isLoading: true,
      error: EMPTY_ERROR,
    });
    const resData: any = await fetchPlayerList({
      query: {
        name: searchTerm,
        teamIds: selectedTeams || [],
        leagueTypeId: filterLeagueOption.value?.id,
      },
      page: getPaginationQuery(currentPage, pageSize),
    });

    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setPlayerList((pre) => ({
        ...pre,
        isLoading: false,
        data: resData?.data?.data,
      }));
    } else {
      setPlayerList({
        ...playerList,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      });
    }
  };

  const getTeamList = async (name?: string) => {
    const resData: any = await fetchTeamList();
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setTeamList((pre) => ({
        ...pre,
        isLoading: false,
        data: resData?.data?.data,
      }));
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message || "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
    }
  };

  const handleTeamFilter = (selectedOptions = []) => {
    setSelectedTeamOptions(selectedOptions);
    setSelectedTeams(selectedOptions?.map((option) => option.value));
  };

  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: "profileImageUrl",
        id: "profileImageUrl",
        header: () => <span>{Player.profile}</span>,
        cell: (info) => {
          return (
            <img src={info.getValue()} width={100} height={100} alt="profile" />
          );
        },
        canSort: false,
      },
      {
        accessorKey: "name",
        id: "name",
        header: () => <span>{Player.name}</span>,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "futyPosition",
        id: "futyPosition",
        header: () => <span>{Player.position}</span>,
        cell: (info) => info.getValue(),
      },

      {
        accessorKey: "team.name",
        id: "teamName",
        header: () => <span>{Player.team}</span>,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "Action",
        id: "action",
        header: () => <span>{Player.action}</span>,
        cell: (info) => (
          <Button
            size="sm"
            variant="link"
            onClick={() => {
              setSelectedPlayer(info?.row?.original);
            }}
          >
            {Player.details}
          </Button>
        ),
        canSort: false,
      },
    ],
    [],
  );

  return (
    <>
      <Typography variant="pageTitle">{Player.player}</Typography>
      {
        <Row className="mb-2">
          <Col lg="6">
            <InputGroup className="search-input" size="lg">
              <Form.Control
                aria-label="Large"
                value={searchTerm || ""}
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Search Players"
                onChange={(event) => setSearchTerm(event.target.value)}
              />
              <Button
                variant="outline-secondary"
                disabled={!searchTerm}
                onClick={() => setSearchTerm("")}
              >
                <ClearInputCross />
              </Button>
            </InputGroup>
          </Col>
          <Col>
            <Select
              className="select-filter"
              placeholder="Filter by league"
              value={selectedLeagueOption}
              options={leaguesOption}
              onChange={(e) => {
                setSelectedLeagueOption(e);
                setFilterLeagueOption({ isSearch: true, value: e });
              }}
              isClearable={true}
              isLoading={leaguesMetadata.isLoading}
              loadingMessage={() => <Spinner variant="primary" size="sm" />}
              noOptionsMessage={() =>
                leaguesMetadata?.isError
                  ? leaguesMetadata?.errorMsg
                  : CommonMessage.noData
              }
            />
          </Col>
          <Col>
            <Select
              className="select-filter"
              placeholder="Filter by teams"
              closeMenuOnSelect={false}
              isMulti
              value={selectedTeamOptions}
              options={teamList?.data?.map((team) => ({
                label: team.name,
                value: team.id,
              }))}
              onChange={handleTeamFilter}
            />
          </Col>
        </Row>
      }
      <TableWrapper>
        <ReactTable
          isLoading={playerList?.isLoading}
          data={playerList?.data?.players || []}
          hasError={playerList?.error?.isError}
          errorMessage={playerList?.error?.errorMsg}
          columns={columns}
          pageChangeHandler={setCurrentPage}
          pageSizeHandler={setPageSize}
          currentPageNumber={currentPage}
          totalRows={playerList?.data?.totalCount}
          rowsPerPage={pageSize}
        />
      </TableWrapper>
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleClose}
        position="top-center"
        autohide
      />
    </>
  );
};
