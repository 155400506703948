import { UserIdentity } from "../../../common/constants";
import "./style.scss";
import LoadingUserDetail from "./LoadingUserDetail";

export const UserDetailsInfo = ({ user, userMetadata }) => {
  const { isLoading, matchedApplicants, onfidoStatus } = userMetadata;
  return (
    <>
      <div className="mt-2">
        <label className="d-block fw-light fs-12">
          {UserIdentity.username}
        </label>
        <span className="d-block  fw-medium text-capitalize mb-3">
          {user?.username}
        </span>
      </div>
      <div className="mt-2">
        <label className="d-block fw-light fs-12">
          {UserIdentity.onfidoStatus}
        </label>
        <span className="d-block fw-medium text-capitalize mb-3">
          <LoadingUserDetail isLoading={isLoading}>
            {onfidoStatus ?? UserIdentity.noValue}
          </LoadingUserDetail>
        </span>
      </div>
      <div className="mt-2">
        <label className="d-block fw-light fs-12">
          {UserIdentity.matchedApplicants}
        </label>
        <span className="d-block  fw-medium text-capitalize mb-3">
          <LoadingUserDetail isLoading={isLoading}>
            {matchedApplicants?.length ? (
              <>
                {matchedApplicants.map((user, index) => (
                  <div key={`document-${index}`}>{`${index + 1}. ${
                    user?.username || ""
                  }${user?.mobile ? ` (${user?.mobile})` : ""}`}</div>
                ))}
              </>
            ) : (
              UserIdentity.noValue
            )}
          </LoadingUserDetail>
        </span>
      </div>
    </>
  );
};
