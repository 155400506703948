import { Button, Col, Row } from "react-bootstrap";
import Typography from "../../../common/components/Typography";
import { Form as BsForm } from "react-bootstrap";
import { Field, FieldArray, FormikErrors, FormikTouched } from "formik";
import Select from "react-select";
import {
  CreateTaskValues,
  currencyOptions,
  GameModesEnum,
  gameModesOptions,
  iconRedirectionUrlMap,
  MatchStatus,
  Option,
  RewardCurrencyEnum,
  taskIcons,
  taskIconsEnum,
  taskLogoMap,
  TaskValue,
  trackingTypeOptions,
  TrackingTypesEnum,
} from "../../tasksUtils";
import { useEffect, useState } from "react";
import {
  autoFillRedirectionUrl,
  fetchFantasyMatches,
  fetchGoalfestGameweeks,
  fetchQuizMatches,
} from "../../service";

const customOptions = (option) => (
  <div className="d-flex align-items-center">
    <img
      src={taskLogoMap[option.value]}
      alt={option.label}
      style={{ width: 20, height: 20, marginRight: 10 }}
    />
    {option.label}
  </div>
);

interface ITaskField {
  idx: number;
  task: TaskValue;
  errors: any;
  values: CreateTaskValues;
  touched: FormikTouched<CreateTaskValues>;
  setValues: (
    values: React.SetStateAction<CreateTaskValues>,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<CreateTaskValues>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<CreateTaskValues>>;
  setFieldError: (field: string, message: string | undefined) => void;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<CreateTaskValues>>;
  remove: (idx: number) => void;
  maxTier: number;
}

const TaskFields: React.FC<ITaskField> = ({
  idx,
  task,
  errors,
  values,
  touched,
  setValues,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  remove,
  maxTier,
}) => {
  const gameModes = values.tasks[idx].gameModes as GameModesEnum[];
  const [isLoadingMatches, setIsLoadingMatches] = useState<boolean>(false);
  const [matchOptions, setMatchOptions] = useState<Option[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingMatches(true);
      setMatchOptions([]);

      const fetchPromises = [];
      if (gameModes?.includes(GameModesEnum.TG_TRIPLE_THREAT)) {
        fetchPromises.push(
          fetchQuizMatches({
            isPublished: true,
            status: [MatchStatus.SCHEDULED],
          }),
        );
      }

      if (gameModes?.includes(GameModesEnum.TG_GOALFEST)) {
        fetchPromises.push(
          fetchGoalfestGameweeks({
            query: {
              isPublished: true,
              status: [MatchStatus.SCHEDULED],
            },
          }),
        );
      }

      if (gameModes?.includes(GameModesEnum.TG_FANTASY)) {
        fetchPromises.push(
          fetchFantasyMatches({
            query: {
              isPublished: true,
              contestType: GameModesEnum.TG_FANTASY,
              status: [MatchStatus.SCHEDULED],
            },
          }),
        );
      }

      const results = await Promise.all(fetchPromises);
      setMatchOptions(results.flat());
      setIsLoadingMatches(false);
    };

    fetchData();
  }, [gameModes]);

  const isIconSocial = (icon: taskIconsEnum) =>
    [
      taskIconsEnum.INSTAGRAM,
      taskIconsEnum.TELEGRAM,
      taskIconsEnum.X,
      taskIconsEnum.YOUTUBE,
    ].includes(icon);

  return (
    <section className="border p-2 rounded mb-3">
      {maxTier > 1 && (
        <div className="d-flex justify-content-end">
          <Button
            variant="danger"
            onClick={() => {
              remove(idx);
              setFieldValue("maximumTier", maxTier - 1);
            }}
            className="my-2"
          >
            Remove Tier
          </Button>
        </div>
      )}
      <Typography variant="heading3">Tier {idx + 1}</Typography>
      <Row>
        <Col md={4}>
          <BsForm.Label>Task Title</BsForm.Label>
          <Field
            type="text"
            name={`tasks.${idx}.title`}
            className="form-control"
          />
          {errors.tasks?.at(idx)?.title && touched.tasks?.at(idx)?.title ? (
            <Typography className="text-danger fs-10">
              {errors.tasks[idx].title}
            </Typography>
          ) : null}
        </Col>
        <Col md={4}>
          <BsForm.Label>Tier Number</BsForm.Label>
          <Field
            type="number"
            name={`tasks.${idx}.tierNumber`}
            className="form-control"
            value={idx + 1}
            disabled={true}
          />
        </Col>
      </Row>

      <Row className="d-flex my-3 align-items-end">
        <Col md={4}>
          <BsForm.Label>Select Icon</BsForm.Label>
          <Select
            name={`tasks.${idx}.iconUrl`}
            options={taskIcons}
            placeholder="Select icon"
            defaultValue={taskIcons[0]}
            value={taskIcons.find((option) => option.value === task.iconUrl)}
            onChange={(option) => {
              setFieldValue(`tasks.${idx}.iconUrl`, option ? option.value : "");
              if (isIconSocial(option.value as taskIconsEnum)) {
                setFieldError(
                  `tasks.${idx}.redirectionUrl`,
                  `Hint: URL must start with ${
                    iconRedirectionUrlMap[option.label]
                  }`,
                );
                setFieldTouched(`tasks.${idx}.redirectionUrl`, true, false);
              } else {
                setFieldError(`tasks.${idx}.redirectionUrl`, undefined);
              }
              setFieldError(`tasks.${idx}.iconUrl`, undefined);
            }}
            formatOptionLabel={customOptions}
            styles={{
              menu: (base, props) => ({
                ...base,
                zIndex: 300,
              }),
            }}
          />
          {errors.tasks?.at(idx)?.iconUrl && touched.tasks?.at(idx)?.iconUrl ? (
            <Typography className="text-danger fs-10">
              {errors.tasks[idx].iconUrl}
            </Typography>
          ) : null}
        </Col>
        <Col md={4} className="position-relative">
          <BsForm.Label>Redirection URL</BsForm.Label>
          <Field
            type="text"
            name={`tasks.${idx}.redirectionUrl`}
            disabled={
              values?.tasks[idx]?.isInAppUrl &&
              values?.tasks?.[idx]?.trackingDetails?.trackingType ===
                TrackingTypesEnum.GAME_PARTICIPATION
            }
            className="form-control"
          />
          {values?.tasks[idx]?.isInAppUrl ? (
            <Typography className="fs-10 position-absolute">
              Hint: enter in-game url e.g:"/game"
            </Typography>
          ) : (
            errors.tasks?.at(idx)?.redirectionUrl &&
            touched.tasks?.at(idx)?.redirectionUrl && (
              <Typography className="text-danger fs-10 position-absolute">
                {errors.tasks[idx].redirectionUrl}
              </Typography>
            )
          )}
        </Col>
        <Col md={2} className="position-relative">
          <BsForm.Check
            type="checkbox"
            label="isInAppUrl"
            name={`tasks.${idx}.isInAppUrl`}
            checked={task?.isInAppUrl}
            onChange={(e) => {
              setFieldValue(`tasks.${idx}.isInAppUrl`, !task?.isInAppUrl);
              if (e?.target?.checked) {
                setFieldValue(`tasks.${idx}.isTrackable`, true);
              }
            }}
          />
          {errors.tasks?.at(idx)?.isInAppUrl &&
            touched.tasks?.at(idx)?.isInAppUrl && (
              <Typography className="text-danger fs-10 position-absolute">
                {errors.tasks?.at(idx)?.isInAppUrl}
              </Typography>
            )}
        </Col>
        <Col md={2} className="position-relative">
          <BsForm.Check
            type="checkbox"
            label="isTrackable"
            name={`tasks.${idx}.isTrackable`}
            checked={task.isTrackable}
            onChange={() =>
              setFieldValue(`tasks.${idx}.isTrackable`, !task.isTrackable)
            }
          />
          {errors.tasks?.at(idx)?.isTrackable &&
            touched.tasks?.at(idx)?.isTrackable && (
              <Typography className="text-danger fs-10 position-absolute">
                {errors.tasks?.at(idx)?.isTrackable}
              </Typography>
            )}
        </Col>
      </Row>

      <FieldArray name={`tasks.${idx}.rewards`}>
        {({ push, remove }) => (
          <div className="border p-2 rounded my-3">
            <Row className="d-flex justify-content-between align-items-center">
              <Col md={10}>
                <Typography variant="heading4">Rewards Config</Typography>
              </Col>
              <Col>
                <Button
                  variant="primary"
                  onClick={() =>
                    push({
                      amount: 0,
                      currency: RewardCurrencyEnum.FUTY_CREDITS,
                      rewardAfterMins: 0,
                    })
                  }
                >
                  Add Reward
                </Button>
              </Col>
            </Row>
            {task.rewards.map((_, index) => (
              <Row key={index} className="p-3 rounded d-flex align-items-end">
                <BsForm.Label className="text-black">
                  Reward {index + 1}
                </BsForm.Label>

                <Col md={3} className="position-relative">
                  <BsForm.Label>Amount</BsForm.Label>
                  <Field
                    type="number"
                    name={`tasks.${idx}.rewards.${index}.amount`}
                    className="form-control"
                    placeholder="Amount"
                  />
                  {errors.tasks?.at(idx)?.rewards?.at(index)?.amount &&
                    touched.tasks?.at(idx)?.rewards?.at(index)?.amount && (
                      <Typography className="text-danger fs-10 position-absolute">
                        {errors.tasks?.at(idx)?.rewards?.at(index)?.amount}
                      </Typography>
                    )}
                </Col>
                <Col md={3} className="position-relative">
                  <BsForm.Label>Currency</BsForm.Label>
                  <Select
                    options={currencyOptions}
                    name={`tasks.${idx}.rewards.${index}.currency`}
                    className="select-filter border-0"
                    placeholder="Currency"
                    value={currencyOptions.find(
                      (option) =>
                        option.value === task?.rewards?.at(index)?.currency,
                    )}
                    onChange={(selectedOption) => {
                      setFieldValue(
                        `tasks.${idx}.rewards.${index}.currency`,
                        selectedOption ? selectedOption.value : "",
                      );
                      setFieldError(
                        `tasks.${idx}.rewards.${index}.currency`,
                        undefined,
                      );
                    }}
                    styles={{
                      menu: (base, props) => ({
                        ...base,
                        zIndex: 300,
                      }),
                    }}
                  />
                  {errors.tasks?.at(idx)?.rewards?.at(index)?.currency &&
                    touched.tasks?.at(idx)?.rewards?.at(index)?.currency && (
                      <Typography className="text-danger fs-10 position-absolute">
                        {errors.tasks?.at(idx)?.rewards?.at(index)?.currency}
                      </Typography>
                    )}
                </Col>
                <Col md={3} className="position-relative">
                  <BsForm.Label>RewaredAfterMins</BsForm.Label>
                  <Field
                    type="number"
                    name={`tasks.${idx}.rewards.${index}.rewardAfterMins`}
                    className="form-control"
                    placeholder="Delay in minutes"
                  />
                </Col>
                {values.tasks.at(idx).rewards.length > 1 && (
                  <Col md={3}>
                    <Button variant="danger" onClick={() => remove(index)}>
                      Remove Reward
                    </Button>
                  </Col>
                )}
              </Row>
            ))}
          </div>
        )}
      </FieldArray>

      <Row className="my-3">
        <Typography variant="heading4">Tracking details</Typography>
        <Col>
          <Select
            name={`tasks.${idx}.trackingDetails.trackingType`}
            placeholder="Select tracking type"
            options={trackingTypeOptions}
            value={trackingTypeOptions.find(
              (option) => option.value === task.trackingDetails.trackingType,
            )}
            onChange={(selectedOption) => {
              setFieldValue(
                `tasks.${idx}.trackingDetails.trackingType`,
                selectedOption ? selectedOption.value : "",
              );
              setFieldError(
                `tasks.${idx}.trackingDetails.trackingType`,
                undefined,
              );
              if (
                selectedOption?.value === TrackingTypesEnum.GAME_PARTICIPATION
              ) {
                setFieldValue(`tasks.${idx}.isInAppUrl`, true);
                setFieldValue(`tasks.${idx}.isTrackable`, true);
              } else {
                setFieldValue(`tasks.${idx}.isInAppUrl`, false);
                setFieldValue(`tasks.${idx}.isTrackable`, false);
              }
            }}
            styles={{
              menu: (base, props) => ({
                ...base,
                zIndex: 300,
              }),
            }}
          />
          {errors.tasks?.at(idx)?.trackingDetails?.trackingType ? (
            <Typography className="text-danger fs-10">
              {errors.tasks?.at(idx)?.trackingDetails?.trackingType}
            </Typography>
          ) : null}
        </Col>
      </Row>
      {(task.isTrackable ||
        task?.trackingDetails?.trackingType ===
          TrackingTypesEnum.GAME_PARTICIPATION) && (
        <>
          <Row>
            <Col md={4}>
              <BsForm.Group>
                <Select
                  isMulti
                  name={`tasks.${idx}.gameModes`}
                  placeholder="Select Game modes"
                  options={gameModesOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  menuPlacement="top"
                  value={gameModesOptions.filter((option) =>
                    task.gameModes?.includes(option.value),
                  )}
                  onChange={(selectedOptions) => {
                    const updatedValues = { ...values };
                    const gameModes = selectedOptions?.length
                      ? selectedOptions.map((option) => option.value)
                      : null;
                    // update gameModes array
                    updatedValues.tasks[idx].gameModes = gameModes;

                    // clear previously selected matches (if any)
                    updatedValues.tasks[idx].trackingDetails.matchIds = [];
                    updatedValues.tasks[idx].trackingDetails.gameIds = [];

                    if (updatedValues.tasks[idx]?.isInAppUrl) {
                      updatedValues.tasks[idx].redirectionUrl =
                        autoFillRedirectionUrl(
                          gameModes,
                          updatedValues.tasks[idx].trackingDetails?.gameIds ??
                            [],
                        );
                    }

                    setValues(updatedValues);
                  }}
                  onBlur={() => {
                    const updatedValues = { ...values };
                    if (!updatedValues.tasks[idx]?.gameModes?.length) {
                      setFieldError(
                        `tasks.${idx}.gameModes`,
                        "Please select game modes",
                      );
                    } else {
                      setFieldError(`tasks.${idx}.gameModes`, undefined);
                    }
                  }}
                  styles={{
                    menu: (base, props) => ({
                      ...base,
                      zIndex: 300,
                    }),
                  }}
                />
                {errors.tasks?.at(idx)?.gameModes && (
                  <Typography className="fs-10 text-danger">
                    {errors.tasks?.at(idx)?.gameModes}
                  </Typography>
                )}
              </BsForm.Group>
            </Col>
            <Col md={4}>
              <Select
                isMulti
                isLoading={isLoadingMatches}
                name={`tasks.${idx}.trackingDetails.matchIds`}
                placeholder="Select matches"
                options={matchOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                value={matchOptions?.filter((option) =>
                  task?.trackingDetails?.matchIds?.includes(option.value),
                )}
                isDisabled={task.trackingDetails.isAnyMatch}
                menuPlacement="top"
                styles={{
                  menu: (base, props) => ({
                    ...base,
                    zIndex: 300,
                  }),
                }}
                onChange={(selectedOptions) => {
                  const matchIds = selectedOptions
                    ? selectedOptions.map((option) => option.value)
                    : [];

                  const gameIds = selectedOptions
                    ? selectedOptions.map((option) => option.optional)
                    : [];

                  const gameModesArr = selectedOptions
                    ? (selectedOptions.map((option) => {
                        return option?.label?.split("-")[0];
                      }) as GameModesEnum[])
                    : [];

                  const updatedValues = { ...values };

                  updatedValues.tasks[idx].trackingDetails.matchIds = matchIds;
                  updatedValues.tasks[idx].trackingDetails.gameIds = gameIds;
                  updatedValues.tasks[idx].trackingDetails.gameModesArr =
                    gameModesArr;
                  updatedValues.tasks[idx].trackingDetails.totalMatchesCount =
                    matchIds.length;

                  if (values?.tasks[idx]?.isInAppUrl) {
                    updatedValues.tasks[idx].redirectionUrl =
                      autoFillRedirectionUrl(
                        updatedValues.tasks[idx].gameModes ?? [],
                        gameIds,
                      );
                  }

                  setValues(updatedValues);
                }}
              />
            </Col>
            <Col>
              <BsForm.Check
                type="checkbox"
                label="isAnyMatch"
                name={`tasks.${idx}.trackingDetails.isAnyMatch`}
                checked={task.trackingDetails.isAnyMatch}
                className="pt-2"
                onChange={(e) => {
                  const updatedValues = { ...values };
                  if (e.target.checked) {
                    updatedValues.tasks[
                      idx
                    ].trackingDetails.totalMatchesCount = 1;
                    if (
                      !updatedValues.tasks[idx]?.gameModes?.length ||
                      updatedValues.tasks[idx]?.gameModes?.length === 0
                    ) {
                      setFieldError(
                        `tasks.${idx}.gameModes`,
                        "Please select game modes",
                      );
                    }
                  } else {
                    updatedValues.tasks[
                      idx
                    ].trackingDetails.totalMatchesCount = 0;
                    setFieldError(`tasks.${idx}.gameModes`, undefined);
                  }
                  const isAnyMatch = !task.trackingDetails.isAnyMatch;
                  updatedValues.tasks[idx].trackingDetails.isAnyMatch =
                    isAnyMatch;
                  updatedValues.tasks[idx].trackingDetails.matchIds = [];
                  updatedValues.tasks[idx].trackingDetails.gameIds = [];
                  updatedValues.tasks[idx].trackingDetails.gameModesArr = null;

                  if (updatedValues.tasks[idx]?.isInAppUrl) {
                    updatedValues.tasks[idx].redirectionUrl =
                      autoFillRedirectionUrl(
                        updatedValues.tasks[idx].gameModes ?? [],
                        updatedValues.tasks[idx].trackingDetails?.gameIds ?? [],
                      );
                  }
                  setValues(updatedValues);
                }}
              />
            </Col>
          </Row>
          <Row className="my-3">
            <Col md={3}>
              <BsForm.Label>Total Matches Count</BsForm.Label>
              <Field
                type="number"
                name={`tasks.${idx}.trackingDetails.totalMatchesCount`}
                className="form-control"
                placeholder="Total Matches Count"
                min={0}
                disabled={!task?.trackingDetails?.isAnyMatch}
              />
            </Col>
          </Row>
        </>
      )}
    </section>
  );
};

export default TaskFields;
