import { API_METHOD } from "../api/api";
import { getBase } from "../apiconfig";
import { URLS } from "../common/url";
import { handleApiRequest } from "../utils/apiUtil";

export const fetchUserStats = async (
  startDate?: string,
  endDate?: string,
  config = {},
) => {
  const url: string =
    getBase() +
    "/api" +
    URLS.USER_STATS.replace("{startDate}", startDate).replace(
      "{endDate}",
      endDate,
    );
  return await handleApiRequest(API_METHOD.GET, url, config);
};

export const fetchUserTeamStats = async (
  startDate?: string,
  endDate?: string,
  leagueSeasonId?: string,
  config = {},
) => {
  let url: string =
    getBase() +
    "/api" +
    URLS.USER_TEAM_STATS +
    `?startDate=${startDate}&endDate=${endDate}`;
  if (leagueSeasonId) url += `&leagueSeasonId=${leagueSeasonId}`;
  return await handleApiRequest(API_METHOD.GET, url, config);
};
export const fetchIdVerificationStats = async (payload) => {
  const { startDate, endDate } = payload;
  const url: string =
    getBase() +
    "/api" +
    URLS.USER_IDENTITY_URL +
    `/status?startDate=${startDate}&endDate=${endDate}`;
  return await handleApiRequest(API_METHOD.GET, url);
};

export const fetchReferralStats = async (config: any = {}) => {
  const { startDate, endDate, skip, limit, ...restConfig } = config;
  let url: string = getBase() + "/api" + URLS.REFERRAL_STATS;
  if (startDate && endDate) {
    url += `&startDate=${startDate}&endDate=${endDate}`;
  }
  url += `&skip=${skip}&limit=${limit}`;
  return await handleApiRequest(API_METHOD.GET, url, restConfig);
};

export const fetchRetentionViewData = async (config: any = {}) => {
  const { leagueSeasonId } = config;
  const url: string =
    getBase() + "/api" + URLS.RETENTION_VIEW_URL + `/${leagueSeasonId}`;
  return await handleApiRequest(API_METHOD.GET, url);
};

export const fetchTelegramDefaultMetrics = async (config: any = {}) => {
  let url: string = getBase() + `/api/${URLS.DEFAULT_TELEGRAM_METRICS_URL}?`;
  const { startDate, endDate } = config;

  if (startDate) url += `startDate=${startDate}&`;
  if (endDate) url += `endDate=${endDate}`;
  return await handleApiRequest(API_METHOD.GET, url);
};
