import {
  DateRangeEnum,
  DateRangeEnumLabel,
  DaysToGetEnum,
} from "../common/constants";
import { getLocalDisplayedDateRange } from "../teamslist/component/TeamConfigure/teamConfigHelper";
export const staticDateOption = [
  {
    key: DateRangeEnum.last7Days,
    label: DateRangeEnumLabel[DateRangeEnum.lastWeek],
    daysToGet: DaysToGetEnum.prev,
    daysNumber: 7,
  },
  {
    key: DateRangeEnum.last15Days,
    label: DateRangeEnumLabel[DateRangeEnum.last15Days],
    daysToGet: DaysToGetEnum.prev,
    daysNumber: 15,
  },
  {
    key: DateRangeEnum.lastMonth,
    label: DateRangeEnumLabel[DateRangeEnum.lastMonth],
    daysToGet: DaysToGetEnum.prev,
    daysNumber: 30,
  },
];

export const tgStatsStaticDateOption = [
  ...staticDateOption,
  {
    key: DateRangeEnum.lifetime,
    label: DateRangeEnumLabel[DateRangeEnum.lifetime],
    daysToGet: null,
    daysNumber: null,
  },
];

export const initialDateRange = [null, null];
export const initialStaticDateRange = {
  [DateRangeEnum.last7Days]: true,
  [DateRangeEnum.last15Days]: false,
  [DateRangeEnum.lastMonth]: false,
};

export const referralStatsStaticDateRange = {
  ...initialStaticDateRange,
  [DateRangeEnum.last7Days]: false,
};

export const referralTgStatsStaticDateRange = {
  ...initialStaticDateRange,
  [DateRangeEnum.lifetime]: false,
};

export const getInitialDateRange = () => {
  return getLocalDisplayedDateRange(
    staticDateOption[0].daysToGet,
    staticDateOption[0].daysNumber,
  );
};

export const initialTgUserStats = {
  telegramUsersCount: 0,
  registeredTelegramUserCount: 0,
  gameJoinedUserCount: 0,
  tripleThreatJoinedUserCount: 0,
  fantasyJoinedUserCount: 0,
  goalFestJoinedUserCount: 0,
  tripleThreatUserTeamsCount: 0,
  fantasyUserTeamsCount: 0,
  goalFestUserTeamsCount: 0,
  userReferredAtleastOneUserCount: 0,
  totalReferralsCount: 0,
};

export const tgUserStatsMetricMap = {
  telegramUsersCount: "Number of unique users",
  registeredTelegramUserCount: "Number of registered users",
  gameJoinedUserCount: "Number of users who played any game",
  tripleThreatJoinedUserCount: "Number of users who have played Triple Threat",
  fantasyJoinedUserCount: "Number of users who have played Fantasy",
  goalFestJoinedUserCount: "Number of users who have played Goalfest",
  tripleThreatUserTeamsCount: "Total Triple Threat games played",
  fantasyUserTeamsCount: "Total Fantasy games palyed",
  goalFestUserTeamsCount: "Total Goalfest games played",
  userReferredAtleastOneUserCount:
    "Number of users who have referred at least one user",
  totalReferralsCount: "Total number of referrals",
};

export const dummyTgReferrals = [
  {
    username: "alpha",
    name: "Alpha",
    referralCount: 15,
  },
  {
    username: "alpha",
    name: "Alpha",
    referralCount: 20,
  },
  {
    username: "alpha",
    name: "Alpha",
    referralCount: 10,
  },
  {
    username: "alpha",
    name: "Alpha",
    referralCount: 10,
  },
  {
    username: "alpha",
    name: "Alpha",
    referralCount: 10,
  },
  {
    username: "alpha",
    name: "Alpha",
    referralCount: 10,
  },
  {
    username: "alpha",
    name: "Alpha",
    referralCount: 10,
  },
];
