import { Info } from "../../ReactTable/Svg";

const BasicError = ({ error, className = "" }) => {
  return (
    <div
      className={`fs-2 w-100 d-flex align-items-center justify-content-center ${className}`}
    >
      <Info />
      <span className="ml-2">{error}</span>
    </div>
  );
};

export default BasicError;
