import React from "react";
import "./layout.scss";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Sidebar";
import classNames from "classnames";
import { Navigate, Outlet } from "react-router-dom";
import { isUserLoggedIn } from "../../utils/userUtil";
import Header from "../Header";

interface ILayout {
  children?: React.ReactNode;
  isRegistered?: boolean;
  showFooter?: boolean;
  showSidebar?: boolean;
  showBackBtn?: boolean;
  showSkipBtn?: boolean;
  showSaveBtn?: boolean;
  showDeleteButton?: boolean;
  isSaveBtnDisabled?: boolean;
  saveBtnText?: string;
  deleteBtnText?: string;
  className?: string;
  viewType?: "default" | "list";
  onSave?: React.MouseEventHandler;
  onSkip?: React.MouseEventHandler;
  onBack?: React.MouseEventHandler;
  onDelete?: React.MouseEventHandler;
}

const Layout = ({
  children,
  showFooter = true,
  showSidebar = true,
  showBackBtn,
  showDeleteButton,
  showSkipBtn,
  showSaveBtn = true,
  onSave,
  onSkip,
  onBack,
  onDelete,
  isRegistered,
  saveBtnText,
  deleteBtnText,
  className,
  isSaveBtnDisabled,
  viewType = "list",
}: ILayout) => {
  const userLoggedIn = isUserLoggedIn();
  return userLoggedIn ? (
    <>
      <Header />
      <section className="app-container">
        <div className={classNames("app-content-wrapper", className)}>
          {showSidebar && <Sidebar isCollapsableSidebar={true} />}
          <Container
            fluid
            className="overflow-auto px-4 d-flex flex-grow-1 h-100 w-100"
          >
            <Row className="flex-grow-1 w-100 h-100">
              <Col
                className={classNames("d-flex h-100", {
                  "mx-auto": !showSidebar,
                })}
                lg={viewType === "list" ? 12 : 10}
              >
                <main
                  className={
                    viewType === "list"
                      ? "app-content overflow-x-hidden"
                      : "app-content"
                  }
                >
                  <Outlet />
                </main>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  ) : (
    <Navigate to={"/login"} />
  );
};

export default Layout;
