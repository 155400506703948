enum RewardCategoryEnum {
  NORMAL = "NORMAL",
  BONUS = "BONUS",
}

enum RewardCurrencyEnum {
  FUTY_CREDITS = "FUTY_CREDITS",
}

enum DayTaskTypeEnum {
  LOGIN = "LOGIN",
}

interface DailyStreakDataResponse {
  statusCode: number;
  data: DayRewardType[];
}

interface DayRewardType {
  id?: string;
  day?: number;
  taskType: DayTaskTypeEnum;
  rewardConfig: RewardConfigType[];
}

interface RewardConfigType {
  amount?: number;
  category?: RewardCategoryEnum;
  currency?: RewardCurrencyEnum;
}

interface ITriggerToaster {
  heading?: string;
  message: string;
  variant?: "success" | "warning" | "danger";
  route?: string;
}

export const initialRewardConfig: RewardConfigType = {
  currency: RewardCurrencyEnum.FUTY_CREDITS,
  category: RewardCategoryEnum.NORMAL,
};

export const initialDayReward: DayRewardType = {
  taskType: DayTaskTypeEnum.LOGIN,
  rewardConfig: [initialRewardConfig],
};

export { RewardCategoryEnum, RewardCurrencyEnum, DayTaskTypeEnum };
export type {
  RewardConfigType,
  DayRewardType,
  DailyStreakDataResponse,
  ITriggerToaster,
};
