import { API_METHOD } from "../../../api/api";
import { getBase } from "../../../apiconfig";
import { handleApiRequest } from "../../../utils/apiUtil";
import { URLS } from "../../url";
import { DayRewardType } from "./types";

export const fetchStreakList = async (payload = {}, config = {}) => {
  const url: string = getBase() + "/api" + URLS.STREAK + "/list";
  return await handleApiRequest(API_METHOD.POST, url, payload, config);
};

export const updateDayPrize = async (payload: any = {}, config = {}) => {
  const { id, ...restPayload } = payload;
  const url: string = getBase() + "/api" + URLS.STREAK + `/${id}`;
  return await handleApiRequest(API_METHOD.PUT, url, restPayload, config);
};

export const deleteDayPrize = async (payload: any = {}, config = {}) => {
  const { id, ...restPayload } = payload;
  const url: string = getBase() + "/api" + URLS.STREAK + `/${id}`;
  return await handleApiRequest(API_METHOD.DELETE, url, restPayload, config);
};

export const createDayPrize = async (payload: any = {}, config = {}) => {
  const url: string = getBase() + "/api" + URLS.STREAK;
  return await handleApiRequest(API_METHOD.POST, url, payload, config);
};

export const mapStreakListToMap = (
  streakList: DayRewardType[],
): Record<number, DayRewardType> => {
  return streakList.reduce((acc, dayReward) => {
    acc[dayReward.day] = dayReward;
    return acc;
  }, {} as Record<number, DayRewardType>);
};

export const compareDay = (day1: DayRewardType, day2: DayRewardType) =>
  JSON.stringify(day1) === JSON.stringify(day2);
