import { Row, Col, Button, Form } from "react-bootstrap";
import { DateRange } from "../../constants";
import "./style.scss";
import { DateRangePickerComp } from "../DateRangePickerComp";

const DateRangeFilter = ({
  staticDateOption,
  dateChangeHandler,
  staticDateChangeHandler,
  staticDateRange,
  dateRange,
  canClear = true,
  dateRangeLabel = DateRange.dateText,
  dateRangePlaceholder = DateRange.placeholder,
}) => {
  return (
    <Row className="d-flex align-items-end date-range-wrapper mb-2 mt-1">
      <Col sm={6}>
        <Form.Group className="form-group date-of-birth">
          <Form.Label className="fs-12 fw-medium">{dateRangeLabel}</Form.Label>
          <DateRangePickerComp
            dateRange={dateRange}
            dateChangeHandler={dateChangeHandler}
            canClear={canClear}
            dateRangePlaceholder={dateRangePlaceholder}
          />
        </Form.Group>
      </Col>
      <Col>
        {staticDateOption?.map((option) => {
          return (
            <Button
              key={option.key}
              className="static-filter-button"
              variant={staticDateRange[option.key] ? "secondary" : "primary"}
              onClick={() =>
                staticDateChangeHandler(option, staticDateRange[option.key])
              }
            >
              {option.label}
            </Button>
          );
        })}
      </Col>
    </Row>
  );
};

export default DateRangeFilter;
