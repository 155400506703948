import { Button, Form, InputGroup } from "react-bootstrap";
import { ClearInputCross } from "../../SVG";

export const SearchWithClearButton = ({
  value,
  searchPlaceholder,
  inputChangeHandler,
  isButtonDisabled,
  clearButtonHandler,
  buttonVariant,
  className = "search-input",
}) => {
  return (
    <>
      <InputGroup className={className} size="lg">
        <Form.Control
          value={value}
          placeholder={searchPlaceholder}
          onChange={inputChangeHandler}
        />
        <Button
          variant={buttonVariant}
          disabled={isButtonDisabled}
          onClick={clearButtonHandler}
        >
          <ClearInputCross />
        </Button>
      </InputGroup>
    </>
  );
};
