import React, { useState, useEffect } from "react";
import { Card, CardBody, Row, Col, Button, Spinner } from "react-bootstrap";
import Typography from "../../common/components/Typography";
import {
  Action,
  EMPTY_ERROR,
  Leagues,
  leaguesSeasonPopulateMessage,
} from "../../common/constants";
import FormSelect from "../../common/components/FormSelect/FormSelect";
import {
  getActivePopulatedLeague,
  getActivePopulatedSeason,
  getActiveSeason,
  getLeaguesOptionData,
  getSelectedLeaguesSeasonOption,
} from "./leaguesUtils";
import { CustomStatusCode, HttpStatus } from "../../utils/constants";
import {
  fetchFreshLeaguesData,
  fetchLeaguesData,
  populateLeagueAndSeason,
  refreshPlayerScore,
} from "./service";
import ReactToastr from "../../common/components/ReactToaster";
import { CommonMessage, getCommonError } from "../../common/message";
import { useDispatch, useSelector } from "react-redux";
import {
  leaguesSeasonInitialState,
  setLeagueSeasonMetadata,
} from "../../redux/leagueSeasonSlice";
import ConfirmationModal from "../../common/components/Modal/ConfirmationModel";
import "./style.scss";
import { formatUTCDateToLocalString } from "../../utils/dateUtils";
import { showFailureToaster, showSuccessToaster } from "../../utils/apiUtil";
const initialErrorState = {
  leaguesError: EMPTY_ERROR,
  seasonsError: EMPTY_ERROR,
};

export const LeaguesConfigure = () => {
  const leaguesMetadata = useSelector((state: any) => state.leagues);
  const dispatch = useDispatch();
  const [leaguesOption, setLeaguesOption] = useState([]);
  const [seasonsOption, setSeasonsOption] = useState([]);
  const [isPopulatingLeaguesAndSeason, setIsPopulatingLeaguesAndSeason] =
    useState(false);
  const [isRefreshingPlayerScore, setIsRefreshingPlayerScore] = useState(false);
  const [isRefreshingStatus, setIsRefreshingStatus] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  // const [isMarkingSeasonActive, setIsMarkingSeasonActive] = useState(false);
  const [selectedLeagueOption, setSelectedLeagueOption] = useState(null);
  const [selectedSeasonOption, setSelectedSeasonOption] = useState(null);
  const [error, setError] = useState(initialErrorState);
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  useEffect(() => {
    const leaguesOption = leaguesMetadata.leaguesSeason;
    setLeaguesOption(leaguesOption);
    if (selectedLeagueOption && selectedSeasonOption) {
      const activePopulatedLeague = getActivePopulatedLeague(
        selectedLeagueOption,
        leaguesOption,
      );
      const activePopulatedSeason = getActivePopulatedSeason(
        activePopulatedLeague,
        selectedSeasonOption,
      );
      const seasonOption = getSelectedLeaguesSeasonOption(
        activePopulatedLeague,
      );
      setSeasonsOption(seasonOption);
      setSelectedLeagueOption(activePopulatedLeague);
      setSelectedSeasonOption(activePopulatedSeason);
    } else {
      const leaguesDefaultSelectedOption = leaguesOption?.length
        ? leaguesOption[0]
        : null;
      const seasonOption = getSelectedLeaguesSeasonOption(
        leaguesDefaultSelectedOption,
      );
      setSeasonsOption(seasonOption);
      const activeSeason = getActiveSeason(seasonOption);
      setSelectedLeagueOption(leaguesDefaultSelectedOption);
      setSelectedSeasonOption(activeSeason);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaguesMetadata]);

  const getLeaguesData = async () => {
    dispatch(
      setLeagueSeasonMetadata({
        ...leaguesMetadata,
        isLoading: true,
      }),
    );
    setIsRefreshingStatus(true);
    const resData: any = await fetchLeaguesData();
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setIsRefreshingStatus(false);
      dispatch(
        setLeagueSeasonMetadata({
          ...leaguesSeasonInitialState,
          leaguesSeason: getLeaguesOptionData(resData?.data?.data),
        }),
      );
    } else {
      setIsRefreshingStatus(false);
      dispatch(
        setLeagueSeasonMetadata({
          isLoading: false,
          isError: true,
          errorMsg: getCommonError(resData?.message),
          leaguesSeason: [],
        }),
      );
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };

  const handleLeaguesChange = (e) => {
    const seasonOption = e?.leagueSeasons?.map((item) => ({
      ...item,
      label: item?.name,
      value: item?.id,
    }));
    const activeSeason = getActiveSeason(seasonOption);
    setSelectedLeagueOption(e);
    setSelectedSeasonOption(activeSeason);
    setSeasonsOption(seasonOption || []);

    setError((prev) => ({
      ...prev,
      leaguesError: EMPTY_ERROR,
      seasonsError: EMPTY_ERROR,
    }));
  };

  const handleSeasonChange = () => {
    if (selectedLeagueOption && selectedSeasonOption) {
      setShowConfirmationModal(true);
    } else if (!selectedLeagueOption && !selectedSeasonOption)
      setError((prev) => ({
        ...prev,
        leaguesError: { isError: true, errorMsg: "Please select league" },
        seasonsError: { isError: true, errorMsg: "Please select season" },
      }));
    else if (!selectedLeagueOption)
      setError((prev) => ({
        ...prev,
        leaguesError: { isError: true, errorMsg: "Please select league" },
      }));
    else
      setError((prev) => ({
        ...prev,
        seasonsError: { isError: true, errorMsg: "Please select season" },
      }));
  };

  const refreshLeaguesData = async () => {
    dispatch(
      setLeagueSeasonMetadata({
        ...leaguesSeasonInitialState,
        isLoading: true,
      }),
    );
    const resData: any = await fetchFreshLeaguesData();
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      dispatch(
        setLeagueSeasonMetadata({
          ...leaguesSeasonInitialState,
          leaguesSeason: getLeaguesOptionData(resData?.data?.data),
        }),
      );
    } else {
      dispatch(
        setLeagueSeasonMetadata({
          isLoading: false,
          isError: true,
          errorMsg: getCommonError(resData?.message),
          leaguesSeason: [],
        }),
      );
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };

  // const handleMarkActive = async () => {
  //   setIsMarkingSeasonActive(true);
  //   const resData: any = await populateLeagueAndSeason({});
  //   if (
  //     resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
  //     resData?.status === HttpStatus.RESOURCE_CREATED
  //   ) {
  //     setIsMarkingSeasonActive(false);
  //     // updateLeagueSeason();
  //   } else {
  //     setIsMarkingSeasonActive(false);
  //     if (resData?.status !== CustomStatusCode.sessionExpire)
  // setShowToaster(showFailureToaster(getCommonError(resData?.message)));
  //   }
  // };

  const handlePopulateLeagueAndSeason = async (leagueSeasonId) => {
    setIsPopulatingLeaguesAndSeason(true);
    const resData: any = await populateLeagueAndSeason({
      leagueSeasonId,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setIsPopulatingLeaguesAndSeason(false);
      getLeaguesData();
      setShowToaster(
        showSuccessToaster(
          Leagues.leaguesConfig.successfullyPopulateLeagueSeason,
        ),
      );
    } else {
      setIsPopulatingLeaguesAndSeason(false);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };

  const handleRefreshLeaguesData = () => {
    setError(initialErrorState);
    setLeaguesOption([]);
    setSelectedLeagueOption(null);
    setSeasonsOption([]);
    setSelectedSeasonOption(null);
    refreshLeaguesData();
  };

  const handleClickRefreshPlayerScore = () => {
    if (!selectedLeagueOption)
      setShowToaster(
        showFailureToaster(Leagues.leaguesConfig.selectLeagueToRefreshPlayer),
      );
    else handleRefreshPlayerScore();
  };
  const handleRefreshPlayerScore = async () => {
    setIsRefreshingPlayerScore(true);
    const resData: any = await refreshPlayerScore({
      leagueTypeId: selectedLeagueOption?.value,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setIsRefreshingPlayerScore(false);
      setShowToaster(
        showSuccessToaster(Leagues.leaguesConfig.successfullyRefreshedPlayer),
      );
    } else {
      setIsRefreshingPlayerScore(false);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };

  return (
    <div className="league-season-wrapper">
      <Typography variant="pageTitle">
        {Leagues.leaguesConfig.leaguesConfigText}
      </Typography>
      <Card>
        <CardBody className="manage-user-body">
          <Row className="mb-3 mt-3">
            <Col sm={3}>
              <label className="d-block fw-light fs-13">{""}</label>
            </Col>
            <Col className="mb-3">
              <Button
                variant="primary"
                onClick={() => {
                  !leaguesMetadata?.isLoading && handleRefreshLeaguesData();
                }}
                disabled={leaguesMetadata?.isLoading && !isRefreshingStatus}
              >
                {leaguesMetadata?.isLoading && !isRefreshingStatus
                  ? Action.fetchingLeaguesAndSeasonFromOpta
                  : Action.fetchLeaguesAndSeasonFromOpta}
              </Button>
            </Col>
          </Row>
          <Row className="mb-3 mt-3">
            <Col sm={3}>
              <label className="d-block fw-light fs-13">
                {Leagues.leaguesConfig.leaguesText}
              </label>
            </Col>
            <Col className="mb-3">
              <FormSelect
                options={leaguesOption}
                value={selectedLeagueOption}
                onChange={(e) => {
                  handleLeaguesChange(e);
                }}
                isRequired={true}
                isError={error?.leaguesError?.isError}
                error={error?.leaguesError?.errorMsg}
                isLoading={leaguesMetadata.isLoading && !isRefreshingStatus}
                loadingMessage={() => <Spinner variant="primary" size="sm" />}
                noOptionsMessage={() =>
                  leaguesMetadata?.isError
                    ? leaguesMetadata?.errorMsg
                    : CommonMessage.noData
                }
              />
            </Col>
            <Col></Col>
          </Row>
          <Row className="mb-3 mt-3">
            <Col sm={3}>
              <label className="d-block fw-light fs-13">
                {Leagues.leaguesConfig.seasonsText}
              </label>
            </Col>
            <Col className="mb-3">
              <FormSelect
                options={seasonsOption}
                value={selectedSeasonOption}
                onChange={(e) => {
                  setSelectedSeasonOption(e);
                  setError((prev) => ({
                    ...prev,
                    seasonsError: EMPTY_ERROR,
                  }));
                }}
                isRequired={true}
                isError={error?.seasonsError?.isError}
                error={error?.seasonsError?.errorMsg}
              />
            </Col>
            <Col></Col>
          </Row>
          {/* <Row className="mb-3 mt-3">
            <Col sm={3}>
              <label className="d-block fw-light fs-13">
                {Leagues.leaguesConfig.seasonActive}:
              </label>
            </Col>
            <Col className="mb-3 fw-bold">
              {selectedSeasonOption ? (
                selectedSeasonOption?.isActive ? (
                  "True"
                ) : (
                  "False"
                )
              ) : (
                <span>&nbsp;&nbsp;-</span>
              )}
            </Col>
          </Row> */}
          <Row className="mt-3">
            <Col sm={3}>
              <label className="d-block fw-light fs-13">{""}</label>
            </Col>
            <Col className="mb-3 d-flex align-items-center">
              {/* <Button
                variant="primary"
                disabled={
                  leaguesMetadata?.isLoading ||
                  isMarkingSeasonActive ||
                  selectedSeasonOption?.isActive ||
                  !selectedSeasonOption
                }
                onClick={() =>
                  !leaguesMetadata?.isLoading &&
                  !isMarkingSeasonActive &&
                  !selectedSeasonOption?.isActive &&
                  handleMarkActive()
                }
                style={{ marginRight: "1rem" }}
              >
                {Action.markActive}
              </Button> */}
              <Button
                variant="primary"
                disabled={
                  leaguesMetadata?.isLoading ||
                  isPopulatingLeaguesAndSeason ||
                  selectedSeasonOption?.processing
                }
                onClick={() =>
                  !leaguesMetadata?.isLoading &&
                  !isPopulatingLeaguesAndSeason &&
                  !selectedSeasonOption?.processing &&
                  handleSeasonChange()
                }
              >
                {isPopulatingLeaguesAndSeason
                  ? Action.populating
                  : Action.populate}
              </Button>
              <Button
                variant="primary"
                className="refresh-player-score"
                disabled={isRefreshingPlayerScore}
                onClick={() =>
                  !isRefreshingPlayerScore && handleClickRefreshPlayerScore()
                }
              >
                {isRefreshingPlayerScore
                  ? Action.refreshingPlayerScore
                  : Action.refreshPlayerScore}
              </Button>
              <Button
                variant="primary"
                className="refresh-button"
                disabled={
                  leaguesMetadata?.isLoading ||
                  isPopulatingLeaguesAndSeason ||
                  isRefreshingStatus
                }
                onClick={() =>
                  !leaguesMetadata?.isLoading &&
                  !isPopulatingLeaguesAndSeason &&
                  !isRefreshingStatus &&
                  getLeaguesData()
                }
              >
                {isRefreshingStatus
                  ? Action.refreshingStatus
                  : Action.refreshStatus}
              </Button>
              {selectedSeasonOption?.processing && (
                <Spinner
                  className="processing-spinner"
                  animation="border"
                  variant="primary"
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={3}></Col>
            <Col>
              {selectedSeasonOption?.error && (
                <Typography className="error-text-style" variant="caption">
                  {selectedSeasonOption?.error}
                </Typography>
              )}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm={3}>
              <label className="d-block fw-light fs-13">
                {Leagues.leaguesConfig.lastSyncAt}
              </label>
            </Col>
            <Col>
              <Typography variant="caption">
                {selectedSeasonOption?.lastSyncAt
                  ? formatUTCDateToLocalString(selectedSeasonOption?.lastSyncAt)
                  : "-"}
              </Typography>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ConfirmationModal
        variant="alert"
        show={showConfirmationModal}
        title={() =>
          leaguesSeasonPopulateMessage(
            selectedLeagueOption,
            selectedSeasonOption,
          )
        }
        handleClick={() => {
          setShowConfirmationModal(false);
          handlePopulateLeagueAndSeason(selectedSeasonOption?.id);
        }}
        handleClose={() => setShowConfirmationModal(false)}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={() => setShowConfirmationModal(false)}
        btnText="Ok"
      />
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleClose}
        position="top-center"
        autohide
      />
    </div>
  );
};
