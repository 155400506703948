import { useEffect, useRef, useState } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { Action, ImageUpload } from "../../constants";
import { showFailureToaster } from "../../../utils/apiUtil";
import { getFileSize } from "../../../utils/fileUtil";
import {
  fetchImagePresignedUrl,
  uploadImageToPresignUrl,
} from "../../../service";
import { CustomStatusCode, HttpStatus } from "../../../utils/constants";
import { getCommonError } from "../../message";
import "./style.scss";

type ImageUpdateProps = {
  max_photo_size: number;
  setShowToaster: React.Dispatch<React.SetStateAction<any>>;
  oldImage: string;
  isImageUpload: boolean;
  setIsImageUpload: React.Dispatch<React.SetStateAction<boolean>>;
  imageUrl: string;
  setImageUrl: React.Dispatch<React.SetStateAction<string>>;
  NoImage: string;
  fetchedPresignUrl: string;
  setIsImageUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
  noImageClassName?: string;
  imageClassName?: string;
};

export const ImageUpdate = ({
  max_photo_size,
  setShowToaster,
  oldImage: oldImageUrl,
  isImageUpload,
  setIsImageUpload,
  imageUrl,
  setImageUrl,
  setIsImageUpdated,
  NoImage,
  fetchedPresignUrl,
  noImageClassName = "no-image-classname",
  imageClassName = "image-classname",
}: ImageUpdateProps) => {
  const [oldImage, setOldImage] = useState<any>(oldImageUrl);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState<string>("");
  const [presignUrl, setPresignUrl] = useState<string>("");
  const fileInputRef = useRef(null);
  useEffect(() => {
    setOldImage(oldImageUrl);
  }, [oldImageUrl]);
  const handleThumbnailClick = () => {
    fileInputRef.current.click();
  };
  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > max_photo_size) {
        const photoSizeInMb = getFileSize(max_photo_size);
        setShowToaster(
          showFailureToaster(ImageUpload.message.max_size_error(photoSizeInMb)),
        );
        return;
      }
      setFile(selectedFile);
      setFileName(selectedFile.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setOldImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setShowToaster(
        showFailureToaster(ImageUpload.message.noImageSelectError),
      );
      return;
    }
    try {
      const resData = await uploadImageToPresignUrl(presignUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
      if (resData.status === HttpStatus.REQUEST_SUCCEEDED) {
        setImageUrl(presignUrl);
        setIsImageUpload(false);
        setIsImageUpdated && setIsImageUpdated(true);
      } else {
        setIsImageUpload(false);
        setOldImage(imageUrl);
        if (resData?.status !== CustomStatusCode.sessionExpire)
          setShowToaster(showFailureToaster(getCommonError(resData?.message)));
      }
    } catch (error) {
      setIsImageUpload(false);
      setOldImage(imageUrl);
      setShowToaster(showFailureToaster(ImageUpload.message.uploadImageError));
    }
  };

  const getPresignedUrl = async () => {
    setIsImageUpload(true);
    const fileUrl = fetchedPresignUrl.replace("{fileName}", fileName);
    const resData: any = await fetchImagePresignedUrl(fileUrl);
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      if (resData?.data?.data) {
        setPresignUrl(resData?.data?.data?.presignUrl);
      }
    } else {
      setIsImageUpload(false);
      setOldImage(imageUrl);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };

  useEffect(() => {
    if (presignUrl) {
      handleUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presignUrl]);

  useEffect(() => {
    if (fileName) {
      getPresignedUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName]);

  return (
    <>
      <div className="profile-image-wrapper">
        {oldImage ? (
          <Image
            src={oldImage}
            className={`${imageClassName}`}
            alt="player"
            thumbnail
          />
        ) : (
          <Image
            src={NoImage}
            className={`${noImageClassName}`}
            alt="player"
            onClick={handleThumbnailClick}
            thumbnail
          />
        )}
        <Button
          variant="link"
          className="profile-image"
          onClick={handleThumbnailClick}
          disabled={isImageUpload}
        >
          {oldImage
            ? isImageUpload
              ? Action.updating
              : Action.update
            : isImageUpload
            ? Action.uploading
            : Action.upload}
        </Button>
      </div>
      <Form.Group style={{ display: "none" }}>
        <Form.Label>{Action.uploadImage}</Form.Label>
        <Form.Control
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={handleImageChange}
        />
      </Form.Group>
    </>
  );
};
