import { Referrals } from "../../../common/constants";

export const isAddingReferralsButtonDisabled = (
  referrals,
  description,
  message,
  isPubCampaignSelected,
  isDuplicate,
) => {
  const trimmedDescription = description?.trim();
  const trimmedMessage = message?.trim();

  if (isDuplicate) return true;
  if (!referrals || !trimmedDescription) return true;
  if (isPubCampaignSelected && !trimmedMessage) return true;
  if (
    referrals?.length < Referrals.referralCodeMinLength ||
    referrals?.length > Referrals.refefrralCodeMaxLength
  )
    return true;
  if (trimmedMessage?.length > Referrals.messageMaxLength) return true;
  else return false;
};
