import React, { useEffect, useState } from "react";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import { fetchIdVerificationStats } from "../../service";
import { HttpStatus } from "../../../utils/constants";
import { EMPTY_ERROR, Stats } from "../../../common/constants";
import { ColumnDef } from "@tanstack/react-table";

const statDescription = {
  Verified: "Total approved users",
  Rejected: "Total rejected users",
  ReviewByAdmin: "Total users under admin review",
};
const IdVerificationComponent = ({ startDate, endDate }) => {
  const [idVerificationStats, setIdVerificationStats] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  useEffect(() => {
    if (startDate && endDate) {
      getIdVerificationStats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const getIdVerificationStats = async () => {
    setIdVerificationStats({
      ...idVerificationStats,
      isLoading: true,
      error: EMPTY_ERROR,
    });

    const resData: any = await fetchIdVerificationStats({ startDate, endDate });

    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      const stats = resData?.data?.data.userStatuses
        .filter((statusObject) => statDescription[statusObject.status])
        .map((statusObject) => {
          return {
            description: statDescription[statusObject.status],
            value: statusObject.statusCount,
          };
        });

      setIdVerificationStats((pre) => ({
        ...pre,
        isLoading: false,
        data: {
          stats,
        },
      }));
    } else {
      setIdVerificationStats({
        ...idVerificationStats,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      });
    }
  };

  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: "description",
        id: "description",
        header: () => <span>{Stats.description}</span>,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "value",
        id: "value",
        header: () => <span>{Stats.value}</span>,
        cell: (info) => info.getValue(),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <>
      <TableWrapper>
        <ReactTable
          isLoading={idVerificationStats?.isLoading}
          data={idVerificationStats?.data?.stats || []}
          columns={columns}
          hasError={idVerificationStats?.error.isError}
          errorMessage={idVerificationStats?.error.errorMsg}
        />
      </TableWrapper>
    </>
  );
};

export default IdVerificationComponent;
