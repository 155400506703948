import { API_METHOD } from "../../../api/api";
import { getBase } from "../../../apiconfig";
import { URLS } from "../../../common/url";
import { handleApiRequest } from "../../../utils/apiUtil";

// To save the kick off match details when adding mini league 1st time
export const saveKickOffMatch = async (payload = {}) => {
  const url: string = getBase() + "/api" + URLS.MINI_LEAGUE;
  return await handleApiRequest(API_METHOD.POST, url, payload);
};

// To save the kick off match details when updating already existed mini league
export const updateKickOffMatch = async (payload = {}, config: any = {}) => {
  const { miniLeagueId, ...restConfig } = config;
  const url: string =
    getBase() + "/api" + URLS.MINI_LEAGUE + `/${miniLeagueId}`;
  return await handleApiRequest(API_METHOD.PUT, url, payload, restConfig);
};

// To publish the mini league
export const publishMiniLeague = async (payload: any = {}) => {
  const { miniLeagueId } = payload;
  const url: string =
    getBase() + "/api" + URLS.MINI_LEAGUE + `/${miniLeagueId}/publish`;
  return await handleApiRequest(API_METHOD.POST, url);
};

// To UnPublish the mini league
export const unPublishMiniLeague = async (payload: any = {}) => {
  const { miniLeagueId } = payload;
  const url: string =
    getBase() + "/api" + URLS.MINI_LEAGUE + `/${miniLeagueId}/unpublish`;
  return await handleApiRequest(API_METHOD.POST, url);
};

// To fetch the mini league list
export const getMiniLeagueList = async (payload = {}) => {
  const url: string = getBase() + "/api" + URLS.MINI_LEAGUE + `/list`;
  return await handleApiRequest(API_METHOD.POST, url, payload);
};

// To fetch the mini league by id
export const getMiniLeagueById = async (payload: any = {}) => {
  const { miniLeagueId } = payload;
  const url: string =
    getBase() + "/api" + URLS.MINI_LEAGUE + `/${miniLeagueId}`;
  return await handleApiRequest(API_METHOD.GET, url);
};

// To disburse the prize
export const disburseMiniLeaguePrize = async (payload: any = {}) => {
  const { miniLeagueId } = payload;
  const url: string =
    getBase() + "/api" + URLS.MINI_LEAGUE + `/${miniLeagueId}/disbursePrize`;
  return await handleApiRequest(API_METHOD.POST, url);
};
