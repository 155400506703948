import React, { useEffect, useState } from "react";
import CohortGraph from "react-cohort-graph";
import { EMPTY_ERROR } from "../../../common/constants";
import { fetchRetentionViewData } from "../../service";
import { HttpStatus } from "../../../utils/constants";

enum COHORT_GRAPH_VIEW_TYPE_CONSTANT {
  PERCENTAGE = "percent",
  ABSOLUTE_VALUE = "value",
}

export default function RetentionView({
  selectedLeagueSeason,
  isPercentageChange,
}) {
  const [retentionData, setRetentionData] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });

  // following function calls the function that makes the API call and helps re render the CohortGraph on the selectedLeagueSeason change on parent component
  useEffect(() => {
    getRetentionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLeagueSeason]);

  // following function helps re render the CohortGraph on the retentionViewTypeFlag change on parent component
  useEffect(() => {
    setRetentionData({
      ...retentionData,
      isLoading: true,
      error: EMPTY_ERROR,
    });
    setTimeout(() => {
      setRetentionData({
        ...retentionData,
        isLoading: false,
        error: EMPTY_ERROR,
      });
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPercentageChange]);

  const getRetentionData = async () => {
    setRetentionData({
      ...retentionData,
      isLoading: true,
      error: EMPTY_ERROR,
    });
    const resData: any = await fetchRetentionViewData({
      leagueSeasonId: selectedLeagueSeason?.id,
    });

    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      const response = resData?.data?.data;
      const weekData = response?.weeks;
      let totalWeeks = response?.totalWeeks;
      const transformedData = {
        Weeks: {},
      };

      Object.keys(weekData).forEach((gameWeek) => {
        const retentionWeeks = new Array(totalWeeks--).fill(0);
        Object.keys(weekData[gameWeek].weeks).forEach((week) => {
          retentionWeeks[parseInt(week) - parseInt(gameWeek)] =
            weekData[gameWeek].weeks[week];
        });

        transformedData.Weeks[gameWeek] = retentionWeeks;
      });
      setRetentionData((pre) => ({
        ...pre,
        isLoading: false,
        data: {
          Weeks: transformedData?.Weeks,
        },
      }));
    } else {
      setRetentionData({
        ...retentionData,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      });
    }
  };

  return (
    <div>
      {!retentionData?.isLoading && (
        <CohortGraph
          data={{
            weeks: { ...retentionData?.data?.Weeks },
          }}
          defaultValueType={
            isPercentageChange
              ? COHORT_GRAPH_VIEW_TYPE_CONSTANT.PERCENTAGE
              : COHORT_GRAPH_VIEW_TYPE_CONSTANT.ABSOLUTE_VALUE
          }
        />
      )}
    </div>
  );
}
