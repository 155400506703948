import Milestone from "./milestone";
import Tasks from "./tasks";
import "./style.css";

const MilestoneAndTasks = () => {
  return (
    <div className="tasks-wrapper">
      <div className="milestone">
        <Milestone />
      </div>
      <div className="tasks">
        <Tasks />
      </div>
    </div>
  );
};

export default MilestoneAndTasks;
