import { Match } from "../../../../common/constants";
import "./style.scss";
import Select, { components } from "react-select";
import {
  getAppendedDateAndTime,
  getTrueOrFalseValue,
} from "../../../../utils/matchUtil";

const Control = ({ children, ...props }: any) => {
  const selectedVal = props?.getValue()?.[0]?.value;
  return (
    <components.Control {...props}>
      {selectedVal && (
        <div
          style={{
            width: "18px",
            height: "18px",
            border: "1px solid",
            background: selectedVal,
            marginLeft: "5px",
          }}
        ></div>
      )}
      {children}
    </components.Control>
  );
};

const CustomOption = (props) => {
  return (
    <components.Option {...props} className="option-style">
      {
        <span
          className="d-inline-block"
          style={{
            marginRight: "5px",
            width: "20px",
            height: "20px",
            background: props.data.value,
            border: "1px solid",
          }}
        ></span>
      }
      {props.data.label}
    </components.Option>
  );
};

const TeamConfigInfo = ({
  match,
  matchMetaData,
  team1Color,
  team2Color,
  setTeam1Color,
  setTeam2Color,
  team1ColorOptions,
  team2ColorOptions,
  isReadOnly,
}) => {
  return (
    <div className="config-info-read-content">
      <div className="mt-2">
        <label className="d-block fw-light fs-12">
          {Match.configure.team1name}
        </label>
        <span className="d-block  fw-medium text-capitalize mb-3">
          {match?.teamId1?.name}
        </span>
      </div>
      <div className="mt-2 team1-color-wrapper">
        <label className="d-block fw-light fs-12 mb-1">
          {Match.configure.team1Color}
          <span className="text-danger">*</span>
        </label>
        <span className="d-block  fw-medium text-capitalize mb-3">
          <Select
            classNames={{
              control: () => "rounded-0 bg-white form-select-control",
            }}
            options={team1ColorOptions}
            components={{ Option: CustomOption, Control: Control }}
            onChange={(e) => {
              !isReadOnly && setTeam1Color(e);
            }}
            value={team1Color}
            isDisabled={isReadOnly}
          />
        </span>
      </div>
      <div className="mt-2">
        <label className="d-block fw-light fs-12">
          {Match.configure.team2Name}
        </label>
        <span className="d-block  fw-medium text-capitalize mb-3">
          {match?.teamId2?.name}
        </span>
      </div>
      <div className="mt-2 team2-color-wrapper">
        <label className="d-block fw-light fs-12">
          {Match.configure.team2Color}
          <span className="text-danger">*</span>
        </label>
        <span className="d-block  fw-medium text-capitalize mb-3">
          <Select
            classNames={{
              control: () => "rounded-0 bg-white form-select-control",
            }}
            options={team2ColorOptions}
            components={{ Option: CustomOption, Control: Control }}
            onChange={(e) => {
              !isReadOnly && setTeam2Color(e);
            }}
            value={team2Color}
            isDisabled={isReadOnly}
          />
        </span>
      </div>
      <div className="mt-2">
        <label className="d-block fw-light fs-12">
          {Match.configure.startDate}
        </label>
        <span className="d-block  fw-medium text-capitalize mb-3">
          {getAppendedDateAndTime(match?.localStartDate, match?.localStartTime)}
        </span>
      </div>
      <div className="mt-2">
        <label className="d-block fw-light fs-12">
          {Match.configure.specialMatch}
        </label>
        <span className="d-block  fw-medium text-capitalize mb-3">
          {getTrueOrFalseValue(match?.featured)}
        </span>
      </div>
    </div>
  );
};

export default TeamConfigInfo;
