import { useState } from "react";
import { HttpStatus } from "../../../../../utils/constants";
import {
  compareDay,
  createDayPrize,
  deleteDayPrize,
  updateDayPrize,
} from "../../service";
import {
  DayRewardType,
  DayTaskTypeEnum,
  initialDayReward,
  initialRewardConfig,
  ITriggerToaster,
  RewardConfigType,
} from "../../types";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select, { GroupBase } from "react-select";
import { Tasks } from "../../../../constants";
import { Option, taskTypeOptions } from "./options";
import RewardConfig from "./RewardConfig";
import "./index.scss";
import ConfirmationModal from "../../../Modal/ConfirmationModel";

interface DayRowProps {
  dayNumber?: number;
  isNew: boolean;
  dayReward?: DayRewardType;
  streakMap: Record<number, DayRewardType>;
  triggerToast: (payload: ITriggerToaster) => void;
  closeNewDayRow?: () => void;
  refresh: () => void;
}

const DayRewardsRow: React.FC<DayRowProps> = ({
  dayNumber,
  isNew,
  dayReward,
  streakMap,
  triggerToast,
  closeNewDayRow,
  refresh,
}) => {
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [dayRewardData, setDayRewardData] = useState<DayRewardType>(
    dayReward ?? initialDayReward,
  );

  const updateDayNumber = (day: number | "") => {
    if (day === "") {
      setDayRewardData((p) => ({
        ...p,
        day: undefined,
      }));
      return;
    }

    setDayRewardData((p) => ({
      ...p,
      day: day <= 366 ? day : p.day,
    }));
  };

  const handleDayNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;

    if (input === "") {
      updateDayNumber("");
      return;
    }

    const numValue = Number(input);
    if (!Number.isNaN(numValue)) {
      updateDayNumber(numValue);
    }
  };

  const updateRewardConfigAtIndex = (
    index: number,
    updates: RewardConfigType,
  ) => {
    setDayRewardData((prevState) => {
      const updatedRewardConfig = [...prevState.rewardConfig];

      const currentConfig = updatedRewardConfig[index];

      if (!currentConfig) {
        throw new Error("Invalid index for rewardConfig update");
      }

      // Update only the provided fields
      updatedRewardConfig[index] = {
        ...currentConfig,
        ...updates,
      };

      return {
        ...prevState,
        rewardConfig: updatedRewardConfig,
      };
    });
  };

  const deleteRewardConfigAtIndex = (index: number) => {
    setDayRewardData((prevState) => {
      try {
        const updatedRewardConfig = [...prevState.rewardConfig];

        // Ensure there's more than one object in the array before attempting to delete
        if (updatedRewardConfig.length <= 1) {
          throw new Error("At least one reward is required");
        }

        // Check if the index is valid
        if (index < 0 || index >= updatedRewardConfig.length) {
          throw new Error("Invalid index for rewardConfig delete");
        }

        // Remove the item at the specified index
        updatedRewardConfig.splice(index, 1);

        return {
          ...prevState,
          rewardConfig: updatedRewardConfig,
        };
      } catch (error) {
        console.error(error);
        triggerToast({
          message: error.message,
          variant: "warning",
        });
        return prevState;
      }
    });
  };

  const addNewReward = () => {
    // add default/empty reward config
    setDayRewardData((p) => {
      const updatedConfig = [...p.rewardConfig, initialRewardConfig];
      return {
        ...p,
        rewardConfig: updatedConfig,
      };
    });
  };

  const handleUpdateDayReward = async () => {
    try {
      const res = await updateDayPrize(dayRewardData);

      if (
        res?.status === HttpStatus.REQUEST_SUCCEEDED ||
        res?.status === HttpStatus.RESOURCE_CREATED
      ) {
        refresh();
        triggerToast({
          message: "Day updated",
          variant: "success",
        });
      } else {
        throw new Error(res?.message ?? "Something went wrong");
      }
    } catch (error) {
      console.error(error);
      triggerToast({
        message: error.message,
        variant: "danger",
      });
    }
  };

  const handleCreateDayReward = async () => {
    try {
      const res = await createDayPrize(dayRewardData);

      if (
        res?.status === HttpStatus.REQUEST_SUCCEEDED ||
        res?.status === HttpStatus.RESOURCE_CREATED
      ) {
        refresh();
        closeNewDayRow?.();
        triggerToast({
          message: "Day created",
          variant: "success",
        });
      } else {
        throw new Error(res?.message ?? "Something went wrong");
      }
    } catch (error) {
      console.error(error);
      triggerToast({
        message: error.message,
        variant: "danger",
      });
    }
  };

  const handleDeleteDayReward = async () => {
    try {
      const res = await deleteDayPrize({
        id: dayRewardData?.id,
      });

      if (
        res?.status === HttpStatus.REQUEST_SUCCEEDED ||
        res?.status === HttpStatus.RESOURCE_CREATED
      ) {
        refresh();
        updateDayNumber("");
        triggerToast({
          message: "Day deleted",
          variant: "success",
        });
      } else {
        throw new Error(res?.message ?? "Something went wrong");
      }
    } catch (error) {
      console.error(error);
      triggerToast({
        message: error.message,
        variant: "danger",
      });
    }
  };

  const disableFields = !isNew && !!streakMap[dayNumber];

  return (
    <>
      <div
        style={{
          backgroundColor: isNew ? "rgba(60, 255, 0, 0.23)" : "transparent",
        }}
        className="py-4 position-relative"
      >
        <Row>
          {isNew && (
            <span className="new-day-label">
              Add reward details for new day
            </span>
          )}

          <Col lg={1}>
            <Form.Control
              type="text"
              value={dayRewardData?.day ?? ""}
              placeholder="Day"
              className="button-height"
              disabled={disableFields}
              onChange={handleDayNumberChange}
            />
          </Col>
          <Col lg={2}>
            <Select<
              Option<DayTaskTypeEnum>,
              false,
              GroupBase<Option<DayTaskTypeEnum>>
            >
              placeholder="Task Type"
              menuPlacement="auto"
              options={taskTypeOptions}
              value={taskTypeOptions.find(
                (opt) => opt.value === dayRewardData?.taskType,
              )}
              onChange={(selected) => {
                setDayRewardData((p) => ({
                  ...p,
                  taskType: selected?.value,
                }));
              }}
              styles={{
                control: (p) => ({
                  ...p,
                  ...(disableFields && { pointerEvents: "none" }),
                  ...(disableFields && { backgroundColor: "#f3efee" }),
                }),
              }}
            />
          </Col>

          <Col lg={1}></Col>

          <Col lg={6} className="d-flex flex-column gap-4">
            {dayRewardData?.rewardConfig?.map((rc, idx) => (
              <RewardConfig
                key={idx}
                idx={idx}
                rc={rc}
                rcLength={dayRewardData.rewardConfig.length}
                deleteReward={deleteRewardConfigAtIndex}
                updateReward={updateRewardConfigAtIndex}
              />
            ))}
          </Col>

          <Col lg={2}>
            <Button onClick={addNewReward}>Add Reward</Button>
          </Col>
        </Row>

        <div className="d-flex gap-2 buttons-wrapper">
          {isNew && (
            <>
              <Button onClick={handleCreateDayReward}>{Tasks.create}</Button>
              <Button onClick={closeNewDayRow}>{Tasks.cancel}</Button>
            </>
          )}

          {!isNew && (
            <>
              {streakMap[dayNumber] ? (
                <>
                  <Button
                    disabled={compareDay(
                      dayRewardData,
                      streakMap[dayRewardData?.day],
                    )}
                    onClick={handleUpdateDayReward}
                  >
                    {Tasks.save}
                  </Button>
                  <Button onClick={() => setShowConfirmationModal(true)}>
                    {Tasks.delete}
                  </Button>
                </>
              ) : (
                <Button onClick={handleCreateDayReward}>{Tasks.create}</Button>
              )}
            </>
          )}
        </div>
      </div>
      <div className="line" />
      <ConfirmationModal
        variant="alert"
        show={showConfirmationModal}
        title={`Are you sure, you want to delete the day?`}
        handleClick={() => {
          handleDeleteDayReward();
        }}
        handleClose={() => setShowConfirmationModal(false)}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={() => setShowConfirmationModal(false)}
        btnText="Delete"
      />
    </>
  );
};

export default DayRewardsRow;
