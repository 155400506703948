import { useEffect, useRef, useState } from "react";
import { Form, Overlay, Tooltip } from "react-bootstrap";
import Select, { OptionProps, components } from "react-select";
import classNames from "classnames";
import FormCheckbox from "../FormCheckbox/FormCheckbox";

interface IFormSelect {
  options: Array<any>;
  isMulti?: boolean;
  label?: string;
  helperText?: string;
  isRequired?: boolean;
  onChange?: any;
  components?: any;
  value?: any;
  defaultValue?: any;
  name?: string;
  className?: string;
  error?: string;
  placeholder?: string;
  closeMenuOnSelect?: boolean;
  labelItalic?: boolean;
  isError?: boolean;
  [key: string]: any;
  isTooltip?: boolean;
  tooltipId?: string;
  tooltipContent?: string;
}

const InputOption = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}: OptionProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isActive, setIsActive] = useState(false);
  // const onMouseDown = () => setIsActive(true);
  // const onMouseUp = () => setIsActive(false);
  // const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#F8FAFE";
  if (isSelected) bg = "#E5E5F2";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
    cursor: "pointer",
  };

  // prop assignment
  const props = {
    ...innerProps,
    // onMouseDown,
    // onMouseUp,
    // onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <FormCheckbox className="me-2" checked={isSelected} />
      {children}
    </components.Option>
  );
};

const FormSelect = ({
  options,
  isMulti,
  onChange,
  components,
  label,
  isRequired,
  helperText,
  value,
  defaultValue,
  name,
  className,
  closeMenuOnSelect,
  placeholder,
  labelItalic,
  isError,
  error,
  selectClassName,
  isTooltip = false,
  tooltipId,
  tooltipContent,
  ...props
}: IFormSelect) => {
  const [show, setShow] = useState(false);
  const target = useRef<HTMLSpanElement>(null);
  const [tooltipPlacement, setTooltipPlacement] = useState<any>("right");
  const handleResize = () => {
    const newPlacement = window.innerWidth < 768 ? "top" : "right";
    setTooltipPlacement(newPlacement);
  };
  const handleDocumentClick = (event: MouseEvent) => {
    if (target.current && !target.current.contains(event.target as Node)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      document.removeEventListener("click", handleDocumentClick);
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Form.Group className={classNames("form-select", className)}>
      {label && (
        <Form.Label
          className={classNames("fs-12 fw-medium", {
            "fst-italic": labelItalic,
          })}
        >
          {label}
          {isRequired && <span className="text-danger">*</span>}
          {isTooltip && (
            <>
              <span
                ref={target}
                onClick={() => setShow(!show)}
                className="tooltip-icon"
              >
                i
              </span>
              <Overlay
                target={target.current}
                show={show}
                placement={tooltipPlacement}
              >
                {(props) => (
                  <Tooltip id={tooltipId} {...props}>
                    {tooltipContent}
                  </Tooltip>
                )}
              </Overlay>
            </>
          )}
        </Form.Label>
      )}

      <Select
        classNames={{
          control: () =>
            isError
              ? "border-danger rounded-0 bg-white form-select-control"
              : "rounded-0 bg-white form-select-control custom-index",
          menuList: () => "p-2",
          input: () => "cursor-text",
          indicatorsContainer: () => "cursor-pointer",
          option: () => "cursor-pointer",
          container: () => (isError ? "is-invalid" : ""),
          placeholder: () => "form-select-placeholder",
        }}
        options={options}
        isMulti={isMulti}
        onChange={onChange}
        components={isMulti ? { Option: InputOption } : components}
        value={value}
        defaultValue={defaultValue}
        name={name}
        placeholder={placeholder}
        closeMenuOnSelect={closeMenuOnSelect}
        required={isRequired}
        {...props}
      />
      {helperText && !isError && (
        <div className="helper-text-style">{helperText}</div>
      )}
      {isError && (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default FormSelect;
