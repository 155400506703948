import React, { HTMLProps } from "react";
export function IndeterminateSelection({
  indeterminate,
  type = "checkbox",
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <>
      <input
        type={type}
        ref={ref}
        className={className + " form-check-input cursor-pointer"}
        {...rest}
      />
    </>
  );
}
