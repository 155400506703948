import {
  DateRangeEnum,
  DateRangeEnumLabel,
  DaysToGetEnum,
} from "../common/constants";

export const staticDateOption = [
  {
    key: DateRangeEnum.last7Days,
    label: DateRangeEnumLabel[DateRangeEnum.lastWeek],
    daysToGet: DaysToGetEnum.prev,
    daysNumber: 7,
  },
  {
    key: DateRangeEnum.lastMonth,
    label: DateRangeEnumLabel[DateRangeEnum.lastMonth],
    daysToGet: DaysToGetEnum.prev,
    daysNumber: 30,
  },
  {
    key: DateRangeEnum.thisMonth,
    label: DateRangeEnumLabel[DateRangeEnum.thisMonth],
    daysToGet: DaysToGetEnum.prev,
    daysNumber: new Date().getDate() - 1,
  },
];

export const initialDateRange = [null, null];
export const initialStaticDateRange = {
  [DateRangeEnum.last7Days]: false,
  [DateRangeEnum.lastMonth]: true,
  [DateRangeEnum.thisMonth]: false,
};

export const resetStaticDateRange = {
  ...initialStaticDateRange,
  [DateRangeEnum.lastMonth]: false,
};
