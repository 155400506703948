import React from "react";
import TableWrapper from "../../../../../../../common/components/ReactTable/TableWrapper";
import ReactTable from "../../../../../../../common/components/ReactTable/ReactTable";
import { MiniLeagues } from "../../../../../../../common/constants";
import { IndeterminateSelection } from "../../../../../../../common/components/IndeterminateSelection";
import "./style.scss";

export const FeaturedMatchList = ({
  featuredMatch,
  featuredMatchPagesize,
  setFeaturedMatchPagesize,
  featuredMatchCurrentPage,
  setFeaturedMatchCurrentPage,
  kickOffMatch,
  setKickOffMatch,
  setAddMiniLeagueInfoError,
}) => {
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "teamId1.name",
        id: "team1Id",
        header: () => <span>{MiniLeagues.featured.team1}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "teamId2.name",
        id: "team2Id",
        header: () => <span>{MiniLeagues.featured.team2}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "id",
        id: "matchId",
        header: () => <span>{MiniLeagues.featured.matchId}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "localStartDate",
        id: "localStartDate",
        header: () => <span>{MiniLeagues.featured.startDate}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "localStartTime",
        id: "localStartTime",
        header: () => <span>{MiniLeagues.featured.startTime}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "action",
        id: "action",
        header: () => <span>{MiniLeagues.featured.action}</span>,
        cell: ({ row }) => {
          return (
            <div className="px-1">
              <IndeterminateSelection
                {...{
                  type: "radio",
                  checked: kickOffMatch?.id === row?.original?.id,
                  onChange: () => {
                    setKickOffMatch(row?.original);
                    setAddMiniLeagueInfoError((prev) => ({
                      ...prev,
                      kickOffMatch: { error: false, errorMsg: "" },
                    }));
                  },
                  disabled: false,
                }}
              />
            </div>
          );
        },
        canSort: false,
      },
    ],
    [kickOffMatch, setKickOffMatch, setAddMiniLeagueInfoError],
  );

  return (
    <>
      <TableWrapper tableClassName="kickOff-match-table-wrapper">
        <ReactTable
          isLoading={featuredMatch?.isLoading}
          data={featuredMatch?.data?.matches || []}
          hasError={featuredMatch?.error?.isError}
          errorMessage={featuredMatch?.error?.errorMsg}
          totalRows={featuredMatch?.data?.totalCount} //total records
          columns={columns}
          pageChangeHandler={setFeaturedMatchCurrentPage}
          pageSizeHandler={setFeaturedMatchPagesize}
          rowsPerPage={featuredMatchPagesize} //no of records per page
          currentPageNumber={featuredMatchCurrentPage}
        />
      </TableWrapper>
    </>
  );
};
