import { DaysToGetEnum, DAYTIME_PHASE } from "../../../common/constants";
import { getUnpublishedPlayer } from "./Transfer/transferPlayerUtil";

export const getFutyStar = (players) => {
  const futyStar = [];
  players?.forEach((player) => {
    if (player?.isFutyStar) futyStar.push(player);
  });
  return futyStar;
};

export const isSaveButtonDisabled = (
  futyStar,
  prizeList,
  localStartTime,
  playerList,
) => {
  if (!prizeList?.length)
    return { disable: true, message: "Prize list must have atleast 1 entry" };
  else if (futyStar?.length !== 4) {
    return { disable: true, message: "4 Futy star must be selected" };
  } else if (!localStartTime) {
    return {
      disable: true,
      message: "Local start time is not available for match",
    };
  }
  const { message } = getUnpublishedPlayer(playerList);
  if (message) {
    return { disable: true, message: message };
  }
  return { disable: false, message: "" };
};

export const checkIsMatchTBD = (team1, team2) => {
  if (team1?.defaultTeam || team2?.defaultTeam) return true;
  else return false;
};

export const isPublishButtonDisable = (
  matchType,
  futyStar,
  prizeList,
  playerList,
) => {
  if (matchType?.published === true) return { disable: false, message: "" };
  else if (matchType?.published === false) {
    if (!prizeList?.length)
      return { disable: true, message: "Prize list must have atleast 1 entry" };
    else if (futyStar?.length !== 4)
      return { disable: true, message: "4 Futy star must be selected" };
    else if (!matchType?.localStartTime)
      return {
        disable: true,
        message: "Local start time is not available for match",
      };
    const { message } = getUnpublishedPlayer(playerList);
    if (message) {
      return { disable: true, message: message };
    }
    return { disable: false, message: "" };
  } else return { disable: false, message: "" };
};

export const getLocalDisplayedDateRange = (daysToGet, daysNumber) => {
  if (daysToGet === DaysToGetEnum.prev) {
    // Calculate the date for the last date from today
    const currentDate = new Date();
    const numberOfDays = daysNumber || 0;
    const lastDate = new Date(currentDate);
    lastDate.setDate(currentDate.getDate() - numberOfDays);
    return [lastDate, currentDate];
  } else if (daysToGet === DaysToGetEnum.next) {
    // Calculate the date for the next date from today
    const currentDate = new Date();
    const numberOfDays = daysNumber || 0;
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + numberOfDays);
    return [currentDate, nextDate];
  } else {
    return [null, null];
  }
};

export const getDisplayedDateFromUTC = (utcDate, seprator = "-") => {
  // return date in yyyy-mm-dd format
  if (!utcDate) return null;
  try {
    const originalDate = new Date(utcDate);
    if (isNaN(originalDate.getTime())) {
      throw new Error("Invalid date string");
    } else {
      let date: any = originalDate.getDate();
      date = date < 10 ? `0${date}` : date;
      let month: any = originalDate.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      const year = originalDate.getFullYear();
      return `${year}${seprator}${month}${seprator}${date}`;
    }
  } catch (error) {
    throw new Error("Invalid date string");
  }
};

export const getTimeStampedDate = (
  utcDate: Date,
  timePhase: DAYTIME_PHASE,
): string | null => {
  if (!utcDate) return null;
  try {
    const originalDate = new Date(utcDate);
    if (isNaN(originalDate.getTime())) {
      throw new Error("Invalid date string");
    }
    switch (timePhase) {
      case DAYTIME_PHASE.START:
        // Set the time to the start of the day (midnight) in local timezone
        originalDate.setHours(0, 0, 0, 0);
        return originalDate.toISOString();

      case DAYTIME_PHASE.END:
        // Set the time to the end of the day (23:59:59.999) in local timezone
        originalDate.setHours(23, 59, 59, 999);
        return originalDate.toISOString();

      default:
        return getDisplayedDateFromUTC(utcDate);
    }
  } catch (error) {
    throw new Error("Invalid date string");
  }
};

export const isPrizeConfigValid = (prizeData) => {
  if (!prizeData || prizeData?.length === 0)
    return {
      isValid: false,
      message: `Prize config is not valid`,
    };

  const dataLength = prizeData.length;
  for (let i = 0; i < dataLength - 1; i++) {
    // Return false if next element from is not equal to current element (to + 1)
    if (prizeData[i + 1].from !== prizeData[i].to + 1)
      return {
        isValid: false,
        message: `Prize config for row having amount ${prizeData[i].coins}, ${
          prizeData[i + 1].coins
        } is not valid as prize range is missing in between`,
      };
    // Return false if current element from is greater than current element to
    if (prizeData[i].from > prizeData[i].to) {
      return {
        isValid: false,
        message: `Prize config for row having amount ${prizeData[i].coins} is not valid as From can't be greater than To`,
      };
    }
  }

  // return true if last element's to is greater than or equal to last element's from
  if (prizeData[dataLength - 1].from <= prizeData[dataLength - 1].to)
    return { isValid: true, message: `` };
  else
    return {
      isValid: false,
      message: `Prize config for row having amount ${
        prizeData[dataLength - 1].coins
      } is not valid as From can't be greater than To`,
    };
};

export const getColorList = (...colors) => {
  return colors.filter((color) => (color ? true : false));
};
