export const ErrorMessage = {
  somethingWrong: "Something went wrong, please try again",
};

export const getCommonError = (errorMessage) => {
  return errorMessage || ErrorMessage.somethingWrong;
};

export const CommonMessage = {
  noData: "No data",
};
