import DocViewer from "@cyntler/react-doc-viewer";
import { DEFAULT_FILE_TYPE, UserIdentity } from "../../../common/constants";
import { useEffect, useState } from "react";
import { getDocumentByDocsId } from "../../service";
import { Button, Spinner } from "react-bootstrap";
import { ArrowWithStroke } from "../../../common/SVG";
import { HttpStatus } from "../../../utils/constants";
import BasicError from "../../../common/components/Error/BasicError";
import { getCommonError } from "../../../common/message";
export const UserDocumentsView = ({ documentImg }) => {
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const getImage = async (doc) => {
    setIsLoading(true);
    setError("");
    const resData = await getDocumentByDocsId({
      documentId: doc.onfidoId,
      type: doc.type,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      try {
        const type = resData?.headers["mime-type"];
        const byteCharacters = atob(resData.data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray]);
        const file = new File([blob], `document-${doc.onfidoId}`);
        setDocuments([
          {
            uri: URL.createObjectURL(file),
            fileType: type || DEFAULT_FILE_TYPE,
          },
        ]);
      } catch (error) {
        setError(UserIdentity.errorDisplayingDocument);
        setIsLoading(false);
      }
    } else {
      setError(getCommonError(resData?.message));
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (documentImg?.length) {
      getImage(documentImg[0]);
    }
  }, [documentImg]);
  const handleNext = () => {
    const nextIndex = currentIndex + 1;
    if (nextIndex < documentImg.length) {
      setCurrentIndex(nextIndex);
      getImage(documentImg[nextIndex]);
    }
  };
  const handlePrevious = () => {
    const prevIndex = currentIndex - 1;
    if (prevIndex >= 0) {
      setCurrentIndex(prevIndex);
      getImage(documentImg[prevIndex]);
    }
  };
  return documentImg?.length ? (
    <div className="d-flex doc-view-container-wrapper">
      <div className="doc-header-wrapper">
        <div>{documentImg[currentIndex].onfidoId}</div>
        <div>
          <span>
            {UserIdentity.documentNumberInfo(
              currentIndex + 1,
              documentImg.length,
            )}
          </span>
          {documentImg?.length > 1 && (
            <span className="no-wrap">
              <Button
                className="left-arrow-button"
                onClick={handlePrevious}
                disabled={currentIndex === 0}
                variant="dark"
              >
                <ArrowWithStroke />
              </Button>
              <Button
                className="right-arrow-button"
                onClick={handleNext}
                disabled={currentIndex === documentImg.length - 1}
                variant="dark"
              >
                <ArrowWithStroke rightArrow />
              </Button>
            </span>
          )}
        </div>
      </div>
      {isLoading ? (
        <div className="react-table-loader border-styling">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : error ? (
        <div className="react-table-loader border-styling">
          {<BasicError error={error}></BasicError>}
        </div>
      ) : (
        <DocViewer documents={documents} className="doc-view-container" />
      )}
    </div>
  ) : (
    <div className="no-docs-container">{UserIdentity.noDocumentAvailable}</div>
  );
};
