import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/types";

export const HoverTooltip = ({
  children,
  tooltipContent,
  tooltipPlacement = "right" as Placement,
  delayShow = 250,
  delayHide = 400,
  tooltipId,
  ...restProps
}) => {
  return (
    <OverlayTrigger
      placement={tooltipPlacement}
      delay={{ show: delayShow, hide: delayHide }}
      overlay={
        <Tooltip id={tooltipId} {...restProps}>
          {tooltipContent}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
};
