import moment from "moment";

export const getStartDateTimeInUTC = (dateString, time) => {
  // Parse the original datetime string into a Date object
  const originalDatetime = new Date(dateString);
  if (isNaN(originalDatetime.getTime())) {
    throw new Error("Invalid date string");
  }
  originalDatetime.setHours(time?.hour?.value || 0);
  originalDatetime.setMinutes(time?.minute?.value || 0);
  const newDatetimeStr = originalDatetime.toISOString();
  return newDatetimeStr;
};

export const formatUTCDateToLocalString = (utcDateString) => {
  // Parse the UTC datetime string into a moment object
  const momentDate = moment.utc(utcDateString);
  // Convert to local time
  momentDate.local();

  // Format the date with the desired format
  const formattedDate = momentDate.format("YYYY-MM-DD, hh:mm A");
  return formattedDate;
};

// Function to check if token is expired
export function isTokenExpired(token) {
  if (token !== "null" && token !== "undefined" && token) {
    const decodedToken = JSON.parse(atob(token?.split(".")[1]));
    return Date.now() >= decodedToken.exp * 1000;
  } else {
    return true;
  }
}
