export enum HttpStatus {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  GATEWAY_TIMEOUT = 504,
  REQUEST_SUCCEEDED = 200,
  RESOURCE_CREATED = 201,
}

export const CustomStatusCode = {
  sessionExpire: 1100,
};

export const CustomStatusCodeMessage = {
  [CustomStatusCode.sessionExpire]:
    "Something wrong with user session, Please login again",
};
