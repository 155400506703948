import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { isTokenExpired } from "../utils/dateUtils";
import { refreshToken } from "../teamslist/service";
import { store } from "../redux/store";
import { setSessionExpireData } from "../redux/sessionExpireSlice";
import { CustomStatusCode, CustomStatusCodeMessage } from "../utils/constants";
export enum API_METHOD {
  GET = "get",
  POST = "post",
  PUT = "put",
  PATCH = "patch",
  DELETE = "delete",
}

class API {
  static headers: Record<string, string> = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  static signal: any = undefined;

  static get(
    endPoint: string,
    params: Record<string, any> = {},
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse | AxiosError> {
    return this.xhr(endPoint, { method: "GET", params, ...config });
  }

  static post(
    endPoint: string,
    payload: any,
    config: AxiosRequestConfig = {},
    isLoginApi = false,
  ): Promise<AxiosResponse | AxiosError> {
    return this.xhr(
      endPoint,
      { method: "POST", data: payload, ...config },
      isLoginApi,
    );
  }

  static put(
    endPoint: string,
    payload: any,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse | AxiosError> {
    return this.xhr(endPoint, { method: "PUT", data: payload, ...config });
  }

  static patch(
    endPoint: string,
    payload: any,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse | AxiosError> {
    return this.xhr(endPoint, {
      method: "PATCH",
      data: payload,
      ...config,
    });
  }

  static delete(
    endPoint: string,
    payload: any,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse | AxiosError> {
    return this.xhr(endPoint, {
      method: "DELETE",
      data: payload,
      ...config,
    });
  }

  static async xhr(
    location: string,
    config: AxiosRequestConfig = {},
    isLoginApi = false,
  ): Promise<AxiosResponse | AxiosError> {
    try {
      const { headers, signal } = this;
      if (!isLoginApi) {
        const oldRefreshToken = localStorage.getItem("refreshToken");
        const mobileNumber = localStorage.getItem("mobileNumber");
        if (isTokenExpired(localStorage.getItem("accessToken"))) {
          if (
            oldRefreshToken !== "undefined" &&
            mobileNumber !== "undefined" &&
            oldRefreshToken !== "null" &&
            mobileNumber !== "null" &&
            mobileNumber &&
            oldRefreshToken
          ) {
            await refreshToken();
          } else {
            // eslint-disable-next-line no-throw-literal
            throw {
              status: CustomStatusCode.sessionExpire,
              data: {
                message:
                  CustomStatusCodeMessage[CustomStatusCode.sessionExpire],
              },
            };
          }
        }
      }

      const url = location;
      const token = localStorage.getItem("accessToken");
      const role = localStorage.getItem("role");

      if (token) {
        headers.Authorization = `${token}`;
        // headers["access-token"] = token;
        if (role !== null) {
          headers[`X-current-context`] = role;
        }
      }

      const response = await axios({
        url,
        headers,
        signal,
        ...config,
      });

      return response;
    } catch (error: any) {
      if (error.status === CustomStatusCode.sessionExpire) {
        store.dispatch(
          setSessionExpireData({
            variant: "alert",
            show: true,
            title: error.data.message,
          }),
        );
        return error;
      }
      return (error.response as AxiosResponse) || error;
    }
  }
}

export default API;
