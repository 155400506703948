import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation } from "react-router-dom";
import { Login } from "../../common/constants";
import { HttpStatus } from "../../utils/constants";
import { fetchAdminUserDetails } from "../../Login/service";
import { getUserDetailsObject, setUserDetails } from "../../utils/userUtil";
import { useEffect, useState } from "react";

const UserProfile = () => {
  const location = useLocation();
  const [user, setUser] = useState(() => {
    const userJson = localStorage.getItem("user");
    return userJson ? getUserDetailsObject(userJson) : null;
  });

  const getUserDetails = async () => {
    const resData = await fetchAdminUserDetails();
    if (
      resData.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData.status === HttpStatus.RESOURCE_CREATED
    ) {
      setUserDetails(resData?.data?.data);
      setUser(resData?.data?.data);
    }
  };

  useEffect(() => {
    if (!user) getUserDetails();
  }, [location?.pathname, user]);

  return (
    <Dropdown className="profile-dropdown">
      <Dropdown.Toggle
        variant="link"
        id="dropdown-basic"
        className="d-flex align-items-center flex-row py-0"
      >
        <div>
          <small className="d-flex fw-medium profile-name justify-content-end">
            {user?.name || ""}
          </small>
          <span className="d-flex profile-role justify-content-end">
            Admin User
          </span>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="bg-white">
        {/* <Dropdown.Item>
                    <Link to="/users-list">{MenuItems.settings}</Link>
                </Dropdown.Item>
                <Dropdown.Item>
                    <Link to="/users-list">{Common.profile}</Link>
                </Dropdown.Item> */}
        <Dropdown.Item
          href="/login"
          onClick={() => {
            localStorage.clear();
          }}
        >
          <Link to="/login">{Login.logoutText}</Link>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserProfile;
