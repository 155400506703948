import { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Form,
  Spinner,
  Button,
} from "react-bootstrap";
import "./style.scss";
import { useNavigate, useParams } from "react-router-dom";
import { fetchPlayer, updatePlayer } from "../../service";
import { CustomStatusCode, HttpStatus } from "../../../utils/constants";
import { EditPencil } from "../../../common/SVG";
import ReactToastr from "../../../common/components/ReactToaster";
import ConfirmationModal from "../../../common/components/Modal/ConfirmationModel";
import { Action, EMPTY_ERROR, max_photo_size } from "../../../common/constants";
import NoImage from "../../../../src/common/assets/NoImage.svg";
import { URLS } from "../../../common/url";
import { ImageUpdate } from "../../../common/components/ImageUpdate";

export const PlayerInfo = () => {
  const { playerId } = useParams();
  const navigate = useNavigate();
  const [isFutyPositionEditing, setIsFutyPositionEditing] =
    useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isPlayerInfoLoading, setIsPlayerInfoLoading] =
    useState<boolean>(false);
  const [updatedFutyPosition, setUpdatedFutyPosition] = useState<string>();
  const [isPlayerImageUpload, setIsPlayerImageUpload] = useState(false);
  const [playerImageUrl, setPlayerImageUrl] = useState(null);
  const [isProfileImageUpdated, setIsProfileImageUpdated] = useState(false);
  const [isUpdatingDetails, setIsUpdatingDetails] = useState(false);
  const [player, setPlayer] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  useEffect(() => {
    if (playerId) {
      getPlayer(playerId);
    }
  }, [playerId]);

  useEffect(() => {
    setUpdatedFutyPosition(player?.data?.futyPosition);
  }, [player]);

  const getPlayer = async (playerId: string) => {
    setIsPlayerInfoLoading(true);
    const resData: any = await fetchPlayer(playerId);
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setPlayer((pre) => ({
        ...pre,
        isLoading: false,
        data: resData?.data?.data,
      }));
      setPlayerImageUrl(resData?.data?.data?.profileImageUrl);
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message || "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
    }
    setIsPlayerInfoLoading(false);
  };

  const handleFutyPositionUpdate = () => {
    const updatedBody = {
      futyPosition: updatedFutyPosition,
    };
    if (isProfileImageUpdated) updatedBody["profileImageUrl"] = playerImageUrl;
    updatePlayerFutyPosition(playerId, updatedBody);
    setShowModal(false);
    setUpdatedFutyPosition("");
    setIsFutyPositionEditing(false);
  };

  const updatePlayerFutyPosition = async (
    playerId: string,
    updatedBody: any,
  ) => {
    setIsUpdatingDetails(true);
    const resData: any = await updatePlayer(playerId, updatedBody);
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setIsProfileImageUpdated(false);
      setIsUpdatingDetails(false);
      setPlayer((pre) => ({
        ...pre,
        isLoading: false,
        data: {
          ...player.data,
          futyPosition: resData?.data?.data?.futyPosition,
        },
      }));
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: resData?.message || "Successfully updating player details",
        variant: "success",
      }));
    } else {
      setIsUpdatingDetails(false);
      setUpdatedFutyPosition(player?.data?.futyPosition);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message || "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
    }
  };

  return isPlayerInfoLoading ? (
    <div className="react-table-loader">
      <Spinner animation="border" variant="primary" />
    </div>
  ) : (
    <>
      <div className="text-left">
        <Button variant="link" onClick={() => navigate(-1)}>
          {Action.goBack}
        </Button>
      </div>
      <Card>
        <CardBody>
          <Row>
            <Col lg="3">
              <ImageUpdate
                NoImage={NoImage}
                setShowToaster={setShowToaster}
                max_photo_size={max_photo_size}
                fetchedPresignUrl={URLS.PLAYERIMAGE_PRESIGNED_URL}
                imageClassName="player-image-classname"
                setIsImageUpdated={setIsProfileImageUpdated}
                oldImage={player?.data?.profileImageUrl}
                isImageUpload={isPlayerImageUpload}
                setIsImageUpload={setIsPlayerImageUpload}
                imageUrl={playerImageUrl}
                setImageUrl={setPlayerImageUrl}
              />
            </Col>
            <Col>
              <Row>
                <Col>
                  <h2>{player?.data?.name}</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Team:</b> {player?.data?.team?.name}
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Shirt Number:</b> {player?.data?.shirtNumber}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <b>Futy Position:</b> {player?.data?.futyPosition}{" "}
                  <span
                    onClick={() =>
                      setIsFutyPositionEditing(!isFutyPositionEditing)
                    }
                  >
                    <EditPencil />
                  </span>
                  {isFutyPositionEditing && (
                    <Form>
                      <div key={`inline-radio`}>
                        <Form.Check
                          inline
                          label="Attacker"
                          name="position"
                          type="radio"
                          id={`Attacker`}
                          value={`Attacker`}
                          checked={updatedFutyPosition === "Attacker"}
                          onChange={() => {
                            setUpdatedFutyPosition("Attacker");
                          }}
                        />
                        <Form.Check
                          inline
                          label="Defender"
                          name="position"
                          type="radio"
                          id={`Defender`}
                          value={`Defender`}
                          checked={updatedFutyPosition === "Defender"}
                          onChange={() => {
                            setUpdatedFutyPosition("Defender");
                          }}
                        />
                      </div>
                    </Form>
                  )}
                </Col>
              </Row>
              <Button
                onClick={() => {
                  !isUpdatingDetails &&
                    !isPlayerImageUpload &&
                    setShowModal(true);
                }}
                disabled={isUpdatingDetails || isPlayerImageUpload}
              >
                {isUpdatingDetails ? Action.saving : Action.save}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ConfirmationModal
        variant="alert"
        show={showModal}
        title={`Are you sure you want to save player details?`}
        infoText="Please note: The updates will not be applied to published matches."
        handleClick={handleFutyPositionUpdate}
        handleClose={() => setShowModal(false)}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={() => setShowModal(false)}
        btnText="Confirm"
      />
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleClose}
        position="top-center"
        autohide
      />
    </>
  );
};
