import {
  DayTaskTypeEnum,
  RewardCategoryEnum,
  RewardCurrencyEnum,
} from "../../types";

export interface Option<T> {
  label: string;
  value: T;
}

export const taskTypeOptions = [
  {
    label: "Login",
    value: DayTaskTypeEnum.LOGIN,
  },
];

export const currencyOptions = [
  {
    label: "Futy Credits",
    value: RewardCurrencyEnum.FUTY_CREDITS,
  },
];

export const categoryOptions = [
  {
    label: "Normal",
    value: RewardCategoryEnum.NORMAL,
  },
  {
    label: "Bonus",
    value: RewardCategoryEnum.BONUS,
  },
];
