import API from "../api/api";
import { getBase } from "../apiconfig";
import { URLS } from "../common/url";
import { HttpStatus } from "../utils/constants";
import { ERROR_MESSAGE_TYPE } from "../utils/error/errorMsg";

export const doUserLogin = async (payload = {}, config = {}) => {
  try {
    const url: string = getBase() + "/api" + URLS.USER_URL + "/adminLogin";
    const data: any = await API.post(url, payload, config, true);
    if (data.status === 200 || data.status === 201) {
      return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.statusCode],
      };
    }
  } catch (error) {
    return error;
  }
};

export const fetchAdminUserDetails = async (payload = {}, config = {}) => {
  try {
    const url: string = getBase() + "/api" + URLS.USER_URL;
    const data: any = await API.get(url, payload, config);
    if (data.status === 200 || data.status === 201) {
      if (data?.data?.data?.error)
        // eslint-disable-next-line no-throw-literal
        throw {
          status: HttpStatus.BAD_REQUEST,
          message:
            data?.data?.data?.error ||
            ERROR_MESSAGE_TYPE[HttpStatus.BAD_REQUEST],
        };
      else return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.statusCode],
      };
    }
  } catch (error) {
    return error;
  }
};

export const clearCacheApi = async (config = {}) => {
  try {
    const url: string = getBase() + "/api" + URLS.USER_URL + "/cache/clear";
    const data: any = await API.delete(url, config);
    if (data.status === 200 || data.status === 201) {
      if (data?.data?.data?.error)
        // eslint-disable-next-line no-throw-literal
        throw {
          status: HttpStatus.BAD_REQUEST,
          message:
            data?.data?.data?.error ||
            ERROR_MESSAGE_TYPE[HttpStatus.BAD_REQUEST],
        };
      else return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.statusCode],
      };
    }
  } catch (error) {
    return error;
  }
};
