import { HttpStatus } from "../constants";

interface ErrorMessages {
  [HttpStatus.BAD_REQUEST]: string;
  [HttpStatus.UNAUTHORIZED]: string;
  [HttpStatus.FORBIDDEN]: string;
  [HttpStatus.NOT_FOUND]: string;
  [HttpStatus.INTERNAL_SERVER_ERROR]: string;
  [HttpStatus.BAD_GATEWAY]: string;
  [HttpStatus.GATEWAY_TIMEOUT]: string;
  ErrCanceled: string;
}

export const ERROR_MESSAGE_TYPE: ErrorMessages = {
  [HttpStatus.BAD_REQUEST]: "Cannot process the request",
  [HttpStatus.UNAUTHORIZED]: "Unauthorized Please login again",
  [HttpStatus.FORBIDDEN]: "You don't have authorized to access right",
  [HttpStatus.NOT_FOUND]: "Url Not Found",
  [HttpStatus.INTERNAL_SERVER_ERROR]:
    "Internal server error try after sometime",
  [HttpStatus.BAD_GATEWAY]: "Couldn't complete the request please try again",
  [HttpStatus.GATEWAY_TIMEOUT]: "Server timeout please try after sometime",
  ErrCanceled: "Request Canceled",
};
