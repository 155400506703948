import { SVG_COLOR } from "../../constants";
import { Checkmark, ClearInputCross } from "../../SVG";
export const TrueFalseIconViewer = ({ value, classNames = "" }) => {
  return (
    <div className={`align-center ${classNames}`}>
      {value === true ? (
        <Checkmark stroke={SVG_COLOR.green} />
      ) : value === false ? (
        <ClearInputCross stroke={SVG_COLOR.red} />
      ) : (
        <></>
      )}
    </div>
  );
};
