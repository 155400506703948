import { useState, useEffect } from "react";
import "./style.scss";
import { Col, Container, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import { UserStatComponent } from "../UserStats";
import { useSearchParams } from "react-router-dom";
import { ReferralStatComponent } from "../ReferralStats";
import { MatchStatComponent } from "../Matches";
import Typography from "../../../common/components/Typography";
import DateRangeFilter from "../../../common/components/DateRangeFilter/DateRangeFilter";
import {
  StatsTabKeysEnum,
  StatsTabTitles,
  Stats as StatsConstant,
} from "../../../common/constants";
import { getLocalDisplayedDateRange } from "../../../teamslist/component/TeamConfigure/teamConfigHelper";
import IdVerificationComponent from "../IdVerification";
import {
  getInitialDateRange,
  initialDateRange,
  initialStaticDateRange,
  referralStatsStaticDateRange,
  staticDateOption,
  tgStatsStaticDateOption,
} from "../../statsUtil";
import { useSelector } from "react-redux";
import RetentionView from "../RetentionView";
import { getActiveSeason } from "../../../LeaguesConfigure/components/leaguesUtils";
import { CommonMessage } from "../../../common/message";
import Select from "react-select";

export function formatStatsDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

const StatTitle = {
  users: "User Stats",
  referral: "Referral Stats",
  match: "Match Stats",
  idVerification: "Id Verification Stats",
  retention: "Retention View",
};

const FilterTitle = {
  PercentageView: "Enable Percentage View",
};

interface IStatsProps {
  type?: "stats" | "tgStats";
}

export const Stats = ({ type = "stats" }: IStatsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab");
  const [activeTab, setActiveTab] = useState(selectedTab);
  const [dateRange, setDateRange] = useState(getInitialDateRange());
  const [referralDateRange, setReferralDateRange] = useState(
    getInitialDateRange(),
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [definedDateRange, setDefinedDateRange] = useState<any>(
    initialStaticDateRange,
  );
  const [referralDefinedDateRange, setReferralDefinedDateRange] = useState<any>(
    referralStatsStaticDateRange,
  );

  /* code for handling the logic for the leagueSeason change - start */
  const leaguesMetadata = useSelector((state: any) => state.leagues);
  const [leaguesOption, setLeaguesOption] = useState(
    leaguesMetadata.leaguesSeason,
  );
  const [seasonsOption, setSeasonsOption] = useState([]);
  const [selectedLeagueOption, setSelectedLeagueOption] = useState(null);
  const [selectedSeasonOption, setSelectedSeasonOption] = useState(null);
  const [retentionViewPercentageFlag, setRetentionViewPercentageFlag] =
    useState(false);

  useEffect(() => {
    setLeaguesOption(leaguesMetadata.leaguesSeason);
  }, [leaguesMetadata]);

  const handleLeaguesChange = (e) => {
    const seasonOption = e?.leagueSeasons?.map((item) => ({
      ...item,
      label: item?.name,
      value: item?.id,
    }));
    const activeSeason = getActiveSeason(seasonOption);
    setSelectedLeagueOption(e);
    setSelectedSeasonOption(activeSeason);
    setSeasonsOption(seasonOption || []);
  };
  /* code for handling the logic for the leagueSeason change - end */

  const updateTab = (tab: string) => {
    setSearchParams({ tab });
    setActiveTab(tab);
  };

  const handleDateRange = (e) => {
    if (activeTab === StatsTabKeysEnum.REFERRAL) {
      setReferralDateRange(e);
      setReferralDefinedDateRange(referralStatsStaticDateRange);
    } else {
      setDateRange(e);
      setDefinedDateRange(referralStatsStaticDateRange);
    }
  };

  const handleDefinedDateRange = (filterButtonOption, oldValue) => {
    if (filterButtonOption?.key === "lifetime") {
      setDefinedDateRange((prev) => ({
        initialStaticDateRange,
        [filterButtonOption.key]: !prev[filterButtonOption.key],
      }));
      setDateRange([null, null]);
    } else {
      setDefinedDateRange((prev) => ({
        initialStaticDateRange,
        [filterButtonOption.key]: !prev[filterButtonOption.key],
      }));
      if (oldValue) setDateRange(initialDateRange);
      else {
        const dateToSet = getLocalDisplayedDateRange(
          filterButtonOption.daysToGet,
          filterButtonOption.daysNumber,
        );
        setDateRange(dateToSet);
      }
    }
  };

  const handleReferralDefinedDateRange = (filterButtonOption, oldValue) => {
    setReferralDefinedDateRange((prev) => ({
      referralStatsStaticDateRange,
      [filterButtonOption.key]: !prev[filterButtonOption.key],
    }));
    if (oldValue) setReferralDateRange(initialDateRange);
    else {
      const dateToSet = getLocalDisplayedDateRange(
        filterButtonOption.daysToGet,
        filterButtonOption.daysNumber,
      );
      setReferralDateRange(dateToSet);
    }
  };

  useEffect(() => {
    if (type === "tgStats") {
      if (!dateRange[0] || !dateRange[1]) {
        setStartDate(null);
        setEndDate(null);
      } else if (dateRange[0] && dateRange[1]) {
        setStartDate(formatStatsDate(dateRange[0]));
        setEndDate(formatStatsDate(dateRange[1]));
      }
    } else {
      if (dateRange[0] && dateRange[1]) {
        setStartDate(formatStatsDate(dateRange[0]));
        setEndDate(formatStatsDate(dateRange[1]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    if (activeTab && activeTab !== selectedTab) {
      setActiveTab(selectedTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    if (!selectedTab) {
      updateTab("users");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);
  return (
    <>
      <Container className="py-2 px-0 mx-0">
        <Row>
          <Col>
            <Typography variant="pageTitle">
              {type === "tgStats" ? `Stats` : StatTitle[activeTab]}
            </Typography>
          </Col>
        </Row>
        {activeTab !== StatsTabKeysEnum.REFERRAL &&
          activeTab !== StatsTabKeysEnum.RETENTION && (
            <Row>
              <Col>
                <DateRangeFilter
                  staticDateOption={
                    type === "tgStats"
                      ? tgStatsStaticDateOption
                      : staticDateOption
                  }
                  dateChangeHandler={handleDateRange}
                  staticDateChangeHandler={handleDefinedDateRange}
                  staticDateRange={definedDateRange}
                  dateRange={dateRange}
                  canClear={false}
                />
              </Col>
            </Row>
          )}
        {activeTab === StatsTabKeysEnum.REFERRAL && (
          <Row>
            <Col>
              <DateRangeFilter
                staticDateOption={
                  type === "tgStats"
                    ? tgStatsStaticDateOption
                    : staticDateOption
                }
                dateChangeHandler={handleDateRange}
                staticDateChangeHandler={handleReferralDefinedDateRange}
                staticDateRange={referralDefinedDateRange}
                dateRange={referralDateRange}
                canClear={false}
                dateRangeLabel={StatsConstant.referralDateRangeLabel}
                dateRangePlaceholder={
                  StatsConstant.referralDateRangePlaceholder
                }
              />
            </Col>
          </Row>
        )}
        {activeTab === StatsTabKeysEnum.RETENTION && (
          <div
            className="header-row-wrapper"
            style={{
              width: "100%",
              display: "flex",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <Select
              placeholder="Filter by leagues"
              options={leaguesOption}
              value={selectedLeagueOption}
              onChange={(e) => {
                handleLeaguesChange(e);
              }}
              isLoading={leaguesMetadata.isLoading}
              loadingMessage={() => <Spinner variant="primary" size="sm" />}
              noOptionsMessage={() =>
                leaguesMetadata?.isError
                  ? leaguesMetadata?.errorMsg
                  : CommonMessage.noData
              }
            />

            <Select
              placeholder="Filter by season"
              options={seasonsOption}
              value={selectedSeasonOption}
              onChange={(e) => {
                setSelectedSeasonOption(e);
              }}
            />
            <Col className="retention-view-percentage-flag-container">
              <div>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setRetentionViewPercentageFlag(
                      !retentionViewPercentageFlag,
                    );
                  }}
                />
                <label htmlFor="retentionViewPercentageFlag">
                  &nbsp;{FilterTitle.PercentageView}
                </label>
              </div>
            </Col>
          </div>
        )}
      </Container>
      <div className="tab-wrapper stats-tab-wrapper">
        <Tabs
          defaultActiveKey={1}
          id="matchTab"
          className="mb-2"
          activeKey={activeTab}
          onSelect={(key: any) => updateTab(key)}
        >
          <Tab eventKey={StatsTabKeysEnum.USERS} title={"Users"}>
            <UserStatComponent startDate={startDate} endDate={endDate} />
          </Tab>
          {type === "stats" && (
            <Tab eventKey={StatsTabKeysEnum.MATCH} title={"Match"}>
              <MatchStatComponent startDate={startDate} endDate={endDate} />
            </Tab>
          )}
          <Tab eventKey={StatsTabKeysEnum.REFERRAL} title={"Referral"}>
            <ReferralStatComponent
              startDate={referralDateRange[0]}
              endDate={referralDateRange[1]}
            />
          </Tab>
          {type === "stats" && (
            <Tab
              eventKey={StatsTabKeysEnum.ID_VERIFICATION}
              title={StatsTabTitles[StatsTabKeysEnum.ID_VERIFICATION]}
            >
              <IdVerificationComponent
                startDate={startDate}
                endDate={endDate}
              />
            </Tab>
          )}
          {type === "stats" && (
            <Tab
              eventKey={StatsTabKeysEnum.RETENTION}
              title={StatsTabTitles[StatsTabKeysEnum.RETENTION]}
            >
              <RetentionView
                selectedLeagueSeason={selectedSeasonOption}
                isPercentageChange={retentionViewPercentageFlag}
              />
            </Tab>
          )}
        </Tabs>
      </div>
    </>
  );
};
