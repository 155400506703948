import { useCallback, useState, useEffect } from "react";
import {
  DAYTIME_PHASE,
  EMPTY_ERROR,
  Reward,
  UserRewardStatusOption,
  defaultPageSize,
} from "../../common/constants";
import { Tabs, Tab } from "react-bootstrap";
import { getAllRewards, getUsersReward } from "../service";
import { HttpStatus } from "../../utils/constants";
import "./style.scss";
import { AddRewardView } from "./AddRewards";
import { RewardsDashboard } from "./RewardsDashboard";
import { UserRewardsView } from "./UsersRewards";
import {
  initialDateRange,
  initialStaticDateRange,
  resetStaticDateRange,
  staticDateOption,
} from "../rewardUtil";
import {
  getLocalDisplayedDateRange,
  getTimeStampedDate,
} from "../../teamslist/component/TeamConfigure/teamConfigHelper";
import { RewardHeaderButton } from "./RewardHeaderButton";
import ReactToastr from "../../common/components/ReactToaster";
import { getPaginationQuery } from "../../utils/userUtil";

export const RewardsView = () => {
  const [allRewardPageSize, setAllRewardPageSize] = useState(defaultPageSize);
  const [allRewardCurrentPage, setAllRewardCurrentPage] = useState(1);
  const [usersRewardPageSize, setUsersRewardPageSize] =
    useState(defaultPageSize);
  const [usersRewardCurrentPage, setUsersRewardCurrentPage] = useState(1);
  const [isAddRewardView, setIsAddRewardView] = useState(false);
  const [activeTab, setActiveTab] = useState<number>(1);
  const [status, setStatusFilter] = useState(UserRewardStatusOption[0]);
  const [mobileNumber, setMobileNumber] = useState("");
  const [username, setUsername] = useState("");
  const [filterMobileNumber, setFilterMobileNumber] = useState("");
  const [filterUsername, setFilterUsername] = useState("");
  const [statusValue, setStatusValue] = useState(UserRewardStatusOption[0]);
  const [dateRange, setDateRange] = useState(() =>
    getLocalDisplayedDateRange(
      staticDateOption[1].daysToGet,
      staticDateOption[1].daysNumber,
    ),
  );
  const [staticDateRange, setStaticDateRange] = useState<any>(
    initialStaticDateRange,
  );
  const [rewardAndBrand, setRewardAndBrand] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [usersReward, setUsersReward] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
    if (showToaster?.callBack) {
      showToaster.callBack();
    }
  };

  const handleGetAllReward = useCallback(async () => {
    setRewardAndBrand((prev) => ({
      ...prev,
      isLoading: true,
      error: EMPTY_ERROR,
    }));
    const resData: any = await getAllRewards({
      page: getPaginationQuery(allRewardCurrentPage, allRewardPageSize),
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setRewardAndBrand((pre) => ({
        ...pre,
        isLoading: false,
        data: resData?.data?.data,
      }));
    } else {
      setRewardAndBrand((prev) => ({
        ...prev,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRewardPageSize, allRewardCurrentPage]);

  const handleGetUsersReward = async () => {
    setUsersReward((prev) => ({
      ...prev,
      isLoading: true,
      error: EMPTY_ERROR,
    }));
    const startDate = getTimeStampedDate(dateRange?.[0], DAYTIME_PHASE.START);
    const endDate = getTimeStampedDate(dateRange?.[1], DAYTIME_PHASE.END);
    const resData: any = await getUsersReward({
      query: {
        status: statusValue?.value,
        username: filterUsername,
        mobile: filterMobileNumber,
        startDate,
        endDate,
      },
      page: getPaginationQuery(usersRewardCurrentPage, usersRewardPageSize),
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setUsersReward((pre) => ({
        ...pre,
        isLoading: false,
        data: resData?.data?.data,
      }));
    } else {
      setUsersReward((prev) => ({
        ...prev,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      }));
    }
  };

  useEffect(() => {
    handleGetAllReward();
  }, [handleGetAllReward]);

  useEffect(() => {
    handleGetUsersReward();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    usersRewardCurrentPage,
    usersRewardPageSize,
    filterMobileNumber,
    filterUsername,
    statusValue,
    dateRange,
  ]);

  useEffect(() => {
    if (usersRewardCurrentPage === 1) setStatusValue(status);
    else {
      setStatusValue(status);
      setUsersRewardCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (usersRewardCurrentPage === 1) setFilterMobileNumber(mobileNumber);
      else {
        setFilterMobileNumber(mobileNumber);
        setUsersRewardCurrentPage(1);
      }
    }, 1000);
    return () => clearTimeout(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileNumber]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (usersRewardCurrentPage === 1) setFilterUsername(username);
      else {
        setFilterUsername(username);
        setUsersRewardCurrentPage(1);
      }
    }, 1000);
    return () => clearTimeout(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  const handlePageReload = () => {
    handleGetAllReward();
  };

  const handleUsersRewardPageReload = () => {
    handleGetUsersReward();
  };

  const dateRangeSetter = (e) => {
    if (usersRewardCurrentPage === 1) setDateRange(e);
    else {
      setDateRange(e);
      setUsersRewardCurrentPage(1);
    }
  };
  const handleDateRange = (e) => {
    setStaticDateRange(resetStaticDateRange);
    dateRangeSetter(e);
  };
  const handleStaticDateRange = (filterButtonOption, oldValue) => {
    setStaticDateRange((prev) => ({
      initialStaticDateRange,
      [filterButtonOption.key]: !prev[filterButtonOption.key],
    }));
    if (oldValue) dateRangeSetter(initialDateRange);
    else {
      const dateToSet = getLocalDisplayedDateRange(
        filterButtonOption.daysToGet,
        filterButtonOption.daysNumber,
      );
      dateRangeSetter(dateToSet);
    }
  };

  return (
    <>
      {isAddRewardView ? (
        <AddRewardView
          handleGoBack={() => setIsAddRewardView(false)}
          handleReload={() => {
            handleGetAllReward();
          }}
          setShowToaster={setShowToaster}
        />
      ) : (
        <>
          <RewardHeaderButton
            setIsAddRewardView={setIsAddRewardView}
            activeTab={activeTab}
            setShowToaster={setShowToaster}
          />
          <div className="tab-wrapper reward-tab-wrapper">
            <Tabs
              defaultActiveKey={1}
              id="rewardTab"
              className="mb-3 reward-tabs"
              activeKey={activeTab}
              onSelect={(key: any) => setActiveTab(parseInt(key))}
            >
              <Tab
                eventKey={1}
                title={Reward.allReward}
                tabClassName="reward-tab-content"
              >
                <RewardsDashboard
                  rewardAndBrand={rewardAndBrand}
                  setAllRewardCurrentPage={setAllRewardCurrentPage}
                  setAllRewardPageSize={setAllRewardPageSize}
                  allRewardCurrentPage={allRewardCurrentPage}
                  allRewardPageSize={allRewardPageSize}
                  handlePageReload={handlePageReload}
                  setShowToaster={setShowToaster}
                />
              </Tab>
              <Tab
                eventKey={2}
                tabClassName="reward-tab-content"
                title={Reward.userRedeem}
              >
                <UserRewardsView
                  usersReward={usersReward}
                  setUsersRewardCurrentPage={setUsersRewardCurrentPage}
                  setUsersRewardPageSize={setUsersRewardPageSize}
                  usersRewardCurrentPage={usersRewardCurrentPage}
                  usersRewardPageSize={usersRewardPageSize}
                  handleUsersRewardPageReload={handleUsersRewardPageReload}
                  status={status}
                  setStatusFilter={setStatusFilter}
                  mobileNumber={mobileNumber}
                  setMobileNumber={setMobileNumber}
                  username={username}
                  setUsername={setUsername}
                  handleDateRange={handleDateRange}
                  handleStaticDateRange={handleStaticDateRange}
                  dateRange={dateRange}
                  staticDateRange={staticDateRange}
                  setShowToaster={setShowToaster}
                />
              </Tab>
            </Tabs>
          </div>
        </>
      )}
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleClose}
        position="top-center"
        autohide
      />
    </>
  );
};
