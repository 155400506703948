import { Card, CardBody, Col, Row } from "react-bootstrap";
import {
  ALPHANUMERIC_UPPERCASE_REGEX,
  max_photo_size,
  MiniLeagues,
} from "../../../../../../common/constants";
import "./style.scss";
import FormControl from "../../../../../../common/components/FormControl/FormControl";
import { getKickOffMatchFormat } from "../../../../miniLeagueUtil";
import BasicError from "../../../../../../common/components/Error/BasicError";
import { ImageUpdate } from "../../../../../../common/components/ImageUpdate";
import NoImage from "../../../../../../common/assets/NoImage.svg";
import React from "react";
import { URLS } from "../../../../../../common/url";
import { CopyIcon } from "../../../../../../common/SVG";

interface AddMiniLeagueInfoProps {
  leagueName: string;
  setLeagueName: (name: string) => void;
  kickOffMatch: any;
  uniqueCode: string;
  setUniqueCode: (code: string) => void;
  joinLeagueUrl: string;
  isMiniLeaguePublished: boolean;
  isEditingLeague: boolean;
  addMiniLeagueInfoError: any;
  setAddMiniLeagueInfoError: (errorObj) => void;
  error: boolean;
  errorMsg: string;
  isImageUpload: boolean;
  setIsImageUpload: React.Dispatch<React.SetStateAction<boolean>>;
  imageUrl: string;
  setImageUrl: React.Dispatch<React.SetStateAction<string>>;
  setIsImageUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
  oldImage: string;
  setShowToaster: React.Dispatch<React.SetStateAction<any>>;
}

export const AddMiniLeagueInfo = ({
  leagueName,
  setLeagueName,
  kickOffMatch,
  uniqueCode,
  setUniqueCode,
  joinLeagueUrl,
  isMiniLeaguePublished,
  isEditingLeague,
  addMiniLeagueInfoError,
  setAddMiniLeagueInfoError,
  error,
  errorMsg,
  setShowToaster,
  ...restProps
}: AddMiniLeagueInfoProps) => {
  const handleUniqueCodeInput = (e) => {
    const inputValue = e.target.value;
    const filteredValue = inputValue
      .replace(ALPHANUMERIC_UPPERCASE_REGEX, "")
      .toUpperCase();
    setUniqueCode(filteredValue);
    setAddMiniLeagueInfoError({
      ...addMiniLeagueInfoError,
      uniqueCode: { error: false, errorMsg: "" },
    });
  };
  return (
    <Card className="bg-white w-100">
      <CardBody>
        {error ? (
          <BasicError error={errorMsg} />
        ) : (
          <Row className="add-mini-league-info-content">
            <Col sm={3}>
              <div className="d-flex">
                <label className="d-block fw-light fs-12 mini-league-logo-style">
                  {MiniLeagues.featured.miniLeagueLogo}
                  {" :"}
                </label>
                <ImageUpdate
                  NoImage={NoImage}
                  setShowToaster={setShowToaster}
                  max_photo_size={max_photo_size}
                  fetchedPresignUrl={URLS.MINI_LEAGUE_LOGO_PRESIGNED_URL}
                  {...restProps}
                />
              </div>
            </Col>
            <Col sm={5}>
              <div
                className={`d-flex align-item-wrapper ${
                  addMiniLeagueInfoError?.leagueName?.error ? "mb-2" : "mb-24"
                }`}
              >
                <label className="d-block fw-light fs-12 label-style">
                  {MiniLeagues.featured.labelName}
                  <span className="required-label">*</span>
                  {" :"}
                </label>
                <span className="d-block fw-medium text-capitalize w-65">
                  <FormControl
                    onChange={(e: any) => {
                      setLeagueName(e.target.value);
                      setAddMiniLeagueInfoError({
                        ...addMiniLeagueInfoError,
                        leagueName: { error: false, errorMsg: "" },
                      });
                    }}
                    value={leagueName}
                    isRequired={true}
                    isError={addMiniLeagueInfoError?.leagueName?.error}
                    error={addMiniLeagueInfoError?.leagueName?.errorMsg}
                  />
                </span>
              </div>
              <div className="d-flex align-item-wrapper">
                <label className="d-block fw-light fs-12 label-style">
                  {MiniLeagues.featured.labelKickoffmatch}
                  <span className="required-label">*</span>
                  {" :"}
                </label>
                <span className="d-block fw-medium w-65 text-capitalize">
                  <FormControl
                    value={getKickOffMatchFormat(kickOffMatch)}
                    isRequired={true}
                    isError={addMiniLeagueInfoError?.kickOffMatch?.error}
                    error={addMiniLeagueInfoError?.kickOffMatch?.errorMsg}
                    readOnly={true}
                  />
                </span>
              </div>
            </Col>
            <Col sm={4}>
              <div className="d-flex align-item-wrapper mb-2">
                <label className="d-block fw-light fs-12 label-style">
                  {MiniLeagues.featured.uniqueCode}
                  <span className="required-label">*</span>
                  {" :"}
                </label>
                <span className="d-block fw-medium text-capitalize">
                  <FormControl
                    onChange={(e: any) => {
                      !isEditingLeague && handleUniqueCodeInput(e);
                    }}
                    helperText={MiniLeagues.messages.uniqueCodeHelperText(
                      MiniLeagues.uniqueCodeMinLength,
                      MiniLeagues.uniqueCodeMaxLength,
                    )}
                    value={uniqueCode}
                    disabled={isEditingLeague}
                    isRequired={true}
                    isError={addMiniLeagueInfoError?.uniqueCode?.error}
                    error={addMiniLeagueInfoError?.uniqueCode?.errorMsg}
                  />
                </span>
              </div>
              {isMiniLeaguePublished && (
                <div className="d-flex align-item-wrapper mb-2">
                  <label className="d-block fw-light fs-12 label-style">
                    {MiniLeagues.featured.joinLeagueUrl}
                    {" :"}
                  </label>
                  <div className="d-flex fw-medium">
                    <input
                      className="form-control"
                      type="text"
                      value={joinLeagueUrl}
                      readOnly
                      disabled
                    />
                    <button
                      className="copy-button"
                      onClick={() =>
                        navigator.clipboard.writeText(joinLeagueUrl)
                      }
                    >
                      <CopyIcon />
                    </button>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};
