import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Row,
  Form,
  Card,
  CardHeader,
  CardBody,
} from "react-bootstrap";
import "./style.scss";
import {
  IAddReferralData,
  addReferral,
  fetchReferrals,
  updateReferralApi,
} from "../../service";
import { CustomStatusCode, HttpStatus } from "../../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import ReactToastr from "../../../common/components/ReactToaster";
import {
  ALPHANUMERIC_UPPERCASE_REGEX,
  Action,
  EMPTY_ERROR,
  Referrals,
  referralTypeOptions,
} from "../../../common/constants";
import { isAddingReferralsButtonDisabled } from "./addingReferralsHelper";
import classNames from "classnames";
import FormSelect from "../../../common/components/FormSelect/FormSelect";
import FormCheckbox from "../../../common/components/FormCheckbox/FormCheckbox";
import "./style.scss";
import { getCommonError } from "../../../common/message";
const initialErrorState = {
  referralCodeError: EMPTY_ERROR,
  referralDescriptionError: EMPTY_ERROR,
  referralTypeError: EMPTY_ERROR,
  messageError: EMPTY_ERROR,
};

export const AddReferral = () => {
  const location = useLocation();
  const locationData = location.state || null;
  const [referralCode, setReferralCode] = useState<string>("");
  const [referralDescription, setReferralDescription] = useState<string>("");
  const [referralType, setReferralType] = useState(null);
  const [isBonus, setIsBonus] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState(initialErrorState);
  const [isPubCampaignSelected, setIsPubCampaignSelected] = useState(false);
  const [isEditingReferral, setIsEditingReferral] = useState(false);
  const navigate = useNavigate();
  const [referralList, setReferralList] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
    if (showToaster?.callBack) {
      showToaster.callBack();
    }
  };

  useEffect(() => {
    if (referralType?.value === referralTypeOptions[1].value) {
      setIsPubCampaignSelected(true);
      setIsBonus(true);
    } else {
      setIsPubCampaignSelected(false);
    }
  }, [referralType]);

  useEffect(() => {
    if (locationData) {
      const { code, description, message, type } = locationData;
      const referralType = referralTypeOptions.find(
        (item) => item.value === type,
      );
      setIsEditingReferral(true);
      setReferralCode(code);
      setReferralDescription(description);
      setMessage(message);
      setReferralType(referralType);
    }
  }, [locationData]);
  const getReferralsList = async () => {
    const resData: any = await fetchReferrals();
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setReferralList((pre) => ({
        ...pre,
        isLoading: false,
        data: resData?.data?.data?.promotions,
      }));
    }
  };

  const handleReferralCodeInput = (e) => {
    const inputValue = e.target.value;
    const filteredValue = inputValue
      .replace(ALPHANUMERIC_UPPERCASE_REGEX, "")
      .toUpperCase();
    setReferralCode(filteredValue);
    checkIfDuplicate(filteredValue);
    setError((prev) => ({
      ...prev,
      referralCodeError: EMPTY_ERROR,
    }));
  };
  const checkIfDuplicate = async (referralInput: string) => {
    const hasDuplicate = referralList?.data?.some(
      (referral) =>
        referral?.code?.toLowerCase() === referralInput?.toLowerCase(),
    );
    if (hasDuplicate) {
      setIsDuplicate(true);
    } else {
      setIsDuplicate(false);
    }
  };

  const handleReferralError = () => {
    let referralError = "";
    let descriptionError = "";
    let referralTypeError = "";
    let messageError = "";
    if (!referralCode) referralError = "Please enter referral code";
    else if (
      referralCode?.length < Referrals.referralCodeMinLength ||
      referralCode?.length > Referrals.refefrralCodeMaxLength
    )
      referralError = `Please keep referral code length between ${Referrals.referralCodeMinLength} and ${Referrals.refefrralCodeMaxLength}`;
    if (!referralDescription?.trim())
      descriptionError = "Please enter referral code description";
    if (isPubCampaignSelected) {
      if (!message?.trim()) messageError = Referrals.error.messageError;
      else if (message?.trim()?.length > Referrals.messageMaxLength)
        messageError = Referrals.error.messageMaxLengthError(
          Referrals.messageMaxLength,
        );
    }
    if (!referralType) referralTypeError = Referrals.error.typeError;
    setError((prev) => ({
      ...prev,
      referralCodeError: { isError: !!referralError, errorMsg: referralError },
      referralDescriptionError: {
        isError: !!descriptionError,
        errorMsg: descriptionError,
      },
      referralTypeError: {
        isError: !!referralTypeError,
        errorMsg: referralTypeError,
      },
      messageError: {
        isError: !!messageError,
        errorMsg: messageError,
      },
    }));
  };

  const createReferral = async () => {
    setIsLoading(true);
    const referralData: IAddReferralData = {
      code: referralCode,
      type: referralType?.value,
      description: referralDescription?.trim(),
      credits: 0,
      ...(isPubCampaignSelected && { message: message?.trim(), isBonus }),
    };
    const resData: any = await addReferral(referralData);
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      if (resData?.data?.data?.error) {
        if (
          resData?.data?.data?.error ===
          Referrals.error.duplicatePromotionCodeError
        )
          setIsDuplicate(true);
        else
          setShowToaster((prev) => ({
            ...prev,
            show: true,
            message: getCommonError(resData?.data?.data?.error),
            variant: "danger",
          }));
      } else {
        navigate(`/referrals`);
      }
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: getCommonError(resData?.message),
          variant: "danger",
        }));
    }
    setIsLoading(false);
  };

  const updateReferral = async () => {
    setIsLoading(true);
    const payload = {
      id: locationData?.id,
      message: message?.trim(),
    };
    const resData: any = await updateReferralApi(payload);
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setIsLoading(false);
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: resData?.message || Referrals.succussfullMessageUpdate,
        variant: "success",
        callBack: () => {
          navigate(`/referrals`);
        },
      }));
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: getCommonError(resData?.message),
          variant: "danger",
        }));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getReferralsList();
  }, []);

  return (
    <>
      <div>
        <div className="text-left">
          <Button variant="link" onClick={() => navigate(-1)}>
            {Action.goBack}
          </Button>
        </div>
        <Card className="bg-white mb-3">
          <CardHeader className="bg-primary-subtle card-header-style">
            {"Add Referral Code"}
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <Form.Group className="mb-3">
                  <Form.Label className={classNames("fs-12 fw-medium")}>
                    {`Refferral Code (min ${Referrals.referralCodeMinLength} and max ${Referrals.refefrralCodeMaxLength} alphanumeric char)`}
                    {<span className="text-danger">*</span>}
                  </Form.Label>
                  <Form.Control
                    value={referralCode}
                    type="text"
                    disabled={isEditingReferral}
                    onChange={(e) => {
                      !isEditingReferral && handleReferralCodeInput(e);
                    }}
                    placeholder="Enter Referral Code"
                    aria-label="Enter Referral Code"
                    aria-describedby="duplicatePassword"
                  />
                  {isDuplicate ? (
                    <Form.Text id="duplicatePassword" className="text-danger">
                      Referral already exists, please try new one.
                    </Form.Text>
                  ) : error.referralCodeError.isError ? (
                    <Form.Text id="emptyDescription" className="text-danger">
                      {error.referralCodeError.errorMsg}
                    </Form.Text>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className={classNames("fs-12 fw-medium")}>
                    {`Description`}
                    {<span className="text-danger">*</span>}
                  </Form.Label>
                  <Form.Control
                    value={referralDescription}
                    as="textarea"
                    style={{ height: "50px" }}
                    disabled={isEditingReferral}
                    onChange={(e) => {
                      if (!isEditingReferral) {
                        setReferralDescription(e.target.value);
                        setError((prev) => ({
                          ...prev,
                          referralDescriptionError: EMPTY_ERROR,
                        }));
                      }
                    }}
                    placeholder="Enter Description"
                    aria-label="Enter Description"
                  />
                  {/* <label htmlFor="promoDescription">Description</label> */}
                  {error.referralDescriptionError.isError && (
                    <Form.Text id="emptyDescription" className="text-danger">
                      {error.referralDescriptionError.errorMsg}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormSelect
                  className="mb-3"
                  label={Referrals.referralTypeLable}
                  options={referralTypeOptions}
                  value={referralType}
                  isDisabled={isEditingReferral}
                  onChange={(e) => {
                    if (!isEditingReferral) {
                      setReferralType(e);
                      setError((prev) => ({
                        ...prev,
                        referralTypeError: EMPTY_ERROR,
                      }));
                    }
                  }}
                  isRequired={true}
                  isError={error?.referralTypeError?.isError}
                  error={error?.referralTypeError?.errorMsg}
                />
              </Col>
            </Row>
            {isPubCampaignSelected && (
              <>
                <Row>
                  <Col md={6}>
                    <FormCheckbox
                      className="mt-1 mb-2"
                      label={Referrals.bonus}
                      type="switch"
                      checked={isBonus}
                      disabled={isEditingReferral || isPubCampaignSelected}
                      onChange={(e: any) => {
                        !isEditingReferral &&
                          !isPubCampaignSelected &&
                          setIsBonus(e.target.checked);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className={classNames("fs-12 fw-medium")}>
                        {Referrals.messageLable(Referrals.messageMaxLength)}
                        {<span className="text-danger">*</span>}
                      </Form.Label>
                      <Form.Control
                        value={message}
                        placeholder={Referrals.messagePlacerholder}
                        as="textarea"
                        style={{ height: "50px" }}
                        onChange={(e) => {
                          setMessage(e.target.value);
                          setError((prev) => ({
                            ...prev,
                            messageError: EMPTY_ERROR,
                          }));
                        }}
                      />
                      {error.messageError.isError && (
                        <Form.Text
                          id="emptyMessageDescription"
                          className="text-danger"
                        >
                          {error.messageError.errorMsg}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col md="6">
                <Button
                  variant="primary"
                  disabled={isLoading}
                  onClick={() => {
                    const isDisableReferralButton =
                      isAddingReferralsButtonDisabled(
                        referralCode,
                        referralDescription,
                        message,
                        isPubCampaignSelected,
                        isDuplicate,
                      );
                    if (isDisableReferralButton) handleReferralError();
                    else if (isEditingReferral) updateReferral();
                    else createReferral();
                  }}
                >
                  {isLoading ? (
                    <span>Adding...</span>
                  ) : (
                    <span>Add Referral Code</span>
                  )}
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleClose}
        position="top-center"
        autohide
      />
    </>
  );
};
