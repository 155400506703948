import { useState } from "react";
import Typography from "../../../common/components/Typography";
import { Action, Reward } from "../../../common/constants";
import { Button } from "react-bootstrap";
import { convertBase64ToBuffer } from "../../../utils/transformation";
import { CustomStatusCode, HttpStatus } from "../../../utils/constants";
import { downloadUserRewardReport } from "../../service";
import * as FileSaver from "file-saver";
import { showFailureToaster } from "../../../utils/apiUtil";
import { getCommonError } from "../../../common/message";
import Itooltip from "../../../common/Itooltip/Itooltip";
import "./style.scss";

export const RewardHeaderButton = ({
  setIsAddRewardView,
  activeTab,
  setShowToaster,
}) => {
  const [isDownloadingUserRewardReport, setIsDownloadingUserRewardReport] =
    useState(false);
  const handleDownloadUserRewardReport = async () => {
    setIsDownloadingUserRewardReport(true);
    const resData: any = await downloadUserRewardReport();
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setIsDownloadingUserRewardReport(false);
      const buffer = convertBase64ToBuffer(resData?.data);
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      FileSaver.saveAs(blob, `user_redeemed_reward_${Date.now()}.xlsx`);
    } else {
      setIsDownloadingUserRewardReport(false);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };
  return (
    <div className="d-flex justify-content-between user-reward-header">
      <Typography variant="pageTitle">{Reward.rewardsText}</Typography>
      {activeTab === 1 && (
        <Button onClick={() => setIsAddRewardView(true)}>
          {Reward.addReward.addRewardText}
        </Button>
      )}
      {activeTab === 2 && (
        <div
          className={`button-with-tooltip ${
            isDownloadingUserRewardReport ? "disabled" : ""
          }`}
        >
          <Button
            className="download-report-button"
            onClick={() =>
              !isDownloadingUserRewardReport && handleDownloadUserRewardReport()
            }
            disabled={isDownloadingUserRewardReport}
          >
            {isDownloadingUserRewardReport
              ? Action.downloading
              : Action.download}
          </Button>
          <Itooltip
            placement="bottom"
            tooltipId={"download-user-redeemed-report"}
            tooltipContent={Reward.downloadUserRedeemedReportContent}
          />
        </div>
      )}
    </div>
  );
};
