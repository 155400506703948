import React, { useState, useMemo } from "react";
import { Spinner } from "react-bootstrap";
import { Table as BTable } from "react-bootstrap";
import classNames from "classnames";

import {
  useReactTable,
  getSortedRowModel,
  flexRender,
  SortingState,
  getFilteredRowModel,
  getCoreRowModel,
} from "@tanstack/react-table";
import { Info } from "../Svg";

interface IDataTable {
  configuration?: { [key: string]: any };
  columns?: any;
  data?: any;
  isLoading?: boolean;
  noDataFoundText?: string;
  manualPagination?: boolean;
  [key: string]: any;
}

const DataTable = ({
  configuration,
  columns,
  data,
  isLoading,
  noDataFoundText = "No data found",
  manualPagination = false,
  iFilter,
  AdditionFilterComp,
}: IDataTable) => {
  const columnData = useMemo(() => columns, [columns]);
  const rowData = useMemo(() => data, [data]);

  const [sorting, setSorting] = React.useState<SortingState>(
    configuration?.sorting || [],
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const table = useReactTable({
    data: rowData,
    columns: columnData,
    manualPagination,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableSortingRemoval: false,
    state: {
      sorting,
      globalFilter,
    },
  });

  return (
    <>
      <div
        className={classNames(configuration?.tableContainerClassname, "p-2")}
      >
        {iFilter.isClientSideFilter && !isLoading && (
          <div className={`${iFilter.filterContainerClass}`}>
            <input
              type="text"
              placeholder={iFilter.searchPlaceholder}
              value={globalFilter || ""}
              onChange={(e) => setGlobalFilter(e.target.value)}
              className={`form-control mb-2 ${iFilter.serachBoxClass}`}
            />
            <AdditionFilterComp />
          </div>
        )}
        {isLoading ? (
          <div className="react-table-loader">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            <BTable
              responsive
              className={classNames(
                configuration?.tableClassname,
                "react-table mb-0",
              )}
            >
              <thead>
                {table.getHeaderGroups().map((headerGroup: any) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header: any) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{
                            width:
                              header.column.columnDef.colWidth ??
                              `${header.column.columnDef.colWidth}px`,
                            maxWidth:
                              header.column.columnDef.maxWidth ??
                              `${header.column.columnDef.maxWidth}px !important`,
                          }}
                        >
                          {header.isPlaceholder ? null : (
                            <>
                              {header.column.columnDef.canSort === false ? (
                                <div
                                  {...{
                                    className:
                                      "d-flex align-items-end px-1 fw-normal",
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                                </div>
                              ) : (
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? "d-flex align-items-end fw-normal cursor-pointer"
                                      : "d-flex align-items-end px-1 fw-normal",
                                  }}
                                  onClick={() => {
                                    if (header.column.getCanSort())
                                      header.column.toggleSorting();
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                                  {header.column.getCanSort() && (
                                    <div className="d-flex mx-2">
                                      {/* {header.column.getSortIndex()} */}
                                      {/* {header.column.getIsSorted()} */}

                                      <div
                                        className={
                                          header.column.getIsSorted() === "asc"
                                            ? "text-dark opacity-100"
                                            : " opacity-50"
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="arrow-icon"
                                          viewBox="0 0 16 16"
                                          onClick={header.column.getToggleSortingHandler()}
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                                          />
                                        </svg>
                                      </div>

                                      <div
                                        className={
                                          header.column.getIsSorted() === "desc"
                                            ? "text-dark opacity-100"
                                            : "opacity-50"
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="arrow-icon"
                                          viewBox="0 0 16 16"
                                          onClick={header.column.getToggleSortingHandler()}
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {data.length > 0 ? (
                  table.getRowModel().rows.map((row: any) => {
                    return (
                      <tr
                        key={row.id}
                        className={
                          configuration?.getRowClassNames
                            ? configuration?.getRowClassNames(row.original)
                            : ""
                        }
                      >
                        {row.getVisibleCells().map((cell: any) => {
                          return (
                            <td key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={columns.length} className="text-center fs-2">
                      <Info />
                      <span className="ml-2">{noDataFoundText}</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </BTable>
          </>
        )}
      </div>
    </>
  );
};

export default DataTable;
