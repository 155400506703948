export const getLeaguesOptionData = (data) => {
  return (
    data?.map((item) => ({ ...item, label: item?.name, value: item?.id })) || []
  );
};

export const getActiveSeason = (seasonOption) => {
  return seasonOption.find((season) => season.isActive) || null;
};

export const getActivePopulatedLeague = (selectedLeague, resData) => {
  const populatedLeague = resData?.find(
    (item) => item?.id === selectedLeague?.id,
  );
  return populatedLeague || null;
};

export const getActivePopulatedSeason = (populatedLeague, selectedSeason) => {
  const populatedSeason = populatedLeague
    ? populatedLeague?.leagueSeasons?.find(
        (item) => item?.id === selectedSeason?.id,
      )
    : null;
  const updatedSelectedSeason = populatedSeason
    ? {
        ...populatedSeason,
        label: populatedSeason?.name,
        value: populatedSeason?.id,
      }
    : null;
  return updatedSelectedSeason;
};

export const getSelectedLeaguesOption = (data) => {
  const selectedLeagueOption = data?.length ? data[0] : null;
  return selectedLeagueOption
    ? {
        ...selectedLeagueOption,
        label: selectedLeagueOption?.name,
        value: selectedLeagueOption?.id,
      }
    : selectedLeagueOption;
};

export const getSelectedLeaguesSeasonOption = (data) => {
  const selectedLeaguesSeasonOption = data ? data?.leagueSeasons : [];
  return selectedLeaguesSeasonOption?.map((item) => {
    return {
      ...item,
      label: item?.name,
      value: item?.id,
    };
  });
};
