import React, { ReactNode } from "react";
import classNames from "classnames";

interface ITypography {
  variant?: string;
  element?: string;
  children?: ReactNode | undefined;
  [key: string]: any;
}

const mapElement = (variant: string) => {
  switch (variant) {
    case "pageTitle":
      return "h1";
    case "heading2":
      return "h2";
    case "heading3":
      return "h3";
    case "heading4":
      return "h4";
    case "heading5":
      return "h5";
    case "heading6":
      return "h6";
    case "subHeading":
      return "p";
    case "body":
      return "p";
    case "caption":
      return "span";
    default:
      return "p";
  }
};

const Typography = ({
  element,
  variant = "body",
  children,
  className,
  ...props
}: ITypography) => {
  return React.createElement(
    element ? element : mapElement(variant),
    {
      className: classNames(
        {
          "fs-4 lh-base mb-2 fw-bold": variant === "pageTitle",
          "fs-2": variant === "heading2",
          "fs-3": variant === "heading3",
          "fs-5":
            variant === "heading4" ||
            variant === "heading5" ||
            variant === "subHeading",
          "fs-6": variant === "heading6",
          "text-bodylarge": variant === "body",
          "text-bodymedium": variant === "caption",
        },
        className,
      ),
      ...props,
    },
    [children],
  );
};

export default Typography;
