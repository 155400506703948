import API from "../api/api";
import { HttpStatus } from "./constants";
import { ERROR_MESSAGE_TYPE } from "./error/errorMsg";

export const handleApiRequest = async (
  method,
  url,
  payload = {},
  config = {},
) => {
  try {
    const data: any = await API[method](url, payload, config);
    if (
      data.status === HttpStatus.REQUEST_SUCCEEDED ||
      data.status === HttpStatus.RESOURCE_CREATED
    ) {
      if (data?.data?.data?.error) {
        // eslint-disable-next-line no-throw-literal
        throw {
          status: HttpStatus.BAD_REQUEST,
          message:
            data?.data?.data?.error ||
            ERROR_MESSAGE_TYPE[HttpStatus.BAD_REQUEST],
        };
      } else {
        return data;
      }
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.statusCode],
      };
    }
  } catch (error) {
    return error;
  }
};

export const showToaster = (variant: string, message: string, callBack) => {
  return (prev: any) => ({
    ...prev,
    show: true,
    message,
    variant,
    callBack,
  });
};

export const showSuccessToaster = (
  message: string,
  callBack: (() => void) | null = null,
) => {
  return showToaster("success", message, callBack);
};

export const showFailureToaster = (
  message: string,
  callBack: (() => void) | null = null,
) => {
  return showToaster("danger", message, callBack);
};
