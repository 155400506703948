import { useEffect, useCallback, useState } from "react";
import { HttpStatus } from "../../../utils/constants";
import { TeamsInfoList } from "./TeamsInfoList";
import { fetchTeamList } from "../../../Players/service";
import {
  EMPTY_ERROR,
  TeamsConfig,
  defaultPageSize,
} from "../../../common/constants";
import { getActiveSeason } from "../../../LeaguesConfigure/components/leaguesUtils";
import Typography from "../../../common/components/Typography";
import Select from "react-select";
import "./style.scss";
import TeamsColorConfigView from "./TeamsColorConfigView";
import { Spinner } from "react-bootstrap";
import { CommonMessage } from "../../../common/message";
import { useSelector } from "react-redux";

const TeamsColorConfig = () => {
  const leaguesMetadata = useSelector((state: any) => state.leagues);
  const [teamsInfoList, setTeamsInfoList] = useState({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [leaguesOption, setLeaguesOption] = useState(
    leaguesMetadata.leaguesSeason,
  );
  const [seasonsOption, setSeasonsOption] = useState([]);
  const [selectedLeagueOption, setSelectedLeagueOption] = useState(null);
  const [selectedSeasonOption, setSelectedSeasonOption] = useState(null);
  const getTeamsInfoList = useCallback(async () => {
    setTeamsInfoList({
      ...teamsInfoList,
      isLoading: true,
      error: EMPTY_ERROR,
    });
    const resData: any = await fetchTeamList({
      leagueSeasonId: selectedSeasonOption?.id,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setTeamsInfoList((prev) => ({
        ...prev,
        isLoading: false,
        data: resData?.data?.data,
      }));
    } else {
      setTeamsInfoList({
        ...teamsInfoList,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeasonOption]);
  useEffect(() => {
    getTeamsInfoList();
  }, [getTeamsInfoList]);

  useEffect(() => {
    setLeaguesOption(leaguesMetadata.leaguesSeason);
  }, [leaguesMetadata]);

  const handleLeaguesChange = (e) => {
    const seasonOption = e?.leagueSeasons?.map((item) => ({
      ...item,
      label: item?.name,
      value: item?.id,
    }));
    const activeSeason = getActiveSeason(seasonOption);
    setSelectedLeagueOption(e);
    setSelectedSeasonOption(activeSeason);
    setSeasonsOption(seasonOption || []);
  };

  return selectedTeam ? (
    <TeamsColorConfigView
      team={selectedTeam}
      handleGoBack={() => setSelectedTeam(null)}
      handleReload={() => getTeamsInfoList()}
    />
  ) : (
    <>
      <div className="teams-color-config-header-row-wrapper mb-3">
        <div className="header-filter-wrapper">
          <Typography variant="pageTitle">
            {TeamsConfig.teams.teamsText}
          </Typography>
          <Select
            placeholder="Filter by leagues"
            className="leagues-select-wrapper"
            options={leaguesOption}
            value={selectedLeagueOption}
            onChange={(e) => {
              handleLeaguesChange(e);
            }}
            isLoading={leaguesMetadata.isLoading}
            loadingMessage={() => <Spinner variant="primary" size="sm" />}
            noOptionsMessage={() =>
              leaguesMetadata?.isError
                ? leaguesMetadata?.errorMsg
                : CommonMessage.noData
            }
          />
        </div>
        <Select
          placeholder="Filter by season"
          className="seasons-select-wrapper"
          options={seasonsOption}
          value={selectedSeasonOption}
          onChange={(e) => {
            setSelectedSeasonOption(e);
          }}
        />
      </div>
      <TeamsInfoList
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        teamsInfoList={teamsInfoList}
        currentPage={currentPage}
        setSelectedTeam={setSelectedTeam}
      />
    </>
  );
};

export default TeamsColorConfig;
