import React from "react";
import { Button, Card, CardBody, CardHeader, Col, Row } from "react-bootstrap";
import FormControl from "../FormControl/FormControl";
import { Action, Notification } from "../../constants";

const PushNotificationView = ({
  title,
  setTitle,
  message,
  setMessage,
  error,
  setError,
  isSendPushNotification,
  setShowConfirmationModal,
  titleMaxChar,
  messageMaxChar,
  header = "",
}) => {
  const handleSubmitPushNotification = () => {
    if (!isSendPushNotification) {
      let titleErrorMsg = "";
      let messageErrorMsg = "";
      if (title && message) {
        if (message?.length > messageMaxChar && title?.length > titleMaxChar)
          setError({
            titleError: {
              error: true,
              errorMsg: `Please keep the title less than ${titleMaxChar} char`,
            },
            messageError: {
              error: true,
              errorMsg: `Please keep the message less than ${messageMaxChar} char`,
            },
          });
        else if (message?.length > messageMaxChar)
          setError({
            titleError: { error: false, errorMsg: "" },
            messageError: {
              error: true,
              errorMsg: `Please keep the message less than ${messageMaxChar} char`,
            },
          });
        else if (title?.length > titleMaxChar)
          setError({
            titleError: {
              error: true,
              errorMsg: `Please keep the title less than ${titleMaxChar} char`,
            },
            messageError: {
              error: false,
              errorMsg: "",
            },
          });
        else setShowConfirmationModal(true);
      } else {
        if (!title) {
          titleErrorMsg = "Please enter some title";
        }
        if (!message)
          messageErrorMsg = "Please enter some push notification message";
        if (title?.length > titleMaxChar)
          titleErrorMsg = `Please keep the title less than ${titleMaxChar} char`;
        if (message?.length > messageMaxChar) {
          messageErrorMsg = `Please keep the message less than ${messageMaxChar} char`;
        }
        setError({
          titleError: { error: !!titleErrorMsg, errorMsg: titleErrorMsg },
          messageError: {
            error: !!messageErrorMsg,
            errorMsg: messageErrorMsg,
          },
        });
      }
    }
  };
  return (
    <div>
      <Card className="bg-white mb-3">
        {header && (
          <CardHeader className="bg-primary-subtle card-header-style">
            {header}
          </CardHeader>
        )}
        <CardBody>
          <Row>
            <Col>
              <FormControl
                label={`${Notification.pushNotify.title} (max ${titleMaxChar} char)`}
                min={0}
                className="mb-3"
                onChange={(e: any) => {
                  setTitle(e.target.value);
                  setError({
                    ...error,
                    titleError: { error: false, errorMsg: "" },
                  });
                }}
                value={title}
                isRequired={true}
                isError={error?.titleError?.error}
                error={error?.titleError?.errorMsg}
              />
            </Col>
            <Col>
              <FormControl
                label={`${Notification.pushNotify.message} (max ${messageMaxChar} char)`}
                as="textarea"
                row={4}
                className="mb-3"
                onChange={(e: any) => {
                  setMessage(e.target.value);
                  setError({
                    ...error,
                    messageError: { error: false, errorMsg: "" },
                  });
                }}
                value={message}
                isRequired={true}
                isError={error?.messageError?.error}
                error={error?.messageError?.errorMsg}
              />
            </Col>
            <Col className="d-flex align-items-center" lg={2} sm={2} xs={2}>
              <Button
                disabled={isSendPushNotification}
                onClick={handleSubmitPushNotification}
              >
                {isSendPushNotification ? Action.sending : Action.send}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default PushNotificationView;
