import { API_METHOD } from "../api/api";
import { getBase } from "../apiconfig";
import { URLS } from "../common/url";
import { handleApiRequest } from "../utils/apiUtil";

export const addNewReward = async (payload, config = {}) => {
  const url: string = getBase() + "/api" + URLS.REWARD + "/add";
  return await handleApiRequest(API_METHOD.POST, url, payload, config);
};

export const getBrandsList = async (payload = {}, config = {}) => {
  const url: string = getBase() + "/api" + URLS.REWARD + "/brands";
  return await handleApiRequest(API_METHOD.GET, url, payload, config);
};

export const getAllRewards = async (config = {}) => {
  const url: string = getBase() + "/api" + URLS.REWARD;
  return await handleApiRequest(API_METHOD.POST, url, config);
};

export const getUsersReward = async (config = {}) => {
  const url: string = getBase() + "/api" + URLS.REWARD + "/allUserRewards";
  return await handleApiRequest(API_METHOD.POST, url, config);
};

export const deleteAddedReward = async (payload, config: any = {}) => {
  const { id, ...restPayload } = payload;
  const url: string = getBase() + "/api" + URLS.REWARD + `/${id}`;
  return await handleApiRequest(API_METHOD.DELETE, url, restPayload, config);
};

export const resendEmail = async (payload, config: any = {}) => {
  const { id, ...restPayload } = payload;
  const url: string =
    getBase() + "/api" + URLS.REWARD + `/userReward/${id}/resendEmail`;
  return await handleApiRequest(API_METHOD.POST, url, restPayload, config);
};

export const downloadUserRewardReport = async () => {
  const url: string = getBase() + "/api" + URLS.REWARD + `/userReward/export`;
  return await handleApiRequest(API_METHOD.GET, url);
};
