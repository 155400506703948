import React, { FormEventHandler } from "react";
import Form from "react-bootstrap/Form";

interface IFormCheckbox {
  id?: string;
  label?: any;
  disabled?: boolean;
  defaultChecked?: boolean;
  value?: any;
  name?: string;
  className?: string;
  checked?: boolean;
  onChange?: FormEventHandler<HTMLInputElement> | undefined;
  [key: string]: any;
}

const FormCheckbox = ({
  id,
  label,
  disabled,
  onChange,
  defaultChecked,
  value,
  name,
  className,
  checked,
  type = "checkbox",
  props,
}: IFormCheckbox) => {
  return (
    <Form.Check
      type={type}
      id={id}
      label={label}
      disabled={disabled}
      onChange={onChange}
      defaultChecked={defaultChecked}
      checked={checked}
      value={value}
      name={name}
      className={className}
      {...props}
    />
  );
};

export default FormCheckbox;
