import React from "react";
import { Card, CardBody } from "react-bootstrap";
import classNames from "classnames";

interface ITableWrapper {
  children?: React.ReactNode;
  tableClassName?: string;
}

const TableWrapper = ({ tableClassName, children }: ITableWrapper) => {
  const cardClasses = classNames(
    "bg-white rounded shadow-sm table-scroll",
    tableClassName,
  );
  return (
    <Card className={cardClasses}>
      <CardBody>{children}</CardBody>
    </Card>
  );
};

export default TableWrapper;
