import React, { useCallback, useState } from "react";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import { ColumnDef } from "@tanstack/react-table";
import { Action, Match } from "../../../common/constants";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import { Button } from "react-bootstrap";
import ConfirmationModal from "../../../common/components/Modal/ConfirmationModel";
import { CustomStatusCode, HttpStatus } from "../../../utils/constants";
import { showFailureToaster, showSuccessToaster } from "../../../utils/apiUtil";
import { markUnmarkMatchAsSpecialApi } from "../../service";
import { getCommonError } from "../../../common/message";
import { getAppendedDateAndTime } from "../../../utils/matchUtil";
import { useLocation, useNavigate } from "react-router-dom";

export const UnPublishMatchList = ({
  unPublishedMatchList,
  setUnPubCurrentPage,
  setUnPubPageSize,
  unPubPageSize,
  unPubPage,
  setShowToaster,
  getUnPublishedMatchList,
}) => {
  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState(false);
  const [markUnmarkMatch, setMarkUnmarkMatch] = useState(null);
  const [isMarkingUnmarkingMatch, setIsMarkingUnmarkingMatch] = useState(false);
  const handleMarkUnMarkSpecialMatchAction = (match) => {
    setMarkUnmarkMatch(match);
    setShowConfirmationModal(true);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const markUnmarkMatchAsSpecial = async () => {
    setShowConfirmationModal(false);
    setIsMarkingUnmarkingMatch(true);
    const isFeaturedMatch = markUnmarkMatch?.featured;
    const resData = await markUnmarkMatchAsSpecialApi({
      id: markUnmarkMatch?.id,
      featured: !isFeaturedMatch,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setIsMarkingUnmarkingMatch(false);
      setShowToaster(
        showSuccessToaster(
          Match.unPublished.specialMatchSuccessMsg(isFeaturedMatch),
          () => {
            setMarkUnmarkMatch(null);
            getUnPublishedMatchList();
          },
        ),
      );
    } else {
      setMarkUnmarkMatch(null);
      setIsMarkingUnmarkingMatch(false);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };

  const configureBtnHandler = useCallback(
    (id: string) => {
      if (location.pathname.includes("telegram")) {
        navigate(`/matches/telegram-matches/${id}`);
      } else {
        navigate(`/matches/normal-matches/${id}`);
      }
    },
    [location.pathname, navigate],
  );

  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: "teamId1.name",
        id: "team1-name",
        header: () => <span>{Match.unPublished.team1name}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "teamId2.name",
        id: "team2-name",
        header: () => <span>{Match.unPublished.team2name}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "id",
        id: "match-id",
        header: () => <span>{Match.unPublished.matchId}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        id: "localStartDate",
        header: () => <span>{Match.unPublished.startDate}</span>,
        cell: ({ row }) => (
          <span className="no-wrap">
            {getAppendedDateAndTime(
              row?.original?.localStartDate,
              row?.original?.localStartTime,
            )}
          </span>
        ),
        canSort: false,
      },
      {
        accessorKey: "weekLeague",
        id: "weekLeague",
        header: () => <span>{Match.unPublished.weekLeague}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "featured",
        id: "featured",
        header: () => <span>{Match.unPublished.special}</span>,
        cell: ({ row }) => {
          const isCurrentRowMarking =
            isMarkingUnmarkingMatch &&
            row?.original?.id === markUnmarkMatch?.id;
          const isFeatured = row?.original?.featured;
          const buttonVariant = isFeatured ? "danger" : "primary";
          const buttonText = isCurrentRowMarking
            ? isFeatured
              ? Action.unMarking
              : Action.marking
            : isFeatured
            ? Action.unMark
            : Action.mark;

          return (
            <Button
              variant={buttonVariant}
              size="sm"
              className={`no-wrap ${isFeatured ? "mark-btn" : "unmark-btn"}`}
              onClick={() => handleMarkUnMarkSpecialMatchAction(row?.original)}
              disabled={isCurrentRowMarking}
            >
              {buttonText}
            </Button>
          );
        },
        canSort: false,
      },
      {
        accessorKey: "Action",
        id: "action",
        header: () => <span>{Match.unPublished.action}</span>,
        cell: (info) => (
          <Button
            size="sm"
            variant="link"
            className="no-wrap"
            onClick={() => configureBtnHandler(info.row.original.id)}
          >
            {Match.unPublished.configure}
          </Button>
        ),
        canSort: false,
      },
    ],
    [isMarkingUnmarkingMatch, markUnmarkMatch?.id, configureBtnHandler],
  );

  return (
    <>
      <TableWrapper tableClassName="mt-3">
        <ReactTable
          isLoading={unPublishedMatchList?.isLoading}
          data={unPublishedMatchList?.data?.matches || []}
          hasError={unPublishedMatchList?.error?.isError}
          errorMessage={unPublishedMatchList?.error?.errorMsg}
          columns={columns}
          configuration={{
            sorting: [],
            getRowClassNames: (row: any) => {
              return row?.featured ? "special-match-row" : "";
            },
          }}
          pageChangeHandler={setUnPubCurrentPage}
          pageSizeHandler={setUnPubPageSize}
          totalRows={unPublishedMatchList?.data?.totalCount} //total records
          rowsPerPage={unPubPageSize} //no of records per page
          currentPageNumber={unPubPage}
        />
      </TableWrapper>
      <ConfirmationModal
        variant="alert"
        show={showConfirmationModal}
        title={() =>
          Match.unPublished.specialMatchConfirmMsg(
            markUnmarkMatch?.featured,
            markUnmarkMatch?.id,
          )
        }
        handleClick={() => {
          markUnmarkMatchAsSpecial();
        }}
        handleClose={() => setShowConfirmationModal(false)}
        secondaryBtnText={Action.cancel}
        handleSecondaryBtnClick={() => setShowConfirmationModal(false)}
        btnText={Action.ok}
      />
    </>
  );
};
