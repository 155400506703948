import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import classNames from "classnames";
import { FormControlProps, Overlay, Tooltip } from "react-bootstrap";

interface IFormControl extends FormControlProps {
  label?: string;
  placeholder?: string;
  tooltip?: string;
  helperText?: string;
  name?: string;
  error?: string;
  className?: string;
  isRequired?: boolean;
  isError?: boolean;
  labelItalic?: boolean;
  [key: string]: any;
  isTooltip?: boolean;
  tooltipId?: string;
  tooltipContent?: string;
}

const FormControl = ({
  label,
  type = "text",
  placeholder,
  tooltip,
  isRequired,
  isError,
  name,
  value,
  helperText,
  error,
  onChange,
  className,
  disabled,
  labelItalic,
  isTooltip = false,
  tooltipId,
  tooltipContent,
  ...props
}: IFormControl) => {
  const [show, setShow] = useState(false);
  const target = useRef<HTMLSpanElement>(null);
  const [tooltipPlacement, setTooltipPlacement] = useState<any>("right");
  const handleResize = () => {
    const newPlacement = window.innerWidth < 768 ? "top" : "right";
    setTooltipPlacement(newPlacement);
  };
  const handleDocumentClick = (event: MouseEvent) => {
    if (target.current && !target.current.contains(event.target as Node)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      document.removeEventListener("click", handleDocumentClick);
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Form.Group className={classNames("form-group", className)}>
      {label && (
        <Form.Label
          className={classNames("fs-12 fw-medium", {
            "fst-italic": labelItalic,
          })}
        >
          {label}
          {isRequired && <span className="text-danger">*</span>}

          {isTooltip && (
            <>
              <span
                ref={target}
                onClick={() => setShow(!show)}
                className="tooltip-icon"
              >
                i
              </span>
              <Overlay
                target={target.current}
                show={show}
                placement={tooltipPlacement}
              >
                {(props) => (
                  <Tooltip id={tooltipId} {...props}>
                    {tooltipContent}
                  </Tooltip>
                )}
              </Overlay>
            </>
          )}
        </Form.Label>
      )}
      <Form.Control
        className={classNames({
          "is-invalid": isError,
        })}
        type={type}
        disabled={disabled}
        name={name}
        value={value}
        placeholder={placeholder}
        required={isRequired}
        onChange={onChange}
        {...props}
      />
      {helperText && !isError && (
        <div className="helper-text-style">{helperText}</div>
      )}
      {isError && (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default FormControl;
