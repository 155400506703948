import { useCallback, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { EMPTY_ERROR, MiniLeagues } from "../../../../../common/constants";
import { AddMiniLeagueInfo } from "./MiniLeagueInfo";
import { FeaturedMatchListTabView } from "./FeaturedMatchListTabView";
import { AddMiniLeagueFooter } from "./Footer";
import ReactToastr from "../../../../../common/components/ReactToaster";
import PrizeConfig from "../../../../../common/components/PrizeConfig";
import { showFailureToaster } from "../../../../../utils/apiUtil";
import { HttpStatus } from "../../../../../utils/constants";
import { getMiniLeagueById } from "../../service";
import "./style.scss";
import { FutureMatchTab } from "./FutureMatchTabView";
export const AddMiniLeague = () => {
  const { miniLeagueId: routeMiniLeagueId } = useParams();
  const [miniLeagueId, setMiniLeagueId] = useState(null);
  const [isImageUpload, setIsImageUpload] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [leagueName, setLeagueName] = useState("");
  const [kickOffMatch, setKickOffMatch] = useState(null);
  const [uniqueCode, setUniqueCode] = useState("");
  const [joinLeagueUrl, setJoinLeagueUrl] = useState("");
  const [isEditingLeague, setIsEditingLeague] = useState(false);
  const [reloadMiniLeagueById, setReloadMiniLeagueById] = useState(false);
  const [isMiniLeaguePublished, setIsMiniLeaguePublished] = useState(false);
  const [leaguePrizeList, setLeaguePrizeList] = useState([]);
  const [managerPrizeList, setManagerPrizeList] = useState([]);
  const [miniLeagueData, setMiniLeagueData] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [addMiniLeagueInfoError, setAddMiniLeagueInfoError] = useState({
    leagueName: { error: false, errorMsg: "" },
    kickOffMatch: { error: false, errorMsg: "" },
    uniqueCode: { error: false, errorMsg: "" },
  });
  const [activeTab, setActiveTab] = useState<number>(2);
  const isManagerPrizeExist = managerPrizeList?.length > 0;
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
    if (showToaster?.callBack) {
      showToaster.callBack();
    }
  };

  const handleFetchingMiniLeagueById = useCallback(async () => {
    setMiniLeagueData((prev) => ({
      ...prev,
      isLoading: true,
      error: EMPTY_ERROR,
    }));
    const resData: any = await getMiniLeagueById({
      miniLeagueId: miniLeagueId,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      const leagueData = resData?.data?.data;
      setLeagueName(leagueData?.name ?? "");
      setKickOffMatch(leagueData?.match ?? null);
      setUniqueCode(leagueData?.uniqueCode ?? "");
      setJoinLeagueUrl(leagueData?.link ?? "");
      setLeaguePrizeList(leagueData?.prizeConfig ?? []);
      setManagerPrizeList(leagueData?.matchPrizeConfig ?? []);
      setImageUrl(leagueData?.imageUrl ?? null);
      setIsMiniLeaguePublished(leagueData?.published ?? false);
      setActiveTab(1);
      setMiniLeagueData((prev) => ({
        ...prev,
        isLoading: false,
        data: leagueData,
        error: EMPTY_ERROR,
      }));
    } else {
      setShowToaster(showFailureToaster(resData?.message));
      setMiniLeagueData((prev) => ({
        ...prev,
        isLoading: false,
        data: null,
        error: { errorMsg: resData?.message, isError: true },
      }));
    }
  }, [
    miniLeagueId,
    setLeagueName,
    setKickOffMatch,
    setUniqueCode,
    setLeaguePrizeList,
    setManagerPrizeList,
    setShowToaster,
  ]);

  useEffect(() => {
    // setting the miniLeagueId when we are editing the league and have miniLeagueId in route
    if (routeMiniLeagueId !== MiniLeagues.newLeaguePathId) {
      setIsEditingLeague(true);
      setMiniLeagueId(routeMiniLeagueId);
    }
  }, [routeMiniLeagueId]);

  useEffect(() => {
    if (miniLeagueId) handleFetchingMiniLeagueById();
  }, [handleFetchingMiniLeagueById, miniLeagueId, reloadMiniLeagueById]);

  return (
    <>
      <div className="add-mini-league-header">
        <AddMiniLeagueInfo
          leagueName={leagueName}
          setLeagueName={setLeagueName}
          kickOffMatch={kickOffMatch}
          uniqueCode={uniqueCode}
          setUniqueCode={setUniqueCode}
          joinLeagueUrl={joinLeagueUrl}
          isMiniLeaguePublished={isMiniLeaguePublished}
          isEditingLeague={isEditingLeague}
          addMiniLeagueInfoError={addMiniLeagueInfoError}
          setAddMiniLeagueInfoError={setAddMiniLeagueInfoError}
          error={miniLeagueData.error.isError}
          errorMsg={miniLeagueData.error.errorMsg}
          isImageUpload={isImageUpload}
          setIsImageUpload={setIsImageUpload}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
          oldImage={miniLeagueData?.data?.imageUrl}
          setShowToaster={setShowToaster}
        />
      </div>
      <div className="tab-wrapper config-mini-league-tabs">
        <Tabs
          defaultActiveKey={2}
          id="addMiniLeaguesTab"
          className="mb-3 add-miniLeagues-tabs"
          activeKey={activeTab}
          transition={false}
          onSelect={(key: any) => setActiveTab(key)}
        >
          {isEditingLeague && (
            <Tab
              eventKey={1}
              title={MiniLeagues.featured.featuredMatch}
              id="featured-matches-tab"
              tabClassName="add-miniLeagues-tab-content"
            >
              <FutureMatchTab
                futureMatchList={miniLeagueData?.data?.featuredMatches}
                isLoading={miniLeagueData.isLoading}
                isError={miniLeagueData.error.isError}
                errorMsg={miniLeagueData.error.errorMsg}
              />
            </Tab>
          )}
          <Tab
            eventKey={2}
            title={MiniLeagues.featured.kickOffMatch}
            id="special-matches-tab"
            tabClassName="add-miniLeagues-tab-content"
          >
            <FeaturedMatchListTabView
              kickOffMatch={kickOffMatch}
              setKickOffMatch={setKickOffMatch}
              setAddMiniLeagueInfoError={setAddMiniLeagueInfoError}
              setShowToaster={setShowToaster}
            />
          </Tab>
          <Tab
            eventKey={3}
            title={MiniLeagues.featured.leaguePrize}
            tabClassName="add-miniLeagues-tab-content"
          >
            <PrizeConfig
              isLoading={miniLeagueData.isLoading}
              isError={miniLeagueData.error.isError}
              errorMsg={miniLeagueData.error.errorMsg}
              prizeList={leaguePrizeList}
              setPrizeList={setLeaguePrizeList}
              isReadOnly={isMiniLeaguePublished}
              isDisabledDeleteRow={isMiniLeaguePublished}
              setShowToaster={setShowToaster}
            />
          </Tab>
          <Tab
            eventKey={4}
            title={MiniLeagues.featured.managerPrize}
            tabClassName="add-miniLeagues-tab-content"
          >
            <PrizeConfig
              isLoading={miniLeagueData.isLoading}
              isError={miniLeagueData.error.isError}
              errorMsg={miniLeagueData.error.errorMsg}
              prizeList={managerPrizeList}
              setPrizeList={setManagerPrizeList}
              isReadOnly={isManagerPrizeExist || isMiniLeaguePublished}
              canSetDefaultToAndFromValue={true}
              isDisabledDeleteRow={isMiniLeaguePublished}
              setShowToaster={setShowToaster}
              isFromFieldDisabled={true}
              isToFieldDisabled={true}
            />
          </Tab>
        </Tabs>
      </div>
      <AddMiniLeagueFooter
        kickOffMatch={kickOffMatch}
        setShowToaster={setShowToaster}
        isEditingLeague={isEditingLeague}
        leagueName={leagueName}
        uniqueCode={uniqueCode}
        managerPrizeList={managerPrizeList}
        leaguePrizeList={leaguePrizeList}
        addMiniLeagueInfoError={addMiniLeagueInfoError}
        setAddMiniLeagueInfoError={setAddMiniLeagueInfoError}
        miniLeagueId={miniLeagueId}
        isMiniLeaguePublished={isMiniLeaguePublished}
        setIsMiniLeaguePublished={setIsMiniLeaguePublished}
        imageUrl={imageUrl}
        isImageUpload={isImageUpload}
        isLoading={miniLeagueData.isLoading}
        reloadMiniLeagueById={reloadMiniLeagueById}
        setReloadMiniLeagueById={setReloadMiniLeagueById}
      />
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleClose}
        position="top-center"
        autohide
      />
    </>
  );
};
