import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  Action,
  EMPTY_ERROR,
  RejectionTypeOption,
  UserIdentity,
  UserIdentityApprovalType,
  UserIdentityStatusEnum,
} from "../../../common/constants";
import LoadingUserDetail from "./LoadingUserDetail";
import FormCheckbox from "../../../common/components/FormCheckbox/FormCheckbox";
import FormSelect from "../../../common/components/FormSelect/FormSelect";
import { updateUserIdentityStatusApi } from "../../service";
import { CustomStatusCode, HttpStatus } from "../../../utils/constants";
import { getCommonError } from "../../../common/message";
import ConfirmationModal from "../../../common/components/Modal/ConfirmationModel";
import { showFailureToaster, showSuccessToaster } from "../../../utils/apiUtil";

const UserDocumentActionView = ({
  userIdentityDetail,
  setShowToaster,
  handleReload,
}) => {
  const [isApproving, setIsApproving] = useState<boolean>(false);
  const [isRejecting, setIsRejecting] = useState<boolean>(false);
  const [modelType, setModelType] = useState(null);
  const [rejectionType, setRejectionType] = useState(null);
  const [error, setError] = useState(EMPTY_ERROR);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [approvalTypeAction, setApprovalTypeAction] =
    useState<UserIdentityApprovalType>(UserIdentityApprovalType.APPROVE);
  const updateUserIdentityStatus = async (isApproveAction) => {
    isApproveAction ? setIsApproving(true) : setIsRejecting(true);
    const payload = {
      status: isApproveAction
        ? UserIdentityApprovalType.APPROVE
        : UserIdentityApprovalType.REJECT,
    };
    if (!isApproveAction) payload["rejectionType"] = rejectionType?.value;
    const resData: any = await updateUserIdentityStatusApi(payload, {
      id: userIdentityDetail?.data?.id,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setShowToaster(
        showSuccessToaster(
          `${
            resData?.message || isApproveAction
              ? UserIdentity.successfullApproveUserMsg
              : UserIdentity.successfullRejectUserMsg
          }`,
          () => handleReload(),
        ),
      );
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
    setModelType(null);
    isApproveAction ? setIsApproving(false) : setIsRejecting(false);
  };
  const handleStatusUpdate = (modelType) => {
    setShowConfirmationModal(true);
    setModelType(modelType);
  };
  const handleModalClose = () => {
    setShowConfirmationModal(false);
    setModelType(null);
  };
  return (
    <>
      <Row>
        <Col>
          <div className="mt-2">
            <label className="d-block fw-light fs-12">
              {UserIdentity.rejectedReason}
            </label>
            <span className="d-block  fw-medium text-capitalize mb-3">
              <LoadingUserDetail isLoading={userIdentityDetail?.isLoading}>
                {userIdentityDetail?.data?.rejectedReason ??
                  UserIdentity.noValue}
              </LoadingUserDetail>
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mt-2">
            <label className="d-block fw-light fs-12">
              {UserIdentity.reviewReason}
            </label>
            <span className="d-block  fw-medium text-capitalize mb-3">
              <LoadingUserDetail isLoading={userIdentityDetail?.isLoading}>
                {userIdentityDetail?.data?.reviewReason ?? UserIdentity.noValue}
              </LoadingUserDetail>
            </span>
          </div>
        </Col>
      </Row>
      {userIdentityDetail?.data?.status ===
        UserIdentityStatusEnum.REVIEWBYADMIN && (
        <>
          <div className="mb-3 d-flex">
            <FormCheckbox
              type="radio"
              label={UserIdentity.approveRadioLabel}
              checked={approvalTypeAction === UserIdentityApprovalType.APPROVE}
              onChange={(e: any) =>
                setApprovalTypeAction(UserIdentityApprovalType.APPROVE)
              }
            />
            <FormCheckbox
              className="reject-radio-type"
              type="radio"
              label={UserIdentity.rejectRadioLabel}
              checked={approvalTypeAction === UserIdentityApprovalType.REJECT}
              onChange={() =>
                setApprovalTypeAction(UserIdentityApprovalType.REJECT)
              }
            />
          </div>
          {approvalTypeAction === UserIdentityApprovalType.REJECT && (
            <>
              <FormSelect
                className="reject-select-wrapper"
                options={RejectionTypeOption}
                value={rejectionType}
                onChange={(e) => {
                  setRejectionType(e);
                  setError(EMPTY_ERROR);
                }}
                isRequired={true}
                isError={error?.isError}
                error={error?.errorMsg}
              />
              <span className="edit-referral-button-style">
                <Button
                  variant="primary"
                  disabled={
                    isApproving ||
                    isRejecting ||
                    userIdentityDetail?.data?.status !==
                      UserIdentityStatusEnum.REVIEWBYADMIN
                  }
                  onClick={() => {
                    if (!rejectionType)
                      setError({
                        isError: true,
                        errorMsg: UserIdentity.emptyRejectTypeError,
                      });
                    else if (
                      !isApproving &&
                      !isRejecting &&
                      userIdentityDetail?.data?.status ===
                        UserIdentityStatusEnum.REVIEWBYADMIN
                    ) {
                      handleStatusUpdate(UserIdentityApprovalType.REJECT);
                    }
                  }}
                >
                  {isRejecting ? Action.rejecting : Action.reject}
                </Button>
              </span>
            </>
          )}
          {approvalTypeAction === UserIdentityApprovalType.APPROVE && (
            <span>
              <Button
                variant="primary"
                disabled={
                  isApproving ||
                  isRejecting ||
                  userIdentityDetail?.data?.status !==
                    UserIdentityStatusEnum.REVIEWBYADMIN
                }
                onClick={() => {
                  if (
                    !isApproving &&
                    !isRejecting &&
                    userIdentityDetail?.data?.status ===
                      UserIdentityStatusEnum.REVIEWBYADMIN
                  ) {
                    handleStatusUpdate(UserIdentityApprovalType.APPROVE);
                  }
                }}
              >
                {isApproving ? Action.approving : Action.approve}
              </Button>
            </span>
          )}
        </>
      )}
      <ConfirmationModal
        variant="alert"
        show={showConfirmationModal}
        title={() =>
          modelType === UserIdentityApprovalType.APPROVE
            ? UserIdentity.approveConfirmationMessage(userIdentityDetail?.data)
            : UserIdentity.rejectConfirmationMessage(userIdentityDetail?.data)
        }
        handleClick={() => {
          setShowConfirmationModal(false);
          updateUserIdentityStatus(
            modelType === UserIdentityApprovalType.APPROVE,
          );
        }}
        handleClose={handleModalClose}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={handleModalClose}
        btnText="Confirm"
      />
    </>
  );
};

export default UserDocumentActionView;
