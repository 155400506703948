import React from "react";
import TableWrapper from "../../../../../../common/components/ReactTable/TableWrapper";
import ReactTable from "../../../../../../common/components/ReactTable/ReactTable";
import { MiniLeagues } from "../../../../../../common/constants";
import { TrueFalseIconViewer } from "../../../../../../common/components/TrueFalseIconViewer";

export const FutureMatchTab = ({
  futureMatchList,
  isLoading,
  isError,
  errorMsg,
}) => {
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "teamId1.name",
        id: "team1Id",
        header: () => <span>{MiniLeagues.featured.team1}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "teamId2.name",
        id: "team2Id",
        header: () => <span>{MiniLeagues.featured.team2}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "id",
        id: "matchId",
        header: () => <span>{MiniLeagues.featured.matchId}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "localStartDate",
        id: "localStartDate",
        header: () => <span>{MiniLeagues.featured.startDate}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "localStartTime",
        id: "localStartTime",
        header: () => <span>{MiniLeagues.featured.startTime}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "published",
        id: "published",
        header: () => <span>{MiniLeagues.featured.published}</span>,
        cell: (info) => <TrueFalseIconViewer value={info?.getValue()} />,
        canSort: false,
      },
    ],
    [],
  );

  return (
    <>
      <TableWrapper>
        <ReactTable
          isLoading={isLoading}
          data={futureMatchList || []}
          hasError={isError}
          errorMessage={errorMsg}
          columns={columns}
        />
      </TableWrapper>
    </>
  );
};
