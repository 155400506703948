import { Col, Form, Row } from "react-bootstrap";
import {
  RewardCategoryEnum,
  RewardConfigType,
  RewardCurrencyEnum,
} from "../../types";
import Select from "react-select";
import { categoryOptions, currencyOptions, Option } from "./options";
import { GroupBase } from "react-select";
import { ReactComponent as Minus } from "../../../../assets/Minus.svg";
import "./index.scss";

interface RewardConfigProps {
  rcLength: number;
  rc: RewardConfigType;
  idx: number;
  deleteReward: (idx: number) => void;
  updateReward: (index: number, updates: RewardConfigType) => void;
}

const RewardConfig: React.FC<RewardConfigProps> = ({
  idx,
  rc,
  rcLength,
  updateReward,
  deleteReward,
}) => {
  return (
    <Row className="reward-config">
      <span className="reward-label">Reward {idx + 1}</span>
      {rcLength > 1 && (
        <button className="reward-remove-btn" onClick={() => deleteReward(idx)}>
          <Minus className="text-danger" />
        </button>
      )}

      <Col lg={4}>
        <Form.Control
          type="text"
          placeholder="Amount"
          value={rc.amount}
          className="button-height"
          onChange={(e) => {
            updateReward(idx, {
              amount: Number(e.target.value),
            });
          }}
        />
      </Col>

      <Col lg={4}>
        <Select<
          Option<RewardCategoryEnum>,
          false,
          GroupBase<Option<RewardCategoryEnum>>
        >
          placeholder="Category"
          menuPlacement="auto"
          options={categoryOptions}
          className="button-height"
          value={categoryOptions.find((opt) => opt.value === rc.category)}
          onChange={(selected) => {
            updateReward(idx, {
              category: selected.value,
            });
          }}
        />
      </Col>

      <Col lg={4}>
        <Select<
          Option<RewardCurrencyEnum>,
          false,
          GroupBase<Option<RewardCurrencyEnum>>
        >
          placeholder="Currency"
          menuPlacement="auto"
          options={currencyOptions}
          className="button-height"
          value={currencyOptions.find((opt) => opt.value === rc.currency)}
          onChange={(selected) => {
            updateReward(idx, {
              currency: selected.value,
            });
          }}
        />
      </Col>
    </Row>
  );
};

export default RewardConfig;
