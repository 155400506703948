import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { FILTER_CONSTANTS } from "../../constants";

export const DateRangePickerComp = ({
  dateRange,
  dateChangeHandler,
  canClear = true,
  dateRangePlaceholder = FILTER_CONSTANTS.filterByDate,
  onBlur = () => {
    console.log("");
  },
}) => {
  const [dateRangeCus, setDateRange] = useState(dateRange);
  const [startDate, endDate] = dateRangeCus;

  useEffect(() => {
    setDateRange(dateRange);
  }, [dateRange]);
  return (
    <DatePicker
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      placeholderText={dateRangePlaceholder}
      onChange={(update) => {
        setDateRange(update);
        if ((update[0] && update[1]) || (!update[0] && !update[1]))
          dateChangeHandler(update);
      }}
      className="form-control"
      isClearable={canClear}
      showMonthDropdown
      showYearDropdown
      dateFormat={"yyyy-MM-dd"}
      onBlur={onBlur}
    />
  );
};
