import React, { useState } from "react";
import ConfirmationModal from "../../../common/components/Modal/ConfirmationModel";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import {
  Action,
  Reward,
  UserRewardStatus,
  UserRewardStatusOption,
} from "../../../common/constants";
import { resendEmail } from "../../service";
import { CustomStatusCode, HttpStatus } from "../../../utils/constants";
import { ColumnDef } from "@tanstack/react-table";
import { Button, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { restrictAlpha } from "../../../utils/userUtil";
import FormControl from "../../../common/components/FormControl/FormControl";
import "./style.scss";
import { formatUTCDateToLocalString } from "../../../utils/dateUtils";
import DateRangeFilter from "../../../common/components/DateRangeFilter/DateRangeFilter";
import { staticDateOption } from "../../rewardUtil";
import { showFailureToaster, showSuccessToaster } from "../../../utils/apiUtil";
import { getCommonError } from "../../../common/message";
export const UserRewardsView = ({
  usersReward,
  setUsersRewardCurrentPage,
  setUsersRewardPageSize,
  usersRewardCurrentPage,
  usersRewardPageSize,
  handleUsersRewardPageReload,
  status,
  setStatusFilter,
  setMobileNumber,
  mobileNumber,
  username,
  setUsername,
  handleDateRange,
  handleStaticDateRange,
  dateRange,
  staticDateRange,
  setShowToaster,
}) => {
  const [selectedData, setSelectedData] = useState(null);
  const [isSedingEmail, setIsSendingEmail] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const handleResendEmail = async () => {
    setShowConfirmationModal(false);
    setIsSendingEmail(true);
    const resData: any = await resendEmail({
      id: selectedData?.id,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setIsSendingEmail(false);
      setShowToaster(
        showSuccessToaster("Successfully sending the email", () => {
          handleUsersRewardPageReload();
        }),
      );
      setSelectedData(null);
    } else {
      setIsSendingEmail(false);
      setSelectedData(null);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };

  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: "reward.name",
        id: "brand",
        header: () => <span>{Reward.brand}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "reward.coins",
        id: "coins",
        header: () => <span>{Reward.coins}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "reward.amount",
        id: "amount",
        header: () => <span>{Reward.amount}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "user.username",
        id: "username",
        header: () => <span>{Reward.username}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "emailSent",
        id: "emailSent",
        header: () => <span>{Reward.emailSent}</span>,
        cell: (info) => <span>{info.getValue() ? "Yes" : "No"}</span>,
        canSort: false,
      },
      {
        accessorKey: "status",
        id: "status",
        header: () => <span>{Reward.status}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "error",
        id: "error",
        header: () => <span>{Reward.errorText}</span>,
        cell: (info) =>
          info?.getValue() ? (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{JSON.stringify(info.getValue())}</Tooltip>}
            >
              <div className="error-truncate">
                {JSON.stringify(info.getValue())}
              </div>
            </OverlayTrigger>
          ) : (
            <></>
          ),
        canSort: false,
      },
      {
        accessorKey: "updatedAt",
        id: "updatedAt",
        header: () => <span>{Reward.updatedAt}</span>,
        cell: (info) => formatUTCDateToLocalString(info.getValue()),
        canSort: true,
      },
      {
        accessorKey: "Action",
        id: "action",
        header: () => <span>{Reward.actions}</span>,
        cell: ({ row }) => {
          return (
            <Button
              disabled={
                row?.original?.status?.toLowerCase() !==
                UserRewardStatus.COMPLETED.toLowerCase()
                  ? true
                  : row?.original?.id === selectedData?.id && isSedingEmail
                  ? true
                  : false
              }
              size="sm"
              variant="primary"
              className="resend-button-style"
              onClick={() => {
                setShowConfirmationModal(true);
                setSelectedData(row?.original);
              }}
            >
              {row?.original?.id === selectedData?.id && isSedingEmail
                ? Action.resending
                : Action.resend}
            </Button>
          );
        },
        canSort: false,
      },
    ],
    [isSedingEmail, selectedData],
  );

  return (
    <>
      <Row className="daterange-filter-wrapper">
        <Col>
          <DateRangeFilter
            staticDateOption={staticDateOption}
            dateChangeHandler={handleDateRange}
            staticDateChangeHandler={handleStaticDateRange}
            staticDateRange={staticDateRange}
            dateRange={dateRange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Select
            className="user-reward-small-select-filter"
            placeholder="Filter by status"
            closeMenuOnSelect={true}
            value={status}
            options={UserRewardStatusOption}
            onChange={(e) => {
              setStatusFilter(e);
            }}
          />
        </Col>
        <Col>
          <FormControl
            onChange={(e: any) => {
              if (
                restrictAlpha(e.nativeEvent.data) ||
                !e.nativeEvent.data ||
                e.nativeEvent.data === "+"
              ) {
                setMobileNumber(e.target.value);
              }
            }}
            value={mobileNumber}
            placeholder={"filter by mobile number"}
          />
        </Col>
        <Col>
          <FormControl
            onChange={(e: any) => {
              setUsername(e.target.value);
            }}
            value={username}
            placeholder={"filter by username"}
          />
        </Col>
      </Row>
      <TableWrapper tableClassName="user-reward-table">
        <ReactTable
          isLoading={usersReward?.isLoading}
          data={usersReward?.data?.userRewards || []}
          hasError={usersReward?.error?.isError}
          errorMessage={usersReward?.error?.errorMsg}
          configuration={{
            sorting: [{ id: "updatedAt", desc: true }],
          }}
          columns={columns}
          pageChangeHandler={setUsersRewardCurrentPage}
          pageSizeHandler={setUsersRewardPageSize}
          totalRows={usersReward?.data?.total}
          rowsPerPage={usersRewardPageSize}
          currentPageNumber={usersRewardCurrentPage}
        />
      </TableWrapper>
      <ConfirmationModal
        variant="alert"
        show={showConfirmationModal}
        title={`Are you sure you want to send voucher on email for brand ${selectedData?.reward?.name} having ${selectedData?.reward?.coins} coins and ${selectedData?.reward?.amount} amount?`}
        handleClick={() => {
          selectedData?.status?.toLowerCase() ===
            UserRewardStatus.COMPLETED.toLowerCase() &&
            selectedData?.id &&
            handleResendEmail();
        }}
        handleClose={() => setShowConfirmationModal(false)}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={() => setShowConfirmationModal(false)}
        btnText="Ok"
      />
    </>
  );
};
