// src/components/Counter.js
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { increment, decrement, selectCount } from "../../redux/counterSlice";
import { getTodoList } from "../service";
import "./style.scss";

export const Counter = () => {
  const count = useSelector(selectCount);
  const [apiData, setApiData] = useState<any>("");
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTodoList = async () => {
      const resData = await getTodoList();
      setApiData(resData?.data);
    };
    fetchTodoList();
  }, []);

  return (
    <div>
      <div className="counter-wrapper">
        <div>Dynamic data {apiData ? "" : "Loading..."}</div>
        {apiData && <div>Name: {apiData && apiData?.title}</div>}
        <button onClick={() => dispatch(increment())}>+</button>
        <span>{count}</span>
        <button onClick={() => dispatch(decrement())}>-</button>
      </div>
    </div>
  );
};

export default Counter;
