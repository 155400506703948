import { useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import "./sidebar.scss";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { CENTRAL_ADMIN } from "../menu";
import { adminRole } from "../../common/constants";
import { ArrowDown, ArrowLeft, ArrowUp } from "../../common/SVG";

interface ISidebar {
  isCollapsableSidebar?: boolean;
}

const ApplicationSidebar = ({ isCollapsableSidebar = false }: ISidebar) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const location = useLocation();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const getRoleBasedMenu = () => {
    switch (adminRole) {
      case "CENTRAL_ADMIN":
        return CENTRAL_ADMIN;
    }
  };

  const handleMenuClick = (menuName) => {
    setActiveMenu((prevActiveMenu) =>
      prevActiveMenu === menuName ? null : menuName,
    );
  };

  const isActiveRoute = (currentLocation: string, route: string) => {
    if (currentLocation && route) {
      return currentLocation.split("/")[1] === route.split("/")[1];
    }
    return false;
  };

  const sidebarClass = isCollapsableSidebar
    ? "collapsable-sidebar"
    : "application-sidebar";
  return (
    <aside className={`sidebar ${sidebarClass} ${isSidebarOpen ? "open" : ""}`}>
      <span onClick={toggleSidebar} className="toggle-button">
        <ArrowLeft />
      </span>
      <Navbar className="d-flex flex-column overflow-auto pt-0 h-100">
        {getRoleBasedMenu()?.map((menu, index) => {
          return (
            <Nav
              key={`menu-${index}`}
              className={classNames(
                `nav-item ${menu.name.replaceAll(" ", "-").toLowerCase()}`,
                {
                  "sub-menu": menu.subMenu.length > 0,
                  active: menu.route.length
                    ? isActiveRoute(location.pathname, menu.route)
                    : activeMenu === menu.name ||
                      menu.subMenu.find((m) => m.route === location.pathname),
                },
              )}
              onClick={() => handleMenuClick(menu.name)}
            >
              <Link
                to={menu.route.length > 0 ? menu.route : "#"}
                className={classNames({
                  active: isActiveRoute(location.pathname, menu.route),
                })}
              >
                <div>
                  {/* <span className="menu-icon">
                                        {menu.svg}
                                    </span> */}
                  <span className="menu-name">{menu.name}</span>
                </div>
                {menu.subMenu.length > 0 && (
                  <span className="submenu-arrow">
                    {activeMenu === menu.name ||
                    menu.subMenu.find((m) => m.route === location.pathname) ? (
                      <ArrowUp />
                    ) : (
                      <ArrowDown />
                    )}
                  </span>
                )}
              </Link>

              {menu.subMenu.length > 0 &&
                (activeMenu === menu.name ||
                  menu.subMenu.find((m) => m.route === location.pathname)) && (
                  <ul className="submenu-list">
                    {menu.subMenu.map((subMenu, index) => {
                      return (
                        <li key={`subMenu-${index}`}>
                          <Link
                            to={subMenu.route}
                            className={classNames({
                              "fw-bold": location.pathname === subMenu.route,
                              "pe-none opacity-50": subMenu.disabled,
                            })}
                          >
                            {subMenu.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                )}
            </Nav>
          );
        })}
      </Navbar>
    </aside>
  );
};

export default ApplicationSidebar;
