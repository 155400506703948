import { useState } from "react";
import { Link } from "react-router-dom";

import { Button, Container } from "react-bootstrap";
import "./header.scss";
import UserProfile from "./UserProfile";
import { isUserLoggedIn } from "../../utils/userUtil";
import FUTY_ICON from "../../common/assets/FUTY_ICON.svg";
import { Action, HEADER } from "../../common/constants";
import { CustomStatusCode, HttpStatus } from "../../utils/constants";
import ReactToastr from "../../common/components/ReactToaster";
import { getCommonError } from "../../common/message";
import { clearCacheApi } from "../../Login/service";

const Header = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const userLoggedIn = isUserLoggedIn();
  const [isClearingCache, setIsClearingCache] = useState(false);
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };
  const handleClearCache = async () => {
    setIsClearingCache(true);
    const resData: any = await clearCacheApi();
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setIsClearingCache(false);
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: resData?.message || HEADER.messages.successfullyClearCache,
        variant: "success",
      }));
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: getCommonError(resData?.message),
          variant: "danger",
        }));
    }
    setIsClearingCache(false);
  };

  return (
    <header className="border app-header">
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center">
          <Link to="/">
            <img className="futy-logo-style" src={FUTY_ICON} alt="FUTY" />
          </Link>
          <div className="d-flex align-items-center">
            <Button
              className="clear-cache"
              variant="primary"
              disabled={isClearingCache}
              onClick={() => !isClearingCache && handleClearCache()}
            >
              {isClearingCache ? Action.clearingCache : Action.clearCache}
            </Button>
            {userLoggedIn ? (
              <UserProfile />
            ) : (
              <Button
                variant="link"
                className="hamburger d-lg-none"
                onClick={() => setShowMobileMenu(true)}
              >
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
              </Button>
            )}
          </div>
        </div>
      </Container>
      {/* <Offcanvas
                className="mobile-menu"
                show={showMobileMenu}
                placement="end"
                onHide={() => setShowMobileMenu(false)}
                {...props}
            >
                <Offcanvas.Header closeButton></Offcanvas.Header>
                <Offcanvas.Body>
                    <Navbar className="d-flex flex-column overflow-auto align-items-start">
                        <Nav className="nav-item text-white home mb-3">
                            <Link to="/dashboard"> Home</Link>
                        </Nav>
                        <Nav className="nav-item text-white ticket mb-3">
                            <Link to="/add-student">Ticket</Link>
                        </Nav>
                        <Nav className="nav-item text-white venue mb-3">
                            <Link to="/assessor">Venue</Link>
                        </Nav>
                        <Nav className="nav-item text-white users mb-3">
                            <Link to="/view-test">Users</Link>
                        </Nav>
                        <Nav className="nav-item text-white settings mb-3">
                            <Link to="/">Settings</Link>
                        </Nav>
                    </Navbar>
                </Offcanvas.Body>
            </Offcanvas> */}
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleClose}
        position="top-center"
        autohide
      />
    </header>
  );
};

export default Header;
