import React, { useState } from "react";
import ConfirmationModal from "../../../../common/components/Modal/ConfirmationModel";
import ReactToastr from "../../../../common/components/ReactToaster";
import { MatchNotification } from "../../../../common/constants";
import { CustomStatusCode, HttpStatus } from "../../../../utils/constants";
import { sendMatchPushNotification } from "../../../service";
import PushNotificationView from "../../../../common/components/PushNotification";
const initialErrorState = {
  titleError: { error: false, errorMsg: "" },
  messageError: { error: false, errorMsg: "" },
};
export const MatchPushNotification = ({ matchId }) => {
  const [title, setTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState(initialErrorState);
  const [isSendPushNotification, setIsSendPushNotification] =
    useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  const handleMatchPushNotification = async () => {
    setIsSendPushNotification(true);
    const resData: any = await sendMatchPushNotification({
      matchId: matchId,
      title: title,
      message,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: resData?.message || "Successfully sending the notification",
        variant: "success",
      }));
      setTitle("");
      setMessage("");
      setIsSendPushNotification(false);
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message || "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
      setIsSendPushNotification(false);
    }
  };

  return (
    <div>
      <PushNotificationView
        error={error}
        isSendPushNotification={isSendPushNotification}
        setShowConfirmationModal={setShowConfirmationModal}
        message={message}
        title={title}
        setMessage={setMessage}
        setTitle={setTitle}
        messageMaxChar={MatchNotification.messageMaxChar}
        titleMaxChar={MatchNotification.titleMaxChar}
        setError={setError}
      />
      <ConfirmationModal
        variant="alert"
        show={showConfirmationModal}
        title={`Are you sure, you want to send Notification?`}
        handleClick={() => {
          setShowConfirmationModal(false);
          handleMatchPushNotification();
        }}
        handleClose={() => setShowConfirmationModal(false)}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={() => setShowConfirmationModal(false)}
        btnText="Ok"
      />
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleClose}
        position="top-center"
        autohide
      />
    </div>
  );
};
