import { Match, TransferTypeEnum } from "../../../../common/constants";

const getUnpublishedPlayerMessage = (players) => {
  const unpublishedPlayerNames = players.map((player) => player.name);
  if (unpublishedPlayerNames.length === 0) {
    return { message: "" };
  } else if (unpublishedPlayerNames.length === 1) {
    return {
      message: Match.unPublished.unpublishedPlayerMessage(
        unpublishedPlayerNames[0],
      ),
    };
  } else {
    return {
      message: Match.unPublished.unpublishedPlayersMessage(
        unpublishedPlayerNames.join(", "),
      ),
    };
  }
};

export const getTransferedUnpublishedPlayer = (transferedPlayers = []) => {
  const unpublishedPlayers = transferedPlayers.filter(
    (player) => player.transfer === TransferTypeEnum.IN && !player.published,
  );
  return getUnpublishedPlayerMessage(unpublishedPlayers);
};

export const getUnpublishedPlayer = (allPlayers = []) => {
  const unpublishedPlayers = allPlayers.filter((player) => !player.published);
  return getUnpublishedPlayerMessage(unpublishedPlayers);
};
