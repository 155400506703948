import React, { useEffect, useState } from "react";
import Typography from "../../../common/components/Typography";
import { Button, Col, Row, Form as BsForm } from "react-bootstrap";
import Select from "react-select";
import {
  initialCreateTaskValues,
  taskGroups,
  TaskGroupsEnum,
  createTaskValidationSchema,
  initialTasksValue,
  getTodayTomorrowRange,
  mapDailyTaskData,
  mapMilestoneTaskData,
  formatFormData,
  navigateTaskPageUrlMap,
} from "../../tasksUtils";
import { Field, FieldArray, Formik, Form } from "formik";
import TaskFields from "../TaskFields";
import { DateRangePickerComp } from "../../../common/components/DateRangePickerComp";
import ReactToastr from "../../../common/components/ReactToaster";
import { createTask, updateTask } from "../../service";
import { HttpStatus } from "../../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { Action } from "../../../common/constants";
import { TASK } from "../../tasksUtils";

const TaskForm = () => {
  const location = useLocation();
  const [initialTaskFormValues, setInitialTaskFormValues] = useState(
    initialCreateTaskValues,
  );
  const { edit, task } = location?.state ?? {};
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (edit && task) {
      if (task?.taskGroup === TaskGroupsEnum.MILESTONE) {
        setInitialTaskFormValues(mapMilestoneTaskData(task));
      } else {
        setInitialTaskFormValues(mapDailyTaskData(task));
      }
    }
  }, [location, edit, task]);

  const handleToasterClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  const handleSubmit = async (values: any) => {
    const mappedFormData = formatFormData(values);
    try {
      let res;
      if (edit) {
        res = await updateTask(task?.id, mappedFormData);
      } else {
        res = await createTask(mappedFormData);
      }
      if (
        res?.status === HttpStatus.REQUEST_SUCCEEDED ||
        res?.status === HttpStatus.RESOURCE_CREATED
      ) {
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: edit ? "Task updated" : "Task Created",
          variant: "success",
        }));
        const navigationUrl = navigateTaskPageUrlMap[mappedFormData.taskGroup];
        setTimeout(() => {
          navigate(navigationUrl);
        }, 700);
      } else {
        throw Error(res?.message ?? "Something went wrong");
      }
    } catch (error) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: error?.message,
        variant: "danger",
      }));
    }
  };

  const generateTasks = (count: number) => {
    const taskArray = [...initialTaskFormValues?.tasks];
    if (count > taskArray.length) {
      const diff = count - taskArray.length;
      return [
        ...taskArray,
        ...Array.from({ length: diff }, (_, index) => ({
          ...initialTasksValue,
          tierNumber: taskArray.length + index + 1,
        })),
      ];
    } else if (count < taskArray.length) {
      const diff = taskArray.length - count;
      return taskArray.slice(0, taskArray.length - diff);
    } else return taskArray;
  };

  return (
    <div>
      <Button
        className="d-flex align-items-start px-0"
        variant="link"
        onClick={() => navigate(-1)}
      >
        {Action.goBack}
      </Button>
      <Typography variant="pageTitle">Create Task</Typography>
      <section className="mt-2">
        <Row>
          <Formik
            initialValues={initialTaskFormValues}
            validationSchema={createTaskValidationSchema}
            validateOnBlur={true}
            validateOnChange={false}
            enableReinitialize={true}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({
              errors,
              touched,
              values,
              setValues,
              setFieldValue,
              setFieldError,
              setFieldTouched,
            }) => {
              const handleDateChange = (date) => {
                setFieldTouched("duration", true);
                setFieldValue("duration", {
                  startTime: date[0],
                  endTime: date[1],
                });
              };

              const handleDateBlur = () => {
                if (values.canExpire) {
                  if (!values.duration.startTime) {
                    setFieldError("duration", TASK.validationMessage.duration);
                  } else {
                    setFieldError("duration", undefined);
                  }
                }
              };

              return (
                <Form>
                  <Row>
                    <Col md={6}>
                      <Select
                        placeholder="Select task group"
                        options={taskGroups}
                        name="taskGroup"
                        value={taskGroups.find(
                          (option) => option.value === values.taskGroup,
                        )}
                        onChange={(option) => {
                          setFieldValue("taskGroup", option.value);
                          if (option.value === TaskGroupsEnum.DAILY_TASK) {
                            setFieldValue("canExpire", true);
                          } else {
                            setFieldValue("canExpire", false);
                          }
                          if (option.value !== TaskGroupsEnum.MILESTONE) {
                            setFieldValue("maximumTier", 1);
                            setFieldValue(
                              "tasks",
                              initialCreateTaskValues.tasks,
                            );
                          }
                          setFieldError("taskGroup", undefined);
                        }}
                      />
                      {errors.taskGroup && touched.taskGroup && (
                        <Typography className="text-danger fs-10">
                          {errors.taskGroup}
                        </Typography>
                      )}
                    </Col>
                  </Row>
                  <Row className="d-flex gap-2 align-items-end my-3">
                    <Col md={4}>
                      <BsForm.Label>Heading</BsForm.Label>
                      <Field
                        className="form-control"
                        name="heading"
                        placeholder="Task heading"
                        disabled={values.taskGroup !== TaskGroupsEnum.MILESTONE}
                      />
                      {errors.heading && touched.heading && (
                        <Typography className="text-danger fs-10">
                          {errors.taskGroup}
                        </Typography>
                      )}
                    </Col>
                    <Col md={4}>
                      <BsForm.Label>Maximum tier</BsForm.Label>
                      <Field
                        placeholder="Maximum tier"
                        type="number"
                        min="1"
                        name="maximumTier"
                        className="form-control"
                        disabled={values.taskGroup !== TaskGroupsEnum.MILESTONE}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = parseInt(e.target.value, 10) || 1;
                          setFieldValue("maximumTier", value);
                          setFieldValue("tasks", generateTasks(value));
                        }}
                      />
                    </Col>
                    <Col md="auto">
                      <BsForm.Check
                        name="canExpire"
                        label={"canExpire"}
                        checked={values.canExpire}
                        disabled={values.taskGroup !== TaskGroupsEnum.MILESTONE}
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleDateChange(getTodayTomorrowRange());
                          }
                          setFieldValue("canExpire", !values.canExpire);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between align-items-center mt-4">
                    {values.canExpire && (
                      <Col md={6}>
                        <label>Duration</label>
                        <DateRangePickerComp
                          dateRange={
                            values?.duration
                              ? [
                                  values?.duration?.startTime,
                                  values?.duration?.endTime,
                                ]
                              : getTodayTomorrowRange()
                          }
                          dateChangeHandler={handleDateChange}
                          onBlur={handleDateBlur}
                        />
                        {errors.duration && touched.duration && (
                          <Typography className="text-danger fs-10">
                            {TASK.validationMessage.duration}
                          </Typography>
                        )}
                      </Col>
                    )}
                  </Row>

                  <FieldArray name="tasks">
                    {({ push, remove }) => (
                      <>
                        {values.tasks.map((task, idx) => (
                          <TaskFields
                            key={idx}
                            idx={idx}
                            task={task}
                            errors={errors}
                            touched={touched}
                            values={values}
                            setValues={setValues}
                            setFieldValue={setFieldValue}
                            setFieldError={setFieldError}
                            setFieldTouched={setFieldTouched}
                            remove={remove}
                            maxTier={values.maximumTier}
                          />
                        ))}
                      </>
                    )}
                  </FieldArray>

                  <Button type="submit" variant="primary" className="w-100">
                    {edit ? "Update Task" : "Create Task"}
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Row>
      </section>
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleToasterClose}
        position="top-center"
        autohide
      />
    </div>
  );
};

export default TaskForm;
