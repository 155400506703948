import { API_METHOD } from "../../api/api";
import { getBase } from "../../apiconfig";
import { URLS } from "../../common/url";
import { handleApiRequest } from "../../utils/apiUtil";

export const fetchLeaguesData = async (config = {}) => {
  const url: string = getBase() + "/api" + URLS.LEAGUES_URL;
  return await handleApiRequest(API_METHOD.GET, url, config);
};

export const fetchFreshLeaguesData = async (config = {}) => {
  const url: string =
    getBase() + "/api" + URLS.LEAGUES_URL + "/updateLeagueTypeAndSeasons";
  return await handleApiRequest(API_METHOD.PUT, url, config);
};

export const populateLeagueAndSeason = async (payload, config = {}) => {
  const { leagueSeasonId } = payload;
  const url: string =
    getBase() +
    "/api" +
    URLS.POPULATE_LEAGUES_SEASON +
    `?seasonId=${leagueSeasonId}`;
  return await handleApiRequest(API_METHOD.GET, url, config);
};

export const refreshPlayerScore = async (payload) => {
  const url: string =
    getBase() + "/api" + URLS.PLAYER_URL + "/updateTotalScoreAndForm";
  return await handleApiRequest(API_METHOD.POST, url, payload);
};
