import React, { useState, useCallback, useEffect } from "react";
import {
  EMPTY_ERROR,
  Notification,
  defaultPageSize,
} from "../../../common/constants";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import { ColumnDef } from "@tanstack/react-table";
import { fetchPushNotification, savePushNotification } from "../../service";
import { CustomStatusCode, HttpStatus } from "../../../utils/constants";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import ConfirmationModal from "../../../common/components/Modal/ConfirmationModel";
import Typography from "../../../common/components/Typography";
import { formatUTCDateToLocalString } from "../../../utils/dateUtils";
import ReactToastr from "../../../common/components/ReactToaster";
import PushNotificationView from "../../../common/components/PushNotification";
const initialErrorState = {
  titleError: { error: false, errorMsg: "" },
  messageError: { error: false, errorMsg: "" },
};
const PushNotification = () => {
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [title, setTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState(initialErrorState);
  const [notificationConfig, setNotificationConfig] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [isSavePushNotification, setIsSavePushNotification] =
    useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  const getNotificationConfig = useCallback(async () => {
    setNotificationConfig((prev) => ({
      ...prev,
      isLoading: true,
      error: EMPTY_ERROR,
    }));
    const resData: any = await fetchPushNotification({
      skip: (currentPage - 1) * pageSize,
      limit: pageSize,
    });

    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setNotificationConfig((pre) => ({
        ...pre,
        isLoading: false,
        data: resData?.data?.data,
      }));
    } else {
      setNotificationConfig((prev) => ({
        ...prev,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, currentPage]);

  const savePushNotificationConfig = async () => {
    setIsSavePushNotification(true);
    const resData: any = await savePushNotification({
      title: title,
      text: message,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message:
          resData?.message ||
          "Successfully submit the push notification details",
        variant: "success",
      }));
      setTitle("");
      setMessage("");
      setIsSavePushNotification(false);
      getNotificationConfig();
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message || "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
      setIsSavePushNotification(false);
    }
  };

  useEffect(() => {
    getNotificationConfig();
  }, [getNotificationConfig]);

  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: "createdAt",
        id: "created-date",
        header: () => <span>{Notification.pushNotify.date}</span>,
        cell: (info) => formatUTCDateToLocalString(info.getValue()),
      },
      {
        accessorKey: "title",
        id: "title",
        header: () => <span>{Notification.pushNotify.title}</span>,
        cell: (info) => info.getValue(),
      },

      {
        accessorKey: "text",
        id: "message-text",
        header: () => <span>{Notification.pushNotify.message}</span>,
        cell: (info) => info.getValue(),
      },
    ],
    [],
  );

  return (
    <>
      <PushNotificationView
        error={error}
        isSendPushNotification={isSavePushNotification}
        setShowConfirmationModal={setShowConfirmationModal}
        header="Push Notification"
        message={message}
        title={title}
        setMessage={setMessage}
        setTitle={setTitle}
        messageMaxChar={Notification.pushNotify.messageMaxChar}
        titleMaxChar={Notification.pushNotify.titleMaxChar}
        setError={setError}
      />
      <Typography variant="pageTitle">
        {Notification.pushNotify.allNotification}
      </Typography>
      <TableWrapper>
        <ReactTable
          isLoading={notificationConfig?.isLoading}
          data={notificationConfig?.data?.notifications || []}
          columns={columns}
          hasError={notificationConfig?.error?.isError}
          errorMessage={notificationConfig?.error?.errorMsg}
          pageChangeHandler={setCurrentPage}
          pageSizeHandler={setPageSize}
          totalRows={notificationConfig?.data?.totalCount}
          rowsPerPage={pageSize}
          currentPageNumber={currentPage}
          configuration={{
            sorting: [],
          }}
        />
      </TableWrapper>
      <ConfirmationModal
        variant="alert"
        show={showConfirmationModal}
        title={`Are you sure, you want to save the details?`}
        handleClick={() => {
          setShowConfirmationModal(false);
          savePushNotificationConfig();
        }}
        handleClose={() => setShowConfirmationModal(false)}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={() => setShowConfirmationModal(false)}
        btnText="Ok"
      />
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleClose}
        position="top-center"
        autohide
      />
    </>
  );
};

export default PushNotification;
