import { useCallback, useEffect, useState } from "react";
import {
  defaultPageSize,
  EMPTY_ERROR,
} from "../../../../../../common/constants";
import { fetchMatchList } from "../../../../../../teamslist/service";
import { HttpStatus } from "../../../../../../utils/constants";
import { FeaturedMatchList } from "./FeaturedMatchList";
import { LeagueSeasonFilter } from "../../../../../../common/components/LeagueSeasonFilter";
import { TeamsFilter } from "../../../../../../common/components/TeamsFilter";
import { getDisplayedDateFromUTC } from "../../../../../../teamslist/component/TeamConfigure/teamConfigHelper";
import { DateRangePickerComp } from "../../../../../../common/components/DateRangePickerComp";
import "./style.scss";
import { getPaginationQuery } from "../../../../../../utils/userUtil";
export const FeaturedMatchListTabView = ({
  kickOffMatch,
  setKickOffMatch,
  setAddMiniLeagueInfoError,
  setShowToaster,
}) => {
  const [filteredLeagueSeason, setFilteredLeagueSeason] = useState(null);
  const [selectedTeams, setSelectedTeams] = useState<string[]>(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [featuredMatch, setFeaturedMatch] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [featuredMatchPagesize, setFeaturedMatchPagesize] =
    useState(defaultPageSize);
  const [featuredMatchCurrentPage, setFeaturedMatchCurrentPage] = useState(1);

  const getFeaturedMatch = useCallback(async () => {
    setFeaturedMatch((prev) => ({
      ...prev,
      isLoading: true,
      error: EMPTY_ERROR,
    }));
    const startDate = getDisplayedDateFromUTC(dateRange?.[0]);
    const endDate = getDisplayedDateFromUTC(dateRange?.[1]);
    const resData: any = await fetchMatchList({
      query: {
        featured: true,
        leagueSeasonId: filteredLeagueSeason?.id,
        teamIds: selectedTeams || [],
        startDate,
        endDate,
      },
      page: getPaginationQuery(featuredMatchCurrentPage, featuredMatchPagesize),
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setFeaturedMatch((prev) => ({
        ...prev,
        isLoading: false,
        data: resData?.data?.data,
      }));
    } else {
      setFeaturedMatch((prev) => ({
        ...prev,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      }));
    }
  }, [
    dateRange,
    selectedTeams,
    filteredLeagueSeason,
    featuredMatchCurrentPage,
    featuredMatchPagesize,
    setFeaturedMatch,
  ]);

  useEffect(() => {
    getFeaturedMatch();
  }, [getFeaturedMatch]);
  return (
    <>
      <LeagueSeasonFilter setFilteredLeagueSeason={setFilteredLeagueSeason} />
      <div className="miniLeague-team-datePicker-wrapper">
        <TeamsFilter
          setSelectedTeams={setSelectedTeams}
          setShowToaster={setShowToaster}
          classNames="small-select-filter mini-league-team-filter"
        />
        <DateRangePickerComp
          dateChangeHandler={(changedDate) => setDateRange(changedDate)}
          dateRange={dateRange}
        />
      </div>

      <FeaturedMatchList
        featuredMatch={featuredMatch}
        featuredMatchPagesize={featuredMatchPagesize}
        setFeaturedMatchPagesize={setFeaturedMatchPagesize}
        featuredMatchCurrentPage={featuredMatchCurrentPage}
        setFeaturedMatchCurrentPage={setFeaturedMatchCurrentPage}
        kickOffMatch={kickOffMatch}
        setKickOffMatch={setKickOffMatch}
        setAddMiniLeagueInfoError={setAddMiniLeagueInfoError}
      />
    </>
  );
};
