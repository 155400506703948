import { Button, Card, CardBody } from "react-bootstrap";
import { Action, EMPTY_ERROR, UserIdentity } from "../../../common/constants";
import { useEffect, useState } from "react";
import { CustomStatusCode, HttpStatus } from "../../../utils/constants";
import { fetchUserIdentityDetail } from "../../service";
import Typography from "../../../common/components/Typography";
import "./style.scss";
import { UserDetailsInfo } from "./UserDetailsInfo";
import UserDocumentActionView from "./UserDocumentActionView";
import { UserDocumentsView } from "./UserDocumentsView";
import BasicError from "../../../common/components/Error/BasicError";
import { getCommonError } from "../../../common/message";
import { useNavigate, useParams } from "react-router-dom";
import ReactToastr from "../../../common/components/ReactToaster";
import { showFailureToaster } from "../../../utils/apiUtil";
export const UserIdentityDetailView = () => {
  const { userIdentityId } = useParams();
  const navigate = useNavigate();
  const [userIdentityDetail, setUserIdentityDetail] = useState({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
    if (showToaster?.callBack) {
      showToaster.callBack();
    }
  };

  const handleReload = () => {
    navigate("/user-identity");
  };
  const getUserIdentityDetail = async () => {
    setUserIdentityDetail((pre) => ({
      ...pre,
      isLoading: true,
      error: EMPTY_ERROR,
    }));
    const resData: any = await fetchUserIdentityDetail({
      id: userIdentityId,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setUserIdentityDetail((pre) => ({
        ...pre,
        isLoading: false,
        data: resData?.data?.data,
      }));
    } else {
      setUserIdentityDetail((pre) => ({
        ...pre,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      }));
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };
  useEffect(() => {
    getUserIdentityDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {" "}
      <div className="user-document-wrapper">
        <div className="go-back-wrapper">
          <Button variant="link" onClick={() => navigate(-1)}>
            {Action.goBack}
          </Button>
          {
            <Typography variant="heading4">{`${UserIdentity.identityId} ${
              userIdentityId ?? ""
            }`}</Typography>
          }
        </div>
        <Card className="card-wrapper">
          <CardBody className="card-body">
            {userIdentityDetail?.error?.isError ? (
              <BasicError
                error={userIdentityDetail?.error?.errorMsg}
                className="py-3"
              />
            ) : (
              <>
                <div className="text-section">
                  <UserDetailsInfo
                    user={userIdentityDetail?.data}
                    userMetadata={{
                      isLoading: userIdentityDetail.isLoading,
                      matchedApplicants:
                        userIdentityDetail?.data?.matchedApplicants,
                      onfidoStatus: userIdentityDetail?.data?.onfidoStatus,
                    }}
                  />
                  <UserDocumentActionView
                    setShowToaster={setShowToaster}
                    userIdentityDetail={userIdentityDetail}
                    handleReload={handleReload}
                  />
                </div>
                <div className="image-section">
                  {!userIdentityDetail.isLoading && (
                    <UserDocumentsView
                      documentImg={userIdentityDetail?.data?.documentIds || []}
                    />
                  )}
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </div>
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleClose}
        position="top-center"
        autohide
      />
    </>
  );
};
