import { createSlice } from "@reduxjs/toolkit";
import { EMPTY_ERROR } from "../../../common/constants";
const initialState = {
  data: null,
  isLoading: false,
  error: EMPTY_ERROR,
};
export const teamsColorConfigSlice = createSlice({
  name: "teamsColorSlice",
  initialState,
  reducers: {
    setTeamsData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetTeamsData: (state) => {
      return { ...state, ...initialState };
    },
  },
});

export const { setTeamsData, resetTeamsData } = teamsColorConfigSlice.actions;
export default teamsColorConfigSlice.reducer;
