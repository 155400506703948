import React, { useState } from "react";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import ConfirmationModal from "../../../common/components/Modal/ConfirmationModel";
import { ColumnDef } from "@tanstack/react-table";
import { Action, Reward } from "../../../common/constants";
import { Button } from "react-bootstrap";
import { CustomStatusCode, HttpStatus } from "../../../utils/constants";
import { deleteAddedReward } from "../../service";
import { showFailureToaster, showSuccessToaster } from "../../../utils/apiUtil";
import { getCommonError } from "../../../common/message";

export const RewardsDashboard = ({
  rewardAndBrand,
  setAllRewardCurrentPage,
  setAllRewardPageSize,
  allRewardCurrentPage,
  allRewardPageSize,
  handlePageReload,
  setShowToaster,
}) => {
  const [selectedData, setSelectedData] = useState(null);
  const [isDeletingReward, setIsDeletingReward] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const handleDeleteReward = async () => {
    setShowConfirmationModal(false);
    setIsDeletingReward(true);
    const resData: any = await deleteAddedReward({
      id: selectedData?.id,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setIsDeletingReward(false);
      setShowToaster(
        showSuccessToaster("Successfully deleting reward", () => {
          handlePageReload();
        }),
      );
      setSelectedData(null);
    } else {
      setIsDeletingReward(false);
      setSelectedData(null);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };
  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: "brand.name",
        id: "brand",
        header: () => <span>{Reward.brand}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "coins",
        id: "coins",
        header: () => <span>{Reward.coins}</span>,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "amount",
        id: "amount",
        header: () => <span>{Reward.amount}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "Action",
        id: "action",
        header: () => <span>{Reward.actions}</span>,
        cell: ({ row }) => {
          return (
            <Button
              disabled={
                row?.original?.id === selectedData?.id && isDeletingReward
                  ? true
                  : false
              }
              size="sm"
              variant="danger"
              onClick={() => {
                setShowConfirmationModal(true);
                setSelectedData(row?.original);
              }}
            >
              {row?.original?.id === selectedData?.id && isDeletingReward
                ? Action?.deleting
                : Action.delete}
            </Button>
          );
        },
        canSort: false,
      },
    ],
    [isDeletingReward, selectedData],
  );

  return (
    <>
      <TableWrapper>
        <ReactTable
          isLoading={rewardAndBrand?.isLoading}
          data={rewardAndBrand?.data?.rewards || []}
          hasError={rewardAndBrand?.error?.isError}
          errorMessage={rewardAndBrand?.error?.errorMsg}
          columns={columns}
          pageChangeHandler={setAllRewardCurrentPage}
          pageSizeHandler={setAllRewardPageSize}
          totalRows={rewardAndBrand?.data?.totalCount}
          rowsPerPage={allRewardPageSize}
          currentPageNumber={allRewardCurrentPage}
        />
      </TableWrapper>
      <ConfirmationModal
        variant="alert"
        show={showConfirmationModal}
        title={`Are you sure you want to delete reward for brand ${selectedData?.brand?.name} having ${selectedData?.coins} coins and ${selectedData?.amount} amount?`}
        handleClick={() => {
          selectedData?.id && handleDeleteReward();
        }}
        handleClose={() => setShowConfirmationModal(false)}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={() => setShowConfirmationModal(false)}
        btnText="Ok"
      />
    </>
  );
};
