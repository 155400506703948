import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Spinner } from "react-bootstrap";
import { Action, Reward } from "../../../common/constants";
import { restrictAlpha } from "../../../utils/userUtil";
import { components } from "react-select";
import FormControl from "../../../common/components/FormControl/FormControl";
import "./style.scss";
import ConfirmationModal from "../../../common/components/Modal/ConfirmationModel";
import { addNewReward, getBrandsList } from "../../service";
import { CustomStatusCode, HttpStatus } from "../../../utils/constants";
import FormSelect from "../../../common/components/FormSelect/FormSelect";
import { showFailureToaster, showSuccessToaster } from "../../../utils/apiUtil";
import { getCommonError } from "../../../common/message";
const initialErrorState = {
  brandError: { error: false, errorMsg: "" },
  amountError: { error: false, errorMsg: "" },
  coinsError: { error: false, errorMsg: "" },
};

const Control = ({ children, ...props }: any) => {
  const selectedVal = props?.getValue()?.[0]?.url;
  return (
    <components.Control {...props}>
      {selectedVal && (
        <img className="selected-option-logo" src={selectedVal} alt="logo" />
      )}
      {children}
    </components.Control>
  );
};

const CustomOption = (props) => {
  return (
    <components.Option {...props} className="option-style">
      {
        <img
          src={props.data?.url}
          alt="logo"
          className="d-inline-block option-list-logo"
        />
      }
      {props.data.label}
    </components.Option>
  );
};

export const AddRewardView = ({
  handleGoBack,
  handleReload,
  setShowToaster,
}) => {
  const [brand, setBrand] = useState(null);
  const [amount, setAmount] = useState("");
  const [coins, setCoins] = useState("");
  const [isAddingReward, setIsAddingReward] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isFetchingBrands, setIsFetchingBrands] = useState(false);
  const [brandsList, setBrandsList] = useState([]);
  const [error, setError] = useState(initialErrorState);
  const handleGettingBrands = async () => {
    setIsFetchingBrands(true);
    const resData: any = await getBrandsList();
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setIsFetchingBrands(false);
      setBrandsList(
        resData?.data?.data?.map((item) => {
          return { ...item, label: item.name, value: item.id };
        }) || [],
      );
    } else {
      setIsFetchingBrands(false);
      setBrandsList([]);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };

  useEffect(() => {
    handleGettingBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddingReward = () => {
    let brandErrorMsg = "";
    let amountErrorMsg = "";
    let coinsErrorMsg = "";
    const intCoins = parseInt(coins);
    const intAmount = parseInt(amount);
    const isCoinNaN = isNaN(intCoins);
    const isAmountNaN = isNaN(intAmount);
    if (!brand) brandErrorMsg = "Please select brand";
    if (!amount || isAmountNaN) amountErrorMsg = "Please enter valid amount";
    if (!coins || isCoinNaN) coinsErrorMsg = "Please enter valid coins";
    if (
      isAmountNaN ||
      intAmount < Reward.minAmount ||
      intAmount > Reward.maxAmount
    )
      amountErrorMsg = `Please enter amount from ${Reward.minAmount} to ${Reward.maxAmount}`;
    if (isCoinNaN || intCoins < Reward.minCoins || intCoins > Reward.maxCoins)
      coinsErrorMsg = `Please enter coins from ${Reward.minCoins} to ${Reward.maxCoins}`;
    if (!brandErrorMsg && !amountErrorMsg && !coinsErrorMsg)
      setShowConfirmationModal(true);
    else {
      setError({
        brandError: { error: !!brandErrorMsg, errorMsg: brandErrorMsg },
        amountError: { error: !!amountErrorMsg, errorMsg: amountErrorMsg },
        coinsError: { error: !!coinsErrorMsg, errorMsg: coinsErrorMsg },
      });
    }
  };

  const handleAddNewReward = async () => {
    setShowConfirmationModal(false);
    setIsAddingReward(true);
    const resData: any = await addNewReward({
      name: brand?.label,
      amount: parseInt(amount),
      coins: parseInt(coins),
      brandId: brand?.value,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setIsAddingReward(false);
      setShowToaster(
        showSuccessToaster("Successfully adding reward", () => {
          handleGoBack();
          handleReload();
        }),
      );
    } else {
      setIsAddingReward(false);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };

  return (
    <div className="add-new-reward-wrapper">
      <Button variant="link" onClick={handleGoBack}>
        {Action.goBack}
      </Button>
      <Card className="bg-white">
        <CardBody>
          <div className="d-flex mb-3">
            <label className="d-block fw-light fs-13 width-25">
              {Reward.brand}
            </label>
            <span className="d-block  fw-medium text-capitalize">
              <FormSelect
                onChange={(e) => {
                  setBrand(e);
                  setError({
                    ...error,
                    brandError: { error: false, errorMsg: "" },
                  });
                }}
                isLoading={isFetchingBrands}
                loadingMessage={() => <Spinner variant="primary" size="sm" />}
                className="mb-3 width-20-rem"
                options={brandsList}
                value={brand}
                isError={error?.brandError?.error}
                error={error?.brandError?.errorMsg}
                components={{ Option: CustomOption, Control: Control }}
              />
            </span>
          </div>
          <div className="d-flex mb-3">
            <label className="d-block fw-light fs-13 width-25">
              {Reward.addReward.amountText}
            </label>
            <FormControl
              className="width-20-rem mb-3"
              onChange={(e: any) => {
                if (restrictAlpha(e.nativeEvent.data) || !e.nativeEvent.data) {
                  setAmount(e.target.value);
                  setError({
                    ...error,
                    amountError: { error: false, errorMsg: "" },
                  });
                }
              }}
              placeholder={Reward.addReward.amountPlaceholder}
              value={amount}
              isRequired={true}
              isError={error?.amountError?.error}
              error={error?.amountError?.errorMsg}
            />
          </div>
          <div className="d-flex mb-3">
            <label className="d-block fw-light fs-13 width-25">
              {Reward.addReward.coinsText}
            </label>
            <FormControl
              className="width-20-rem mb-3"
              onChange={(e: any) => {
                if (restrictAlpha(e.nativeEvent.data) || !e.nativeEvent.data) {
                  setCoins(e.target.value);
                  setError({
                    ...error,
                    coinsError: { error: false, errorMsg: "" },
                  });
                }
              }}
              value={coins}
              isRequired={true}
              placeholder={Reward.addReward.coinsPlaceholder}
              isError={error?.coinsError?.error}
              error={error?.coinsError?.errorMsg}
            />
          </div>
          <div className="add-reward-button">
            <Button onClick={handleAddingReward}>
              {isAddingReward ? Action.adding : Action.add}
            </Button>
          </div>
        </CardBody>
      </Card>
      <ConfirmationModal
        variant="alert"
        show={showConfirmationModal}
        title={`Are you sure you want to add reward for brand ${brand?.label} with amount ${amount} and coins ${coins}?`}
        handleClick={() => {
          handleAddNewReward();
        }}
        handleClose={() => setShowConfirmationModal(false)}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={() => setShowConfirmationModal(false)}
        btnText="Ok"
      />
    </div>
  );
};
