import { MiniLeagueModel, MiniLeagues } from "../../common/constants";
import { isPrizeConfigValid } from "../../teamslist/component/TeamConfigure/teamConfigHelper";
interface MiniLeagueErrorMessages {
  isValidationError: boolean;
  leagueNameError?: string;
  kickOffMatchError?: string;
  uniqueCodeError?: string;
  managerPrizeError?: string;
  leaguePrizeError?: string;
}

export const getKickOffMatchFormat = (matchData): string => {
  if (!matchData) {
    return "";
  }
  const team1ShortCode = matchData?.teamId1?.shortCode || "";
  const team2ShortCode = matchData?.teamId2?.shortCode || "";
  const localStartDate = matchData?.localStartDate || "";
  if (team1ShortCode && team2ShortCode && localStartDate) {
    return `${team1ShortCode} vs ${team2ShortCode} on ${localStartDate}`;
  } else if (team1ShortCode && team2ShortCode)
    return `${team1ShortCode} vs ${team2ShortCode}`;
  else return "";
};

export const getKickOffMatchPayload = (
  isEditingLeague,
  imageUrl,
  leagueName,
  uniqueCode,
  kickOffMatch,
  managerPrizeList,
  leaguePrizeList,
) => {
  const payload = {
    imageUrl: imageUrl,
    name: leagueName,
    kickOffMatchId: kickOffMatch?.id,
    prizeConfig: leaguePrizeList?.sort((a, b) => a?.from - b?.from),
    matchPrizeConfig: managerPrizeList?.sort((a, b) => a?.from - b?.from),
  };
  if (!isEditingLeague) {
    payload["uniqueCode"] = uniqueCode;
  }
  return payload;
};

export const getAddMiniLeagueErrorMessages = (
  isEditingLeague: boolean,
  leagueName: string,
  uniqueCode: string,
  kickOffMatch: string,
  managerPrizeList: any[],
  leaguePrizeList: any[],
): MiniLeagueErrorMessages => {
  let leagueNameError = "";
  let kickOffMatchError = "";
  let uniqueCodeError = "";
  let managerPrizeError = "";
  let leaguePrizeError = "";

  if (!leagueName) {
    leagueNameError = MiniLeagues.messages.leagueNameError;
  }
  if (!kickOffMatch) {
    kickOffMatchError = MiniLeagues.messages.kickOffMatchError;
  }
  if (!isEditingLeague) {
    if (!uniqueCode) uniqueCodeError = MiniLeagues.messages.uniqueCodeError;
    else if (uniqueCode.length < MiniLeagues.uniqueCodeMinLength) {
      uniqueCodeError = MiniLeagues.messages.minUniqueCodeLengthError(
        MiniLeagues.uniqueCodeMinLength,
      );
    } else if (uniqueCode.length > MiniLeagues.uniqueCodeMaxLength) {
      uniqueCodeError = MiniLeagues.messages.maxUniqueCodeLengthError(
        MiniLeagues.uniqueCodeMaxLength,
      );
    }
  }
  if (!managerPrizeList?.length) {
    managerPrizeError = MiniLeagues.messages.managerPrizeError;
  }
  if (!leaguePrizeList?.length) {
    leaguePrizeError = MiniLeagues.messages.leaguePrizeError;
  }
  if (leaguePrizeList?.length) {
    const prizeConfig = isPrizeConfigValid(
      leaguePrizeList?.sort((a, b) => a?.from - b?.from),
    );
    if (!prizeConfig.isValid) {
      leaguePrizeError = prizeConfig.message;
    }
  }
  if (managerPrizeList?.length > MiniLeagues.managerPrizeEntryLimit) {
    managerPrizeError = MiniLeagues.messages.managerPrizeEntryLimitError;
  }

  if (
    leagueNameError ||
    kickOffMatchError ||
    uniqueCodeError ||
    managerPrizeError ||
    leaguePrizeError
  ) {
    return {
      isValidationError: true,
      leagueNameError,
      kickOffMatchError,
      uniqueCodeError,
      managerPrizeError,
      leaguePrizeError,
    };
  } else {
    return {
      isValidationError: false,
    };
  }
};

export const getMiniLeagueConfirmationModelMsg = (modelType) => {
  switch (modelType) {
    case MiniLeagueModel.PUBLISH:
      return MiniLeagues.messages.isConfirmPublish;
    case MiniLeagueModel.UNPUBLISH:
      return MiniLeagues.messages.isConfirmUnpublish;
    case MiniLeagueModel.DISBURSE_PRIZE:
      return MiniLeagues.messages.isConfirmDisbursePrize;
    default:
      return "";
  }
};

export const getNextUnpublishedMatch = (matchList) => {
  // check if length of matchlist is empty then return 'Matches not exist' messsage
  if (!matchList?.length) {
    return MiniLeagues.messages.noMatchAvailable;
  }

  // find the next unpublished match from the matchlist if exist return the match else return 'N/A' message
  const nextUnpublishedMatchData = matchList?.find(
    (match) => match?.published === false,
  );
  if (nextUnpublishedMatchData) {
    return getKickOffMatchFormat(nextUnpublishedMatchData);
  } else {
    return MiniLeagues.messages.allMatchesPublished;
  }
};
