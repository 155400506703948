import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./counterSlice";
import teamsReducer from "../teamslist/component/TeamsColorConfig/teamColorConfigSlice";
import sessionExpireReducer from "./sessionExpireSlice";
import leagueSeasonReducer from "./leagueSeasonSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    teams: teamsReducer,
    session: sessionExpireReducer,
    leagues: leagueSeasonReducer,
  },
});
