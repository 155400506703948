import API from "../api/api";
import { getBase } from "../apiconfig";
import { URLS } from "../common/url";
import { HttpStatus } from "../utils/constants";
import { ERROR_MESSAGE_TYPE } from "../utils/error/errorMsg";

export const fetchReferrals = async (config = {}) => {
  try {
    const url: string = getBase() + "/api" + URLS.REFERRALS;
    const data: any = await API.get(url, config);
    if (data.status === 200 || data.status === 201) {
      if (data?.data?.data?.error)
        // eslint-disable-next-line no-throw-literal
        throw {
          status: HttpStatus.BAD_REQUEST,
          message:
            data?.data?.data?.error ||
            ERROR_MESSAGE_TYPE[HttpStatus.BAD_REQUEST],
        };
      else return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.status],
      };
    }
  } catch (error) {
    return error;
  }
};

export interface IAddReferralData {
  code: string;
  type: string;
  description?: string;
  credits: number;
  message?: string | null;
  isBonus?: boolean;
}

export const addReferral = async (
  referralData: IAddReferralData,
  config = {},
) => {
  try {
    const url: string = getBase() + "/api" + URLS.REFERRALS;
    const data: any = await API.post(url, referralData, config);
    if (data.status === 200 || data.status === 201) {
      return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.status],
      };
    }
  } catch (error) {
    return error;
  }
};

export const deactivateReferral = async (referralId: string, config = {}) => {
  try {
    const url = `${getBase()}/api${URLS.DEACTIVATE_REFERRAL.replace(
      "{promotionId}",
      referralId,
    )}`;
    const data: any = await API.put(url, {}, config);
    if (data.status === 200 || data.status === 201) {
      if (data?.data?.data?.error)
        // eslint-disable-next-line no-throw-literal
        throw {
          status: HttpStatus.BAD_REQUEST,
          message:
            data?.data?.data?.error ||
            ERROR_MESSAGE_TYPE[HttpStatus.BAD_REQUEST],
        };
      else return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.status],
      };
    }
  } catch (error) {
    return error;
  }
};

export const updateReferralApi = async (payload: any, config = {}) => {
  try {
    const { id, message } = payload;
    const url = getBase() + "/api" + URLS.REFERRALS + `/update/${id}`;
    const data: any = await API.put(url, { message }, config);
    if (data.status === 200 || data.status === 201) {
      if (data?.data?.data?.error)
        // eslint-disable-next-line no-throw-literal
        throw {
          status: HttpStatus.BAD_REQUEST,
          message:
            data?.data?.data?.error ||
            ERROR_MESSAGE_TYPE[HttpStatus.BAD_REQUEST],
        };
      else return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.status],
      };
    }
  } catch (error) {
    return error;
  }
};
